<template>
    <div v-if="showPrompt==true" class="cookie-prompt">
        <p class="prompt-text">FootBrawl uses cookies to ensure you get the best experience on our website. <a href="https://cookiesandyou.com">Learn More...</a></p>
        <button @click="hideCookiePrompt()" class="prompt-button" id="opt-out">Opt out</button>
        <button @click="acceptCookies()" class="prompt-button" id="opt-in">Okay!</button>
    </div>
</template>
<script>
import VueCookies from 'vue-cookies'
import VueGtag from "vue-gtag";
import {setOptions, bootstrap} from "vue-gtag";
import Vue from 'vue'

Vue.use(VueGtag, {
    enabled: false,
    bootstrap: false,
});
Vue.use(VueCookies)
Vue.$cookies.config('7d')
export default {
    data:function(){
        return{
            showPrompt:false
        }
    },
    methods:{
        checkCookies(){
            if(this.$cookies.isKey("cookiePrompt")){
                this.acceptCookies()
            }
            else {
                this.showCookiePrompt()
            }
        },
        showCookiePrompt(){
            this.showPrompt = true
        },
        saveLocalization(){
            this.$cookies.set("language", this.$language.id)
        },
        acceptCookies(){
            setOptions({
                config: { id: "G-XDJ1SBTMPF" },
                enabled: true
            })
            bootstrap().then(gtag => {
                console.log("GTAG ENABLED")
            })
            this.logAnalytics()
            this.$cookies.set("cookiePrompt", true)
            this.saveLocalization();
            this.acceptCookies = true
            this.hideCookiePrompt()
        },
        hideCookiePrompt(){
            this.showPrompt = false
        },
        logAnalytics(){
            this.$gtag.event('login', { method: 'Google' })
        },
    },
    created(){
        this.checkCookies();
    }
}
</script>
<style lang="less" scoped>
    .cookie-prompt{
        position: fixed;
        width: 100%;
        bottom: 0px;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 10px 20px;
        z-index: 10;
        background-color: rgb(65, 74, 92);
        border: 2px solid rgb(187, 188, 216);
    }
    .prompt-text{
        flex-grow: 1;
        font-size: 20px;
        margin-top: 13px;
    }
    .prompt-text a{
        color: #45cffd;
    }
    .prompt-button{
        max-width: 90px;
        min-width: 70px;
        min-height: 45px;
        max-height: 50px;
        flex-grow: 1;
        color: #fff;
        margin-right: 10px;
        border-radius: 5px;
        font-size: 14px
    }
    #opt-out{
        background-color: #303030;
        border: none;
    }
    #opt-out:hover{
        background-color: #3b3b3b;

    }
    #opt-out:active{
        background-color: #1f1f1f;
        color: #ccc;
    }
    #opt-in{
        background-color: #56b639;
        border: none;
    }
    #opt-in:hover{
        background-color: #5ec041;

    }
    #opt-in:active{
        background-color: #49a02f;
        color: #ccc;
    }
    @media(max-width:481px) {
        .cookie-prompt{
            flex-direction: column;
            display: block;
        }
        .prompt-button{
            margin-top: 10px;
            float: left;
        }
    }
</style>