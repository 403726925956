<template>
    <div>
        <div v-if="lang=='EN'">
            <h1 style="line-height: 120%;text-align: center;margin-top: 0.35cm;margin-bottom: 0.35cm;background: transparent;font-family: &quot;Calibri&quot;, serif;font-size:27px;font-weight: bold;">
        Privacy Policy Power Challenge AB</h1>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">
        Power Challenge AB respects your right to control your privacy. We have put in place security measures for your
        personal data and manage your personal data in accordance with applicable data protection and data privacy
        regulations. This Privacy Policy explains how we handle and treat your data when you (i) register or visit our
        game, [Footbrawl] or associated sites or pages (the <strong>Game</strong>), (ii) use our services,
        or (iii) engage or communicate with us. It also provides information about your rights relating to your Personal
        Data.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">
        Throughout this Privacy Policy the following terms have the following meanings:</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">
        <strong>Data Privacy Laws</strong><strong>&nbsp;</strong>means applicable data protection and data
        privacy laws and regulations, including but not limited to the EU General Data Protection Regulation (2016/679)
        (the <strong>GDPR</strong>).</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">
        <strong>Processing</strong> and <strong>Process</strong> means all activities
        involving your Personal Data, including collecting, handling, storing, sharing, accessing, using, transferring
        and disposing of information.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">
        <span style="color: #fff;"><strong>Services</strong></span> means our applications and
        other products such as Footbrawl, the Game, email communications, social media accounts and any related services
        or properties we control.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">
        <strong>Personal Data</strong> means Personal Data that relates to you as an identified or
        identifiable individual.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">All
        capitalised terms not specifically defined herein shall have the meaning ascribed to such terms in the GDPR.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">
        While operating the Services, Personal Data will be shared with partners we work with. Some of these partners
        are Controllers independently of us and, therefore, independently determine how and why they process your data.
        For more information on these partners and the ways in which they may process your data, please refer to <a href="https://www.powerchallenge.com/footbrawl/privacypolicy.html#data-sharing">section</a> 4 of this Privacy Policy. Under certain
        circumstances we will transfer your Personal Data to countries outside the European Economic Area. Such
        transfers will be done in accordance with section 5 of this Privacy Policy.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">Our
        Services are not directed to children and we do not knowingly collect Personal Data from children under 16 years
        of age.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.28cm;background: transparent;"><br>&nbsp;</p>
    <ol>
        <li>
            <p style="line-height: 110%;text-align: left;margin-bottom: 0.56cm;background: transparent;margin-top: 0.56cm;border: none;padding: 0cm;">
                <span style="color: #fff;"><strong>Who we are</strong></span></p>
        </li>
    </ol>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-top: 0.35cm;"><span style="color: #fff;"><em>Controller</em></span></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;">The Controller
        responsible for your Personal Data for the purposes of the GDPR as well as applicable data privacy laws is<span style="color: #fff;">:</span></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">
        Power Challenge AB<br>&nbsp;Fridtunagatan 24, 582 12 Link�ping
        Sweden<br>&nbsp;privacy@powerchallenge.com<br>&nbsp;<br>&nbsp;<br>hereinafter referred to as the
        <strong>Company</strong>, <strong>we</strong>, <strong>us</strong> or
        <strong>our</strong>.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">The
        principles set out in this Privacy Policy apply to all instances in which the Company Processes your personal
        data as a Controller for the purposes described in this Privacy Policy. The Company is part of the Stillfront
        Group, a global group of gaming studios with its parent company, Stillfront Group AB (publ), incorporated in
        Sweden and listed on Nasdaq Stockholm.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">
        <em>Data protection Officer</em></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">The
        data protection officer of the Controller for the purposes of the GDPR is:</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">
        Name: Peter Birgersson, Deloitte AB<br>Contact details: <a href="mailto:dpo@stillfront.com"><span style="color: rgb(77, 128, 179);"><u>dpo@stillfront.com</u></span></a></p>
    <ol start="2">
        <li>
            <p style="line-height: 110%;text-align: left;margin-bottom: 0.56cm;background: transparent;margin-top: 0.56cm;border: none;padding: 0cm;">
                <span style="color: #fff;"><strong>What information do we collect from you?</strong></span></p>
        </li>
    </ol>
    <table cellpadding="7" cellspacing="0" width="528">
        <tbody>
            <tr>
                <td bgcolor="#303030" style="background: #303030;border: 1px solid #000000;padding: 0cm 0.19cm;" width="50%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;margin-top: 0.35cm;">
                        <span size="1" style="font-size:11px;"><strong>Source</strong></span></p>
                </td>
                <td bgcolor="#303030" style="background: #303030;border: 1px solid #000000;padding: 0cm 0.19cm;" width="50%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;margin-top: 0.35cm;">
                        <span size="1" style="font-size:11px;"><strong>The information collected includes the following
                                data</strong></span></p>
                </td>
            </tr>
            <tr>
                <td bgcolor="#4c4c4c" rowspan="7" style="background: #4c4c4c;border: 1px solid #000000;padding: 0cm 0.19cm;" width="50%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;"><span size="1" style="font-size:11px;">Information you provide voluntarily when using our
                            Services.</span></p>
                </td>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="50%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.42cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Your name, email address, telephone number, or
                                    other contact information communicated to us.&nbsp;</span></p>
                        </li>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Contents of any communication with us, such as
                                    user id, username, type of request and country.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Any Personal Data provided in any communication
                                    channel available in our Services</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">If you have subscribed to our communications such
                                    as push notifications on your device. your content preferences or other information
                                    you submit to us when subscribing to our communications.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Payment information such as credit card
                                    information, address and full name when using our fee-based services or making in
                                    game purchases.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">If you participate in our surveys or other
                                    research, any comments, feedback, responses, or other information you provide to us
                                    when doing so.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Email, password(encrypted) and username when
                                    registering to use our service.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Any other information you choose to submit to us
                                    through our Services or otherwise.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td bgcolor="#4c4c4c" rowspan="5" style="background: #4c4c4c;border: 1px solid #000000;padding: 0cm 0.19cm;" width="50%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.42cm;background: transparent;"><span size="1" style="font-size:11px;">Directly from you and/or your device by automatic
                            means.</span></p>
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.42cm;background: transparent;"><span size="1" style="font-size:11px;"><em>The Company collects Personal Data directly from you
                                and/or your device (such as your phone or computer) by automatic means when you use our
                                Services or interact with our ads outside of our Services on third-party gamess or
                                applications, including through cookies or similar technologies or software development
                                kits (SDKs). For more information regarding the Company�s use of cookies, please
                                view our&nbsp;</em></span><a href="https://www.powerchallenge.com/footbrawl/privacypolicy.html#"><span size="1" style="font-size:11px;"><em>Cookie Policy</em></span></a><span size="1" style="font-size:11px;"><em>.</em></span></p>
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;"><br>
                    </p>
                </td>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="50%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Your device and browser: Certain information is
                                    collected by most browsers or automatically through your device, such as your device
                                    type, model, manufacturer, operating system, language, display, processor, the
                                    Internet browser type you are using, and your IP address (including your coarse
                                    location).</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Your use of our Services: We track and collect
                                    usage data, such as the date and time you access our Services, access status,
                                    duration and frequency of use of our Services, the site you visited URL of referring
                                    game, the information and files that have been downloaded.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Crash logs or other information related to bugs,
                                    errors, or other issues in our Services.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Information collected when logging in to use our
                                    services through a third party in order to facilitate easy log in experience for the
                                    user.&nbsp;</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Inferences we make based on your activity in our
                                    Services.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">We
        do not expect or intend to collect or otherwise process any special categories of data relating to you. By
        special categories of data, we mean genetic, biometric or health information, information revealing racial or
        ethnic origin, sex life or sexual orientation, political opinions, religious or philosophical beliefs, trade
        union membership, or information about your criminal offences or convictions. Please do not provide this kind of
        information to us or use the Services to make it available to others.</p>
    <ol start="3">
        <li>
            <p style="line-height: 110%;text-align: left;margin-bottom: 0.56cm;background: transparent;margin-top: 0.56cm;border: none;padding: 0cm;">
                <span style="color: #fff;"><strong>How do we use your Personal Data?</strong></span></p>
        </li>
    </ol>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">We
        use your Personal Data for different purposes. We will for example use the Personal Data to provide you with the
        Services you have requested, to improve and develop our Services, to predict user trends, to make
        recommendations and marketing activities based on your usage and to customise our Services to you, these include
        the following processing activities:</p>
    <table cellpadding="7" cellspacing="0" width="528">
        <tbody>
            <tr>
                <td bgcolor="#bfbfbf" style="background: #bfbfbf;border: 1px solid #000000;padding: 0cm 0.19cm;" width="29.31726907630522%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;"><span size="1" style="font-size:11px;"><strong>Lawful basis&nbsp;</strong></span></p>
                </td>
                <td bgcolor="#bfbfbf" style="background: #bfbfbf;border: 1px solid #000000;padding: 0cm 0.19cm;" width="70.68273092369478%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;"><span size="1" style="font-size:11px;"><strong>Purpose of the processing&nbsp;</strong></span></p>
                </td>
            </tr>
            <tr>
                <td bgcolor="#4c4c4c" height="8" rowspan="5" style="background: #4c4c4c;border: 1px solid #000000;padding: 0cm 0.19cm;" width="29.31726907630522%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.42cm;background: transparent;"><span size="1" style="font-size:11px;">Processing is necessary to perform our contract with you in
                            respect of our Services.</span></p>
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;"><br>
                    </p>
                </td>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="70.68273092369478%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Information provided voluntarily by you is used
                                    to enable you to use our Services and administer the Services.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Payment information is necessary to facilitate
                                    your purchases in the services and payment for the usage of the
                                    service.&nbsp;</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Information provided voluntarily by you and
                                    automatically collected data is used to provide, maintain, enhance and personalise
                                    the Service(s) (including selecting an appropriate language version for you based on
                                    your location).</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Automatically collected data is used to know your
                                    preferences and to try to ensure that the content on our Game is presented in the
                                    most effective manner for you and your computer or mobile device, based on your
                                    browsing history on our services.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Information provided voluntarily by you is used
                                    to send you newsletters or other similar communications if you have subscribed to
                                    them (for example, through a subscription form on our Game) or push notifications on
                                    your device.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td bgcolor="#4c4c4c" rowspan="6" style="background: #4c4c4c;border: 1px solid #000000;padding: 0cm 0.19cm;" width="29.31726907630522%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;"><span size="1" style="font-size:11px;">Based on our legitimate interest to make our Services the
                            best they can be and provide you with the best possible user experience in our
                            Services</span></p>
                </td>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="70.68273092369478%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Information provided voluntarily by you and
                                    automatically collected data is used to operate and maintain our Services (improve
                                    our current Services or develop new Services) including troubleshoot or debug any
                                    bugs, errors, or other issues in our Services.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Information provided voluntarily by you and
                                    automatically collected data is used to, where available, provide social features
                                    (such as chat and forum functions), communication channels or events in our Services
                                    or other Services.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Contact information provided by you is used for
                                    the purpose of contacting you and/or handling your support requests or otherwise
                                    managing our relationship with you.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Automatically collected data is used to measure
                                    and analyse the use of our Services or data we collect through our Services (for
                                    example, to discover trends or other insights or to inform our operations).</span>
                            </p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Data provided by you as well as automatically
                                    collected data is used to create data that is not identifiable to you (for example,
                                    aggregate data), which we then use and share freely.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="100%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Automatically collected data is used to analyse
                                    how visitors use our Services.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td bgcolor="#4c4c4c" height="61" style="background: #4c4c4c;border: 1px solid #000000;padding: 0cm 0.19cm;" width="29.31726907630522%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;border: none;padding: 0cm;">
                        <span style="color: #fff;">Based on our legitimate interest to make our services more
                            intuitive and evaluate user behaviours and preferences.</span></p>
                </td>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="70.68273092369478%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Automatically generated data, that is not
                                    identifiable to you, specifically aggregated data regarding recency and frequency of
                                    player events such as �game closed� or �in-app purchases� is
                                    analysed to understand retention patterns and player behaviour.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td bgcolor="#4c4c4c" style="background: #4c4c4c;border: 1px solid #000000;padding: 0cm 0.19cm;" width="29.31726907630522%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.42cm;background: transparent;border: none;padding: 0cm;">
                        <span style="color: #fff;">Based on our legitimate interest to facilitate performance
                            marketing.&nbsp;</span></p>
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;border: none;padding: 0cm;">
                        <br></p>
                </td>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="70.68273092369478%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Automatically generated data, that is not
                                    identifiable to you, specifically aggregated data regarding recency and frequency of
                                    player events such as �watched in-game advertising� or �in-app
                                    purchases� is used to understand and measure player lifetime value. Through
                                    this data, we can analyse expected future revenue from our players and further
                                    market our services</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td bgcolor="#4c4c4c" style="background: #4c4c4c;border: 1px solid #000000;padding: 0cm 0.19cm;" width="29.31726907630522%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;border: none;padding: 0cm;">
                        <span style="color: #fff;">Based on our legitimate interest to provide you with
                            interest-based services and advertisement.</span></p>
                </td>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="70.68273092369478%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">We use ad identifiers, third-party tracking
                                    ID�s (such as AppsFlyer ID) and similar technologies to collect data from your
                                    device. Information from third party providers is combined with features including
                                    device type, system language and IP address to create a unique player ID. This is
                                    done for the purpose of recognising you and your device and providing you with
                                    customised player experiences and interest-based advertising.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td bgcolor="#4c4c4c" style="background: #4c4c4c;border: 1px solid #000000;padding: 0cm 0.19cm;" width="29.31726907630522%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;border: none;padding: 0cm;">
                        <span style="color: #fff;">Based on our legitimate interest to safeguard our
                            operations.</span></p>
                </td>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="70.68273092369478%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">Information provided voluntarily by you and
                                    automatically collected data is used to audit our operations or processes (for
                                    example, to verify that they function as intended).</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td bgcolor="#4c4c4c" style="background: #4c4c4c;border: 1px solid #000000;padding: 0cm 0.19cm;" width="29.31726907630522%">
                    <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;border: none;padding: 0cm;">
                        <span size="1" style="font-size:11px;">Processing is necessary for compliance with a legal
                            obligation to which the controller is subject.</span></p>
                </td>
                <td style="border: 1px solid #000000;padding: 0cm 0.19cm;" width="70.68273092369478%">
                    <ul>
                        <li>
                            <p style="line-height: 115%;text-align: left;margin-bottom: 0.25cm;background: transparent;">
                                <span size="1" style="font-size:11px;">For example in the unlikely event of a dispute or
                                    a criminal investigation. Where required by law, your Personal Data will be
                                    disclosed to an applicable governmental, regulatory, sporting or enforcement
                                    authority. Your Personal Data will also be disclosed to any regulatory body in
                                    connection with prevention and detection of crime and where the Company considers
                                    that there are reasonable grounds to suspect that you may be involved in a breach of
                                    the law. Those bodies will then use your Personal Data to investigate and act on any
                                    such breaches in accordance with their procedures.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;border: none;padding: 0cm;">
        <span style="color: #fff;">In addition, we may process your data for additional purposes which are
            compatible with any of the purposes listed above.</span></p>
    <ol start="4">
        <li>
            <p id="data-sharing" style="line-height: 110%;text-align: left;margin-bottom: 0.56cm;background: transparent;margin-top: 0.56cm;border: none;padding: 0cm;">
                <span style="color: #fff;"><strong>Who we share your information with</strong></span></p>
        </li>
    </ol>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-top: 0.35cm;">Your
        Personal Data will (for the purposes described in this Privacy Policy) be transferred or disclosed to third
        parties, for the processing of that Personal Data on our behalf, such as to:</p>
    <ul>
        <li>
            <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;">Other companies in
                the Stillfront Group, where they help us develop or operate our Services or other Services will receive
                access to all available Personal Data. For information on the entities within Stillfront Group, see the
                most recent annual report published on <a href="https://www.stillfront.com/en/reports-presentations/">https://www.stillfront.com/en/reports-presentations/</a>,
                as updated from time&nbsp;to time.</p>
        </li>
        <li>
            <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;">For analytical
                purposes, we collect automatically generated data such as your recent visits to our services and how you
                move around different sections, in order to make our services more intuitive and evaluate user needs and
                preferences. This data will be shared with a limited group of employees in the Stillfront Group IT team.
            </p>
        </li>
        <li>
            <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;">Persons or
                companies that provide services to us and process Personal Data on our behalf when providing those
                services, for example, Facebook and Google.</p>
        </li>
        <li>
            <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;">Professional
                advisors such as external legal and audit services etc. Personal Data shared will consist of information
                you provide voluntarily when using our Services and data collected from your device by automatic means.
            </p>
        </li>
        <li>
            <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;">Third parties to
                whom we outsource certain services such as, without limitation, document processing and translation
                services, confidential waste disposal, IT systems or software providers, IT Support service providers,
                document and information storage providers such as Dropbox, Amazon Web Services, etc. Data shared will
                consist of information you provide voluntarily when using our Services and data collected from your
                device by automatic means.</p>
        </li>
        <li>
            <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;">Competent courts
                of law or other government authorities where we believe disclosure is necessary as a matter of
                applicable law or regulation, such as the Authority for Privacy Protection in relation to any scrutiny
                of compliance with GDPR.</p>
        </li>
        <li>
            <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;">Any person or
                entity where we believe disclosure is necessary to exercise, establish or defend our legal rights or to
                protect your or another person�s vital interests.</p>
        </li>
        <li>
            <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;">Please note
                this list is non-exhaustive and there may be other examples where we need to share data with other
                parties in order to provide the Services as effectively as we can.</p>
        </li>
    </ul>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">When
        Personal Data is shared with our business partners, group affiliates or other trusted entities stated above, we
        always require them to only use information in accordance with our instructions.</p>
    <ol start="5">
        <li>
            <p style="line-height: 110%;text-align: left;margin-bottom: 0.56cm;background: transparent;margin-top: 0.56cm;border: none;padding: 0cm;">
                <span style="color: #fff;"><strong>Appropriate Safeguards to countries outside the
                        EU/EEA-area</strong></span></p>
        </li>
    </ol>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-top: 0.35cm;">In
        connection with some processing activities described in this Privacy Policy we share information outside of the
        European Union (<strong>EU</strong>) and the European Economic Area
        (<strong>EEA</strong>). For example, a number of servers we use for hosting data are located in
        the United States, and some of our group companies or the service providers we use to provide the Services may
        be located outside of the EU and the EEA. Where the Company transfers Personal Data outside the EU/EEA, The
        Company will ensure that Standard Contractual Clauses have been entered into between the transferring entity and
        the receiving external party. Additional safeguards will also be put in place prior to such transfers.
        Alternatively, other approved transfer mechanisms will be put in place prior to third country transfers..</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-top: 0.35cm;"><br>
    </p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;">Data shared will
        consist of information you provide voluntarily when using our Services and data collected from your device by
        automatic means. You are under Data Privacy Laws upon request entitled to receive a copy of any documentation
        demonstrating that appropriate safeguards have been taken in order to protect your Personal Data during a
        transfer outside the EU/EEA. You can do so by reaching out to our Data Processing Officer <a href="mailto:dpo@stillfront.com"><span style="color: rgb(77, 128, 179);"><u>dpo@stillfront.com</u></span></a>.</p>
    <ol start="6">
        <li>
            <p style="line-height: 110%;text-align: left;margin-bottom: 0.56cm;background: transparent;margin-top: 0.56cm;border: none;padding: 0cm;">
                <span style="color: #fff;"><strong>How long do we keep hold of your information?</strong></span>
            </p>
        </li>
    </ol>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">We
        will retain your information only for as long as is necessary for the purposes set out in this Privacy Policy.
        We are using the following criteria to establish our retention period: (i) as long as we have an ongoing
        relationship with you; (ii) as required by legal obligations to which the Company is subject (such as tax and
        accounting obligations); and (iii) as advisable in light of our legal position (such as applicable statutes of
        limitations). For more specific information about data retention terms, please contact our Data Processing
        Officer <a href="mailto:dpo@stillfront.com"><span style="color: rgb(77, 128, 179);"><u>dpo@stillfront.com</u></span></a> and we will provide the specific
        data retention terms for your jurisdiction.</p>
    <ol start="7">
        <li>
            <p style="line-height: 110%;text-align: left;margin-bottom: 0.56cm;background: transparent;margin-top: 0.56cm;border: none;padding: 0cm;">
                <span style="color: #fff;"><strong>Security</strong></span></p>
        </li>
    </ol>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;">Your
        data is secured by appropriate safeguards, taking into account the circumstances, the state of the art, the
        costs of implementation and the nature, scope, context and purposes of processing as well as the risk. In
        support of this commitment, we have implemented appropriate technical, physical and organisational measures to
        protect your Personal Data against unauthorised or accidental destruction, alteration or disclosure; misuse;
        damage; theft or accidental loss; or unauthorised access.</p>
    <ol start="8">
        <li>
            <p style="line-height: 110%;text-align: left;margin-bottom: 0.56cm;background: transparent;margin-top: 0.56cm;border: none;padding: 0cm;">
                <span style="color: #fff;"><strong>Your rights</strong></span></p>
        </li>
    </ol>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;border: none;padding: 0cm;">
        <span style="color: #fff;">Providing data to us is not mandatory. However, we are unable to provide the
            Services, or some parts or features of the Services, without processing your data. If you use our Services,
            we will collect data relating to you for some or all the purposes described in this Privacy Policy,
            depending on the Services you decide to use and your choices when using them. You have a number of options
            to limit or control the extent to which your data is processed. For example, you can choose to not connect
            your third-party accounts with our Services, use your device settings to reset or limit the use of your
            Advertising ID, or disable some or all cookies from your browser settings.</span></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-top: 0.35cm;">In
        relation to our processing of your Personal Data you have, under certain circumstances, the right to exercise
        the following rights:</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;"><br></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">
        <em>Access</em></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">You
        may request confirmation whether or not your Personal Data is processed and, if that is the case, access to your
        Personal Data and additional information such as the purposes of the processing. You are also entitled to
        receive a copy of the Personal Data undergoing processing. If the request is made by electronic means the
        information will be provided in a commonly used electronic format if you do not request otherwise.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;"><br>
    </p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">
        <em>Object to certain processing</em></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">You
        may object to the processing of your Personal Data on the basis of a legitimate interest, on grounds relating to
        your particular situation and to the processing for direct marketing purposes.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;"><br>
    </p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-left: 1.25cm;">To
        unsubscribe from our marketing communications, please use the unsubscribe link provided in the messages we send.
        The unsubscribe link is typically found at the end of the message.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;margin-top: 0.35cm;">
        <em>Rectification</em></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">You
        have at any time the right to have inaccurate Personal Data rectified, as well as, taking into account the
        purposes of the processing, the right to have incomplete Personal Data completed.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;"><br>
    </p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">
        <em>Erasure</em></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">You
        may have your Personal Data erased under certain circumstances such as when your Personal Data is no longer
        needed for the purposes for which it was collected.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;"><br>
    </p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">
        <em>Restriction of processing</em></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">You
        may ask us to restrict the processing of your Personal Data to only comprise storage of your Personal Data under
        certain circumstances, such as when the processing is unlawful, but you do not wish your Personal Data erased.
    </p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;"><br>
    </p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">
        <em>Withdrawal of consent</em></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">You
        have the right to at any time withdraw your consent to processing of Personal Data to the extent the processing
        is based on your consent. This does not affect the lawfulness of processing based on consent before its
        withdrawal.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;"><br>
    </p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;">
        <em>Data Portability</em></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-left: 1.25cm;">You
        may ask to receive a machine-readable copy of Personal Data processed and ask for the information to be
        transferred to another Controller (where possible). This only refers to such Personal Data processed on the
        basis of your consent or on the basis that the processing is necessary in order to perform an agreement with you
        and only to the extent the Personal Data has been provided to the Company by you (<em>data portability</em>).
    </p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0cm;background: transparent;margin-left: 1.25cm;margin-top: 0.35cm;">
        <em>Complaints to the supervisory authority</em></p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-left: 1.25cm;">You
        have the right to lodge complaints pertaining to the processing of your Personal Data to the relevant data
        protection supervisory authority.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-left: 1.25cm;margin-top: 0.35cm;">
        <em>How to adjust your preferences</em><br>To access your data in our Services or to request its deletion,
        please use the automatic tools provided in our Services. These tools are specific to each of our Services. <span style="color: rgb(255, 0, 0);">You will find them in the game settings under Terms &amp;
            Privacy. On the same page, you can also opt out of our personalized offers, where applicable</span>.
        If you opt out of interest-based advertising, you will still be able to play our games and you may still see
        advertising in our games. You can also choose to prevent your device's ad identifier by changing the
        settings of your device.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-left: 1.25cm;margin-top: 0.35cm;">
        We ask you to primarily use these tools or our contact form to submit requests, because that helps us validate,
        process, and fulfil your request more quickly and reliably.</p>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-left: 1.25cm;margin-top: 0.35cm;">
        If you have any questions about this Privacy Policy or our data collection practices, please contact us at the
        address or email set out under section 1 of this Privacy Policy and specify your country of residence and the
        nature of your question.</p>
    <ol start="9">
        <li>
            <p style="line-height: 110%;text-align: left;margin-bottom: 0.56cm;background: transparent;margin-top: 0.56cm;border: none;padding: 0cm;">
                <span style="color: #fff;"><strong>Changes to the terms of this Privacy Policy</strong></span>
            </p>
        </li>
    </ol>
    <p style="line-height: 120%;text-align: left;margin-bottom: 0.35cm;background: transparent;margin-top: 0.35cm;border: none;padding: 0cm;">
        <span style="color: #fff;">We may update this Privacy Policy from time to time, for example due to
            changes in our operations or the legal obligations that apply to us. Updates will be made available here. We
            will also inform you of any changes by other means that are appropriate to the significance of the
            changes.</span></p>
        </div>
        <div v-if="lang=='KR'" class="pp-text">
            <p>여기 eRepublik Labs에서는 귀하의 개인 정보를 소중히 여기며 귀하의 개인 정보 권리를 존중합니다.</p>
            <p>eRepublik 서비스 (아래 정의 됨)를 사용함으로써 귀하는 아래에 설명 된대로 귀하의 개인 정보의 수집 및 사용을 허용하고이 개인 정보 보호 정책에 동의 함을 의미합니다. 이 약관에 동의하지 않는 경우 eRepublik 서비스를 사용해서는 안됩니다.</p>
            <ol>
            <li><strong> 개인 정보 보호 정책에 대하여</strong></li>
            </ol>
            <p>The Unit 4, Block A, Broomfield Business Park, Malahide, Co Dublin, K36 RF83, Ireland의 eRepublik Labs Limited, 등록 번호. 462101, VAT 번호 IE-9693023K ( "eRepublik Labs", "당사", "당사"및 "당사")는 http://gameofnations.net 웹 페이지 및 게임 iOS 및 Android 앱, 모든 섹션 ( "게임"), 게임 내 프로필 및 여기에 나열되지 않은 게임의 기타 영역 ( "eRepublik 서비스").</p>
            <p>1.1 eRepublik 서비스를 통해 사람들은 다양한 활동에 참여하고 참여하고 eRepublik 서비스에서 제공되는 기능을 수시로 사용하기 위해 게임에 플레이어로 등록 할 수 있습니다.</p>
            <p>1.2이 정책 (https://www.erepubliklabs.com/terms-of-service/gon/ 및 여기에 언급 된 기타 문서와 함께 사용 약관)은 당사가 수집하는 개인 데이터의 기반을 설정합니다. 귀하 또는 귀하가 당사에 제공 한 정보는 당사에서 처리합니다. 귀하의 개인 데이터에 관한 당사의 견해와 관행 및 당사가이를 처리하는 방법을 이해하려면 다음을주의 깊게 읽으십시오.</p>
            <ol start="2">
            <li><strong>게임 정보 수집 방법</strong></li>
            </ol>
            <p>2.1 우리는 eRepublik 서비스를 제공하는 데 필요한 경우에만 사용자의 개인 정보를 수집하고 사용합니다. 당사는 이용자의 동의가 있거나 개인 정보의 수집 및 처리가 법률에 의해 허용되는 경우에만 개인 정보를 수집 및 처리합니다. eRepublik 서비스 사용자로 등록 할 때 또는 eRepublik 서비스 사용자로 등록 할 때 귀하는 당사에 정보를 제공해야합니다. eRepublik 서비스를 사용하기 위해 정보를 제공해야하는지 또는 당사가 요청한 정보 제공이 선택 사항인지 여부를 귀하로부터 다른 정보를 수집 할 때 알려드립니다. 당사는 귀하로부터 정보를 수집 할 때 귀하가 당사에 제공 한 정보가 eRepublik 서비스의 다른 사용자 또는 제 3자가 당사에 정보를 제공할지 여부를 결정할 수 있도록 액세스 할 수 있는지 여부를 알려드립니다.</p>
            <p>2.2 당사는 귀하에 대해 다음과 같은 개인 데이터를 수집하고 처리 할 수 ​​있습니다.</p>
            <p>이메일</p>
            <p>Google ID</p>
            <p>페이스 북 아이디</p>
            <p>Facebook 이메일</p>
            <p>도시 국가</p>
            <p>IP</p>
            <p>다음과 같은 모바일 장치 관련 정보 : imei (장치 ID); 브랜드, 모델 및 운송 업체 운영 체제 / 플랫폼 (iOS, Android) 및 OS 버전 네트워크 유형 : WiFi / 모바일 데이터</p>
            <p>2.2.1 귀하가 당사에 연락하는 경우 (이메일 또는 우편 포함) 당사는 해당 서신과 그에 포함 된 정보를 기록 할 수 있습니다.</p>
            <p>2.2.2 게임 내 채팅 토론, 비공개 메시지 또는 기타 게임 내 커뮤니케이션 채널을 통해 귀하가 제출 한 정보;</p>
            <p>2.2.3 게임 플레이 선택을 포함하여 eRepublik 서비스를 통해 사용하는 기능</p>
            <p>2.2.4 eRepublik 서비스를 통해 수행 한 금융 거래의 세부 정보 과</p>
            <p>2.2.5 트래픽 데이터, 위치 데이터, 웹 로그 및 기타 통신 데이터를 포함하되 이에 국한되지 않는 eRepublik 서비스 방문에 대한 세부 정보 (이가 당사의 청구 목적에 필요한지 여부 및 액세스하는 리소스).</p>
            <p>2.3 16 세 미만은 eRepublik 서비스에 등록 할 수 없습니다. 귀하가 16 세에서 18 세 사이 인 경우 귀하는이 개인 정보 보호 정책을 부모 또는 보호자의주의를 끌었고 그들이 귀하가이 개인 정보 보호 정책에 동의하고 eRepublik 서비스를 사용하는 데 동의했음을 확인하는 데 동의합니다 ( 그러한 동의의 제공이 법으로 금지되지 않는 경우).</p>
            <ol start="3">
            <li><strong> 정보의 사용</strong></li>
            </ol>
            <p>3.1 당사 사용자는 토론 포럼, 제휴, 게임 내 비공개 메시지 또는 게임 내 채팅 채널을 통해 eRepublik 서비스에서 다른 사용자와 상호 작용하여 가상 커뮤니티에 참여할 수 있습니다. 그런 다음 eRepublik 서비스의 다른 사용자가 액세스하고 읽을 수 있습니다. eRepublik 서비스의 등록 된 사용자로 선택하는 경우 당사는 귀하의 개인 정보를 표시하지 않습니다. 다른 사용자는 게임 내 정보 만 사용할 수 있으며, 이는 사용자가 게임 내 활동과 eRepublik 서비스 통신을 평가할 수있는 정보입니다.</p>
            <p>3.2 당사는 귀하에 대해 보유한 정보를 다음과 같은 방식으로 사용합니다.</p>
            <p>3.2.1 eRepublik 서비스의 콘텐츠가 귀하와 귀하의 컴퓨터 또는 장치에 가장 효과적인 방식으로 제공되도록 보장합니다. 정보 호스팅 및 표시, 업데이트 제공, eRepublik 서비스 개발, 개선 또는 확장, 기술 문제 해결 등 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. b GDPR.</p>
            <p>3.2.2 귀하가 당사에 요청한 정보, 제품 또는 서비스를 제공하기 위해. 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. b GDPR.</p>
            <p>3.2.3 귀하가 eRepublik 서비스의 광고 기능과 상호 작용하는 것을 포함하여 그러한 목적으로 연락을받는 데 동의 한 경우 당사가 귀하에게 관심을 가질 수있는 정보 (당사 파트너의 제품 또는 서비스 포함)를 제공하기 위해. 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. GDPR. 모바일 게임의 경우 인센티브 광고를 제공하는 광고 플랫폼을 사용합니다 (예 : 사용자가 광고를보고 보상을 받음). 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. f GDPR. 게임에 광고를 제공하고 사용자에게 게임에서 보상을받을 수있는 기회를 제공하는 것이 우리의 이익입니다.</p>
            <p>3.2.4 귀하와 당사간에 체결 된 모든 계약에서 발생하는 당사의 의무를 수행하기 위해 eRepublik 서비스에서 귀하의 계정을 설정 및 유지하고 귀하의 계정 보안을 보호하기 위해 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. b GDPR.</p>
            <p>3.2.5 고품질 서비스 제공 및 유지 고객 지원을 제공하거나 고객 문제를 해결하기 위해 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. b GDPR.</p>
            <p>3.2.6 귀하가 선택하는 경우 eRepublik 서비스에 액세스하고 당사 서비스의 대화 형 기능에 참여할 수 있도록합니다. 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. b GDPR.</p>
            <p>3.2.7 당사 또는 당사 파트너가 개발 한 새로운 게임 또는 응용 프로그램에 대해 당사 서비스, 새로운 기능 또는 eRepublik 서비스의 새로운 버전에 대한 변경 사항을 귀하에게 통지합니다. 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. b GDPR 및 예술. 6 파라. 1 점등. f GDPR. 당사는 당사 제품 및 당사 파트너의 제품에 대한 마케팅 정보를 귀하에게 제공하는 데 관심이 있습니다.</p>
            <p>3.2.8 통계를 컴파일하고 사용자에 대한 정보를 생성 및 분석하여 사용자와 사용자가 eRepublik 서비스를 사용하는 방법을 더 잘 이해하고이 정보를 기반으로 추세와 예측을 도출 할 수 있도록하기 위해 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. f GDPR. 우리의 관심은 서비스 개선에 있습니다.</p>
            <p>3.2.9 귀하가 eRepublik 서비스의 규칙을 준수하는지 확인하고 필요한 경우 제재를 부과합니다. 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. f GDPR. 우리의 관심은 서비스 개선과 사기 탐지에 있습니다.</p>
            <p>3.2.10 지불 처리 (경우에 따라). 개인 정보 처리의 법적 근거는 Art입니다. 6 파라. 1 점등. b GDPR.</p>
            <p>3.2.11 뉴스 레터에 가입 한 경우 뉴스, 홍보 목적 또는 시장 조사를 위해 이메일로 연락하고 해당 이메일에있는 옵트 아웃 링크를 클릭하여 옵트 아웃 할 수 있습니다. 개인 정보 처리의 법적 근거, 기존 동의의 경우, Art. 6 파라. 1 점등. GDPR.</p>
            <p>개인 정보는 수집 된 목적을 달성하기 위해 더 이상 필요하지 않은 경우 즉시 삭제됩니다. 따라서 사용자의 이메일 주소는 뉴스 레터 구독이 활성화되어있는 동안 저장됩니다. 사용자는 각 이메일 뉴스 레터 끝에있는 구독 취소 링크를 클릭하여 언제든지 뉴스 레터 구독을 취소 할 수 있습니다.</p>
            <p>3.4 개인 식별 정보는 제 3 자 사용자의 요청에 대한 귀하의 명시 적 승인을 제외하고 제 3 자 웹 사이트 또는 앱으로 내보내지지 않습니다. 귀하가 귀하의 데이터를 사용하기 위해 제 3 자 사용자에게 부여하는 모든 권한은 귀하와 제 3 자 사용자의 문제입니다. 당사는 귀하가 귀하의 데이터 사용을 허용하기로 선택한 방식에 대해 책임을지지 않습니다.</p>
            <p>3.5 eRepublik 서비스의 모든 영역에 게시 한 콘텐츠를 개인화하고 인기있는 회원을 식별 / 특집하기 위해 귀하가 게시 한 콘텐츠를 판매하거나 라이선스를 부여 할 수 있습니다. 예를 들어 가장 인기있는 활동적인 회원을 홍보 할 수 있습니다. 전체 회원 데이터베이스를 타인에게 전송할 것으로 기대하지는 않지만, 네트워크 전체에서 데이터베이스의 닉네임과 아바타를 사용하고 사용자가 서로 연락하고 상호 작용을 촉진하는 등 다른 사용자를위한 결과를 생성합니다. 사용자 커뮤니케이션.</p>
            <ol start="4">
            <li><strong> 다른 사람에게 귀하의 정보 공개</strong></li>
            </ol>
            <p>4.1 당사는 귀하의 허가가 있거나 법률에서 허용하거나 요구하지 않는 한 귀하가 당사에 제공 한 개인 정보를 제 3 자에게 공개하지 않습니다.</p>
            <p>4.2 귀하가 당사에 개인 정보를 제공하는 경우 귀하는 당사가 귀하의 개인 정보를 다음 제 3 자에게 공개 할 수 있음을 이해하고 동의합니다.</p>
            <p>4.2.1을 eRepublik Labs 호스팅 제공 업체 : Amazon Inc. (EU 및 미국에있는 서버 사용)</p>
            <p>귀하는 당사가 언제든지 호스팅 제공 업체를 변경할 수 있으며 그러한 변경 사항은 본 개인 정보 보호 정책의 수정에 의해 eRepublik 서비스에 게시 될 것임을 인정합니다. 귀하는 귀하의 개인 데이터를 새로운 호스팅 제공 업체의 서버로 전송하는 데 동의합니다. privacy@erepubliklabs.com으로 이메일을 보내거나 게임 내에서 사용 가능한 도움말 및 지원 메뉴를 통해 호스팅 제공 업체의 변경과 관련된 질문, 의견 또는 요청을 전달할 수 있습니다.</p>
            <p>4.2.2 등록시 또는 eRepublik 서비스 사용자로서 귀하가 관심을 가질 수있는 상품 및 서비스에 대한 선택된 제 3 자로부터 정보 또는 프로모션을 수신하게되어 기쁘다 고 표시 한 경우, 귀하는 귀하에게 그러한 제안 또는 정보를 보내는 데 동의합니다. 귀하는 언제든지 privacy@erepubliklabs.com으로 이메일을 보내 이러한 제안을 수신하지 않거나 기본 설정을 변경할 수 있습니다.</p>
            <p>4.2.3 eRepublik 서비스의 업데이트, 새로운 기능 또는 새 버전에 대한 정보를 포함하여 당사 서비스에 대한 정보를 귀하에게 보내는 유일한 목적으로 이메일 서비스를 제공하는 회사에게. 이러한 회사는이 기사에서 언급 한 것 이외의 다른 목적으로 귀하의 개인 식별 정보를 사용하는 것이 금지됩니다. 귀하는 언제든지 privacy@erepubliklabs.com으로 이메일을 보내 이러한 제안을 수신하지 않거나 기본 설정을 변경할 수 있습니다.</p>
            <p>4.2.4 eRepublik 서비스 또는 eRepublik Labs Limited, ERPK LABS SRL 또는 당사 그룹의 다른 구성원과 관련된 비즈니스 또는 자산의 잠재 판매자, 구매자 또는 양수인에게; 과</p>
            <p>4.2.5 당사가 법적 의무를 준수하기 위해 또는 당사의 이용 약관을 시행 또는 적용하기 위해 귀하의 개인 데이터를 제 3 자와 공개 또는 공유 할 의무가있는 경우 제 3 자에게 https : // www.erepubliklabs.com/terms-of-service/gon/ 또는 당사 그룹, eRepublik Labs, 고객, 기타 등록 된 사용자 또는 기타의 권리, 재산 또는 안전을 보호하기 위해. 여기에는 사기 방지 및 신용 위험 감소를 위해 제 3 자와 정보를 교환하는 것이 포함됩니다.</p>
            <p>4.2.6 광고주가 관련 광고를 표시 할 수 있도록합니다.</p>
            <p>4.3 당사는 귀하에 관한 정보가 위에 설명 된 방식 이외의 방식으로 제 3 자에게 제공 될 수있는 경우 귀하에게 통지 할 것이며 귀하는 귀하에게 통지 할 때 그러한 정보 공유를 방지 할 수있는 옵션을 갖게 될 것입니다. 당사는 귀하의 동의없이 이러한 정보를 전송하지 않습니다.</p>
            <p>4.4 당사는 본 정책에 명시된 경우를 제외하고 다른 제 3 자 (광고주 또는 마케팅 회사 포함)에게 귀하에 관한 개인 정보를 공개하지 않지만, eRepublik 서비스 사용자에 대한 집계 익명 정보를 제공 할 수 있습니다. 예를 들어, 30 세 미만의 남성 500 명이 특정 날짜에 eRepublik 서비스에 표시된 광고를 클릭했음을 알리거나 eRepublik 서비스를 방문하는 순 사용자 수에 대해 제 3 자에게 알릴 수 있습니다. eRepublik 서비스의 커뮤니티 사용자 또는 eRepublik 서비스 방문자가 eRepublik 서비스에서 참여하는 활동. 당사는 또한 광고주가 당사 웹 사이트에 광고를 표시 할 때 타겟팅하려는 유형의 잠재 고객 (예 : 아일랜드 남동부 여성)에게 도달 할 수 있도록 이러한 집계 익명 정보를 사용할 수 있습니다.</p>
            <p>4.5 유럽 경제 지역 (EEA) 외부로 개인 정보를 전송해야 할 수도 있습니다. 이는 당사의 서버 또는 공급 업체 및 서비스 제공 업체가 EEA 외부에 있거나 EEA 외부 국가를 방문하는 동안 당사의 서비스 및 제품을 사용하는 경우 발생할 수 있습니다. 이러한 국가의 데이터 보호 및 기타 법률은 아일랜드 또는 EU의 법률만큼 포괄적이지 않을 수 있지만 이러한 경우 당사는 귀하의 개인 정보 보호 권리가 존중되도록 조치를 취할 것입니다. 귀하는 당사가이를 기반으로 귀하의 개인 데이터를 전송할 수 있다는 데 동의합니다.</p>
            <ol start="5">
            <li><strong> 개인 데이터의 보안 및 제어</strong></li>
            </ol>
            <p>5.1 당사는 귀하의 데이터가 본 개인 정보 보호 정책에 따라 안전하게 취급되도록 합리적으로 필요한 모든 조치를 취할 것입니다.</p>
            <p>5.2 eRepublik 서비스의 특정 부분에 액세스 할 수있는 암호를 당사가 귀하에게 제공 한 경우 (또는 귀하가 선택한 경우) 귀하는이 암호를 기밀로 유지할 책임이 있습니다. 누구와도 비밀번호를 공유하지 않도록 부탁드립니다. 귀하는 귀하의 비밀번호 보안에 대한 책임이 있으며 당사의 이용 약관 https://www.erepubliklabs.com/terms-of-service/gon/에 대해 자세히 설명합니다.</p>
            <p>5.3 안타깝게도 인터넷을 통한 정보 전송은 완전히 안전하지 않습니다. 당사는 귀하의 개인 데이터를 보호하기 위해 최선을 다할 것이지만 eRepublik Labs로 전송 된 귀하 데이터의 보안을 보장 할 수는 없습니다. 모든 전송은 귀하의 책임입니다. 귀하의 정보를 받으면 엄격한 절차와 보안 기능을 사용하여 무단 액세스를 방지합니다. 귀하는 인터넷을 통해 전송 된 도청 된 정보에 대해 당사가 책임을지지 않음을 인정하며, 이에 따라 귀하는 도청 된 정보의 무단 사용으로 인해 발생하거나 이와 관련된 모든 청구에서 당사를 면제합니다.</p>
            <p>5.4 귀하는 개인 식별 정보를 자발적으로 공개하고 게임 내 채팅, 개인 메시지, 포럼 또는 eRepublik 서비스의 다른 영역을 통해 해당 정보를 eRepublik 서비스에 공개하는 데 동의 할 때 해당 정보와 함께 귀하의 커뮤니케이션에 공개 된 실질적인 정보는 제 3 자에 의해 수집, 상관 및 사용될 수 있습니다. 이러한 제 3 자 또는 기타 eRepublik Service 사용자는 귀하의 정보를 사용하여 원치 않는 메시지를 보낼 수 있습니다. 그러한 활동은 우리가 통제 할 수 없습니다. eRepublik 서비스 또는 비공개로 유지하려는 다른 영역에 개인 정보를 게시하지 마십시오.</p>
            <ol start="6">
            <li><strong> 개인 정보 처리의 법적 근거</strong></li>
            </ol>
            <p>개인 정보 처리에 대한 정보 주체의 동의를받는 한, Art. 6 파라. 1 점등. GDPR은 법적 근거로 사용됩니다.</p>
            <p>정보 주체가 당사자 인 계약의 이행을 위하여 개인 정보의 처리가 필요한 경우, Art. 6 파라. 1 점등. b GDPR은 법적 근거로 사용됩니다. 이는 계약을 체결하기 전에 데이터 주체의 요청에 따른 단계에도 적용됩니다.</p>
            <p>개인 정보의 처리가 당사가 적용되는 법적 의무를 준수하기 위해 처리가 필요한 경우 Art. 6 파라. 1 점등. c GDPR은 법적 근거로 사용됩니다.</p>
            <p>정보 주체 또는 다른 자연인의 중요한 이익을 보호하기 위해 개인 정보의 처리가 필요한 경우 Art. 6 파라 1 점등. d GDPR은 법적 근거로 사용됩니다.</p>
            <p>공익을 위해 수행되는 업무를 수행하거나 당사가 보유한 공식 권한을 행사하기 위해 개인 정보 처리가 필요한 경우, Art. 6 파라 1 점등. e GDPR은 법적 근거로 사용됩니다.</p>
            <p>당사 또는 제 3자가 추구하는 정당한 이익의 목적을 위해 개인 정보의 처리가 필요한 경우, 특히 개인 정보 보호가 필요한 정보 주체의 이익 또는 기본적 권리와 자유에 의해 그러한 이익이 우선되는 경우를 제외하고 데이터 주체가 아동 인 경우 Art. 6 파라. 1 점등. f GDPR은 처리를위한 법적 근거 역할을합니다.</p>
            <ol start="7">
            <li><strong> 제 3 자 웹 사이트 링크</strong></li>
            </ol>
            <p>7.1 eRepublik 서비스는 때때로 당사의 파트너 네트워크, 광고주 및 계열사의 웹 사이트와 당사가 제휴하지 않은 제 3 자 웹 사이트와의 링크를 포함 할 수 있습니다. 이러한 웹 사이트에 대한 링크를 따라가는 경우, 이러한 웹 사이트에는 자체 개인 정보 보호 정책 및 이용 약관이 있으며 당사는 이러한 정책 또는 해당 웹 사이트의 활동에 대해 어떠한 책임도지지 않음을 유의하십시오.</p>
            <ol start="8">
            <li><strong> 개인 정보의 보유</strong></li>
            </ol>
            <p>개인 정보는 보관 목적이 해지되는 즉시 삭제되거나 접근이 제한됩니다. 계약 체결 또는 이행을 위해 개인 정보를 추가로 보관해야하는 경우를 제외하고 보관 기간이 만료되면 데이터도 삭제되거나 액세스가 제한됩니다.</p>
            <ol start="9">
            <li><strong> Facebook 또는 기타 App Store (Apple App Store, Google Play Store)를 통해 eRepublik 서비스에 등록</strong></li>
            </ol>
            <p>eRepublik 서비스의 일부를 사용하려면 Facebook, Apple App Store 및 Google Play Store를 통해 개인 정보를 제공하여 등록 할 수 있습니다. 데이터는 제 3 자에게 전달되지 않습니다.</p>
            <p>등록 과정에서 개인 정보를 처리하는 법적 근거는 Art에 따른 계약 관계입니다. 6 파라. 1 점등. b GDPR.</p>
            <ol start="10">
            <li><strong> 정보 주체의 권리</strong></li>
            </ol>
            <p>귀하의 개인 정보가 처리되는 경우 귀하는 당사에 대해 다음과 같은 권리를 갖습니다. 이러한 권한을 호출하려면 요청 유형을 지정하여 privacy@erepubliklabs.com으로 이메일을 보내십시오.</p>
            <ol>
            <li><strong>a) 접근권</strong></li>
            </ol>
            <p>귀하는 귀하에 관한 개인 정보가 처리되고 있는지 여부에 대한 확인을 당사로부터받을 권리가 있으며,이 경우 개인 데이터 및 다음 정보에 대한 액세스 권한이 있습니다.</p>
            <p>처리 목적;</p>
            <p>관련된 개인 데이터의 범주;</p>
            <p>개인 데이터가 공개되었거나 공개 될 수신자 또는 수신자 범주, 특히 제 3 국 또는 국제기구의 수신자</p>
            <p>가능한 경우 개인 데이터가 저장되는 예상 기간 또는 가능하지 않은 경우 해당 기간을 결정하는 데 사용되는 기준</p>
            <p>당사에 개인 데이터의 수정 또는 삭제 또는 개인 정보 처리 제한을 요청하거나 그러한 처리에 반대 할 권리가 있음</p>
            <p>감독 기관에 불만을 제기 할 권리;</p>
            <p>귀하로부터 개인 데이터가 수집되지 않는 경우, 그 출처에 관한 사용 가능한 정보</p>
            <p>Art에서 언급 된 프로파일 링을 포함한 자동화 된 의사 결정의 존재. 22 파라. 1 및 4 GDPR 및 최소한 이러한 경우 관련 논리에 대한 의미있는 정보는 물론 해당 처리의 중요성 및 예상 결과가 귀하에게 제공됩니다.</p>
            <p>귀하는 귀하에 관한 개인 데이터가 제 3 국 또는 국제기구로 이전되는지에 대한 정보를 요청할 권리가 있습니다. 이러한 맥락에서 Art에 따라 적절한 보증에 대한 정보를 요청할 수 있습니다. 46 전송과 관련된 GDPR.&nbsp;</p>
            <ol>
            <li><strong>b) 정정권</strong></li>
            </ol>
            <p>귀하는 부정확하거나 불완전한 개인 정보의 수정을 부당한 지연 내에 당사로부터 얻을 권리가 있습니다. 처리 목적을 고려할 때, 귀하는 보충 진술 제공을 포함하여 불완전한 개인 정보를 완성 할 권리가 있습니다.</p>
            <ol>
            <li><strong>c) 삭제할 권리 ( '잊혀 질 권리')</strong></li>
            </ol>
            <p>귀하는 당사로부터 부당한 지체없이 관련 개인 정보를 삭제할 권리가 있으며 당사는 다음 사유 중 하나가 적용되는 경우 부당한 지체없이 개인 정보를 삭제할 의무가 있습니다.</p>
            <ol>
            <li aria-level="2">개인 정보가 수집되거나 처리 된 목적과 관련하여 더 이상 필요하지 않습니다;</li>
            <li aria-level="2">귀하는 Art에 따른 처리에 대한 동의를 철회합니다. 6 para. 1 점등. GDPR 또는 예술. 9 파라. 2 점등. GDPR 및 처리에 대한 다른 법적 근거가없는 경우;</li>
            <li aria-level="2">귀하는 Art에 따른 처리에 반대합니다. 21 파라. 1 GDPR이며 처리에 대한 우선적 인 합법적 근거가 없거나 Art에 따른 처리에 반대합니다. 21 para 2 GDPR;</li>
            <li aria-level="2">개인 정보가 불법적으로 처리되었습니다;</li>
            <li aria-level="2">유럽 연합의 법적 의무를 준수하기 위해 개인 정보를 삭제해야합니다</li>
            <li aria-level="2">제 8 조 제 1 항에 언급 된 정보 사회 서비스 제공과 관련하여 개인 정보가 수집되었습니다.</li>
            </ol>
            <p>개인 정보를 공개하고 위의 규정에 따라 개인 정보를 삭제할 의무가있는 경우, 당사는 이용 가능한 기술 및 시행 비용을 고려하여 기술적 조치를 포함한 합리적인 조치를 취하여 정보를 처리하는 컨트롤러에게 알립니다. 데이터 주체가 해당 개인 데이터에 대한 링크 또는 복사 또는 복제의 삭제를 요청한 개인 데이터.</p>
            <p>삭제 권한은 처리가 필요한 경우에는 적용되지 않습니다:</p>
            <ol>
            <li aria-level="1">표현 및 정보의 자유 권리 행사</li>
            <li aria-level="1">유럽 연합의 처리가 필요한 법적 의무를 준수하거나 공익을 위해 수행되는 작업을 수행하기 위해</li>
            <li aria-level="1">법적 청구의 설정, 행사 또는 방어를 위해</li>
            </ol>
            <h1><strong>d) 처리 제한 권리</strong></h1>
            <p>귀하는 다음 중 하나가 적용되는 경우 당사로부터 처리 제한을받을 권리가 있습니다:</p>
            <ol>
            <li aria-level="2">개인 데이터의 정확성은 당사가 개인 데이터의 정확성을 확인할 수있는 기간 동안 귀하가 이의를 제기합니다.</li>
            <li aria-level="2">처리가 불법이며 데이터 주체가 개인 정보 삭제에 반대하고 대신 사용 제한을 요청합니다.</li>
            <li aria-level="2">우리는 더 이상 처리 목적으로 개인 데이터가 필요하지 않지만 법적 청구의 설정, 행사 또는 방어를 위해 데이터 주체가 필요로합니다.</li>
            <li aria-level="2">Art에 따른 처리에 이의를 제기했습니다. 21 파라. 1 GDPR은 당사의 정당한 근거가 데이터 주체의 근거보다 우선하는지 여부를 확인하기 위해 보류 중입니다.</li>
            </ol>
            <p>처리가 제한된 경우 이러한 개인 데이터는 저장을 제외하고 귀하의 동의가 있거나 법적 청구의 설정, 행사 또는 방어를 위해 또는 다른 자연인 또는 법인의 권리 보호를 위해 또는 이유가있는 경우에만 처리됩니다. 유럽 연합 또는 회원국의 중요한 공익을 위해.</p>
            <p>위의 사항에 따라 처리 제한을받은 경우 처리 제한이 해제되기 전에 당사에서 알려드립니다.</p>
            <h1><strong>e) 데이터 이동에 대한 권리</strong></h1>
            <p>귀하는 구조화되고 일반적으로 사용되며 기계가 읽을 수있는 형식으로 당사에 제공 한 개인 정보를 수신 할 권리가 있으며 개인 정보가있는 컨트롤러의 방해없이 해당 데이터를 다른 컨트롤러로 전송할 권리가 있습니다. 제공되었습니다.</p>
            <ol>
            <li aria-level="2">처리는 Art에 따른 동의를 기반으로합니다. 6 파라 1 점등. 또는 예술. 6 파라 1 점등. b 또는 Art. 2 para 2 점등. ㅏ</li>
            <li aria-level="2">처리는 자동화 된 수단으로 수행됩니다.</li>
            </ol>
            <p>권리는 타인의 권리와 자유에 악영향을 미치지 않습니다.</p>
            <p>데이터 이동성에 대한 귀하의 권리를 행사함에있어 귀하는 기술적으로 가능한 경우 개인 정보를 한 컨트롤러에서 다른 컨트롤러로 직접 전송할 권리가 있습니다.</p>
            <p>이 권리의 행사는 삭제 권리를 침해하지 않습니다. 이 권리는 공익을 위해 수행되거나 컨트롤러에게 부여 된 공식 권한을 행사하는 데 필요한 처리에는 적용되지 않습니다.</p>
            <h1><strong>f) 개인 데이터의 정정 또는 삭제 또는 처리 제한에 대한 알림</strong></h1>
            <p>당사는 불가능한 것으로 입증되거나 불균형적인 노력이 수반되지 않는 한 개인 데이터의 수정 또는 삭제 또는 개인 데이터가 공개 된 각 수신자에게 전달 된 처리 제한을 전달합니다. 요청하면 해당 수신자에 대해 알려드립니다.</p>
            <h1><strong>g) 반대 할 권리</strong></h1>
            <p>귀하는 귀하의 특정 상황과 관련된 근거로 Art를 기반으로 한 개인 데이터 처리에 대해 언제든지 이의를 제기 할 권리가 있습니다. 6 파라. 1 점등 e) 또는 점등. 에프). 당사는 데이터 주체의 이익, 권리 및 자유를 무시하거나 법적 청구의 설정, 행사 또는 방어를위한 처리에 대한 강력한 정당한 근거를 입증하지 않는 한 더 이상 개인 정보를 처리하지 않습니다.</p>
            <p>직접 마케팅 목적으로 개인 정보가 처리되는 경우, 귀하는 그러한 마케팅을위한 귀하의 개인 데이터 처리에 대해 언제든지 이의를 제기 할 권리가 있습니다. 여기에는 이러한 직접 마케팅과 관련된 정도까지 프로파일 링이 포함됩니다.</p>
            <p>직접 마케팅 목적으로 처리하는 것을 거부하는 경우 개인 정보는 더 이상 그러한 목적으로 처리되지 않습니다.</p>
            <p>늦어도 귀하와 처음 통신 할 때 위에서 언급 한 권리가 귀하에게 명시 적으로 전달되어야하며 다른 정보와 명확하고 별도로 제시되어야합니다.</p>
            <p>정보 사회 서비스 사용과 관련하여 Directive 2002 / 58 / EC에도 불구하고 귀하는 기술 사양을 사용하는 자동화 된 수단을 통해 이의를 제기 할 수 있습니다.</p>
            <ol>
            <li><strong>h) 자동화 된 개별 의사 결정</strong></li>
            </ol>
            <p>귀하는 법적 효력을 발생 시키거나 귀하에게 유사하게 중대한 영향을 미치는 프로파일 링을 포함하여 자동화 된 처리만을 기반으로 한 결정을받지 않을 권리가 있습니다.</p>
            <p>다음과 같은 경우에는 적용되지 않습니다:</p>
            <ol>
            <li aria-level="1">귀하와 당사 간의 계약을 체결하거나 이행하는 데 필요합니다.</li>
            <li aria-level="1">유럽 연합 법률에 의해 승인을 받았으며 귀하의 권리와 자유 및 합법적 이익을 보호하기위한 적절한 조치를 마련했습니다. 또는</li>
            <li aria-level="1">귀하의 명시 적 동의를 기반으로합니다.</li>
            </ol>
            <p>당사는 귀하의 권리와 자유 및 합법적 이익을 보호하기 위해 적절한 조치를 취하고, 최소한 당사 측의 인간 개입을 확보하고, 그의 관점을 표현하고 결정에 이의를 제기 할 권리를 구현합니다.</p>
            <p>Art. 9 para. 2 점등. a) 또는 lit g) GDPR이 적용되고 귀하의 권리와 자유 및 합법적 이익을 보호하기위한 적절한 조치가 마련되어 있습니다.</p>
            <ol>
            <li><strong>i) 감독 기관에 불만을 제기 할 권리</strong></li>
            </ol>
            <p>다른 행정적 또는 사 법적 구제 수단을 침해하지 않고, 귀하에 관한 개인 정보의 처리가 GDPR을 준수하지 않습니다. 불만이 제기 된 감독 기관은 Art에 따른 사 법적 구제 가능성을 포함하여 불만의 상태와 결과를 불만 제 기자에게 알려야합니다. 78 GDPR.</p>
            <ol start="11">
            <li><strong> IP 주소 및 쿠키</strong></li>
            </ol>
            <p>11.1 귀하가 모바일 장치에서 eRepublik 서비스에 액세스하는 경우 당사는 귀하의 IP 주소, 장치 영숫자 ID, 운영 체제 (iOS, Android, OS 버전) 및 브라우저 유형, 장치 ID, 브랜드를 포함하여 귀하의 장치에 대한 정보를 수집 할 수 있습니다. 및 모델, 사용 된 이동 통신사에 관한 정보, 네트워크 유형 (Wi-Fi / 모바일 데이터), 데이터 전송량 및 상태, 클릭 스루 통계, 인터넷에 액세스하는 도메인 및 호스트 이름, 액세스 한 날짜 및 시간 eRepublik 서비스의 일부.</p>
            <p>11.3 추적 기술을 통해</p>
            <p>11.3.1 고객 규모 및 사용 패턴 추정</p>
            <p>11.3.2 귀하의 기본 설정에 대한 정보를 저장하여 귀하의 개별 관심사에 따라 eRepublik 서비스를 사용자 지정할 수 있도록합니다. 과</p>
            <p>11.3.3 eRepublik 서비스 및 검색 사용 속도 향상.</p>
            <ol start="12">
            <li><strong> 정보에 대한 액세스 및 개인 데이터 업데이트, 확인 및 삭제</strong></li>
            </ol>
            <p>12.1 귀하는 언제든지 당사가 귀하에 대해 보유하고있는 모든 개인 정보에 접근 할 수 있도록 서면 요청을 할 수 있습니다. 이 정보를 귀하에게 제공하고 소액의 관리 비용을 요청할 수 있습니다.</p>
            <p>12.2 eRepublik 서비스에 등록 된 사용자는 게임 내 지원 센터 또는 privacy@erepubliklabs.com을 통해 당사에 연락하여 개인 정보를 수정하고 업데이트 할 수 있습니다. 계정 삭제를 선택한 경우 privacy@erepubliklabs.com으로 연락하여 계정 삭제를 요청하세요. 그러나 사용자 이름, 아바타,베이스, 기타 게임 내 액션, 게임 내 채팅의 요소 또는 게시물은 게임 기록에 기록됩니다. 이러한 게시물과 함께 제공되는 유일한 정보는 사용자 이름과 아바타입니다.</p>
            <ol start="13">
            <li><strong> 개인 정보 보호 정책 변경</strong></li>
            </ol>
            <p>13.1 향후 당사의 개인 정보 보호 정책에 대한 모든 변경 사항은이 페이지에 게시되며 적절한 경우 이메일로 귀하에게 통지됩니다. 이 페이지를 주기적으로 방문하여 개인 정보 보호 정책의 변경 사항을 검토해야합니다.</p>
            <ol start="14">
            <li><strong> 사용자 피드백 및 불만, 외부 데이터 보호 책임자</strong></li>
            </ol>
            <p>14.1이 개인 정보 보호 정책에 관한 질문, 의견 및 요청은 환영하며 privacy@erepubliklabs.com 또는 게임 내에서 사용 가능한 도움말 및 지원 메뉴를 통해 보내야합니다.</p>
            <p>14.2 데이터 보호 담당자는 dpo@stillfront.com으로 연락 할 수 있습니다.</p>
        </div>
        <div v-if="lang=='JP'" class="pp-text">
            <p>ここeRepublikLabsでは、お客様のプライバシーを尊重し、お客様のプライバシー権を尊重します。</p>
            <p><strong>eRepublikサービス</strong>（以下に定義）を使用することにより、以下に概説するように個人情報の収集と使用を許可し、このプライバシーポリシーに同意したことになります。 これらの条件に同意しない場合は、eRepublikサービスを使用しないでください。</p>
            <ol>
            <li><strong> プライバシーポリシーについて</strong></li>
            </ol>
            <p>eRepublik Labs Limited of the Unit 4、Block A、Broomfield Business Park、Malahide、Co Dublin、K36 RF83、Ireland、登録番号 462101、VAT番号IE-9693023K（eRepublik Labs、私たち、私たち、および私たち）は、http：//gameofnations.netWebページとGameiOSおよびAndroidAppsを含む、Game of Nations Gameを提供し、運用しています。 「ゲーム」）、およびゲーム内プロファイルと、ここにリストされていないゲームの他の領域。これらは将来作成されるか、作成される可能性があります（「eRepublikサービス」）。</p>
            <p>1.1 eRepublikサービスでは、さまざまなアクティビティに参加して参加し、eRepublikサービスで随時利用できる機能を使用するために、<strong>ゲーム</strong>のプレーヤーとして登録することができます。</p>
            <p>1.2 このポリシー（利用規約<a href="https://www.erepubliklabs.com/terms-of-service/gon">https://www.erepubliklabs.com/terms-of-service/gon</a>/およびその中で参照されているその他のドキュメントとともに）は、当社が収集する個人データの基礎を定めています。 あなた、またはあなたが私たちに提供するものは、私たちによって処理されます。 以下を注意深くお読みになり、お客様の個人データに関する当社の見解と慣行、およびその取り扱い方法を理解してください。</p>
            <ol start="2">
            <li><strong> ゲームに関する情報の収集方法</strong></li>
            </ol>
            <p>2.1 当社は、eRepublikサービスを提供するために必要な場合に限り、ユーザーの個人情報を収集および使用します。 当社は、利用者の同意がある場合、または法律により個人情報の収集および処理が許可されている場合にのみ、個人情報を収集および処理します。 eRepublikサービスのユーザーとして登録するとき、またはeRepublikサービスのユーザーとして登録するときは、情報を提供するように求められます。 eRepublikサービスを使用するために情報を提供する必要があるかどうか、または当社が要求する情報の提供がオプションであるかどうかは、他の情報を収集するときに通知されます。 あなたが私たちに提供する情報がeRepublikサービスの他のユーザーまたは第三者によってアクセス可能であるかどうかをあなたから情報を収集するときに通知し、あなたが私たちに情報を提供するかどうかを決定できるようにします。</p>
            <p>2.2 当社は、お客様に関する以下の個人データを収集および処理する場合があります。</p>
            <ul>
            <li aria-level="1">-Eメール</li>
            <li aria-level="1">-Google ID</li>
            <li aria-level="1">-FacebookのID</li>
            <li aria-level="1">-Facebookメール</li>
            <li aria-level="1">-市と国</li>
            <li aria-level="1">-IP</li>
            <li aria-level="1">-次のようなモバイルデバイス関連情報：imei（デバイスID）; ブランド、モデル、キャリア; オペレーティングシステム/プラットフォーム（iOS、Android）およびOSバージョン; ネットワークタイプ：WiFi /モバイルデータ</li>
            </ul>
            <p>2.2.1お客様が（電子メールまたは郵便を含めて）当社に連絡した場合、当社はその通信およびそれに含まれる情報の記録を保持する場合があります。</p>
            <p>2.2.2ゲーム内チャットディスカッション、プライベートメッセージ、またはその他のゲーム内通信チャネルを介して送信する情報。</p>
            <p>2.2.3ゲームプレイの選択を含む、eRepublikサービスを通じて使用する機能。</p>
            <p>2.2.4eRepublikサービスを通じて実行する金融取引の詳細。 そして</p>
            <p>2.2.5 eRepublikサービスへのアクセスの詳細。これには、トラフィックデータ、位置データ、ウェブログ、その他の通信データが含まれますが、これが当社の請求目的で必要かどうか、およびアクセスするリソースが含まれます。</p>
            <p><br /><br /></p>
            <p>2.3 16歳未満の人は、eRepublikサービスに登録する資格がありません。 16〜18歳の場合は、このプライバシーポリシーを親または保護者に通知し、このプライバシーポリシーに同意し、eRepublikサービスを使用することに同意したことに同意するものとします（ ただし、そのような同意の付与は法律で禁止されていません）。</p>
            <p><strong>3.情報の利用</strong></p>
            <p>3.1当社のユーザーは、ディスカッションフォーラム、アライアンス、ゲーム内のプライベートメッセージ、またはゲーム内のチャットチャネルを通じて、eRepublikサービスで他のユーザーと対話することにより、仮想コミュニティに参加できます。 これらは、eRepublikサービスの他のユーザーがアクセスして読み取ることができます。 eRepublikサービスの登録ユーザーになることを選択した場合、お客様の個人情報は表示されません。 他のユーザーが利用できるのはゲーム内情報のみです。これは、ユーザーがゲーム内アクティビティとeRepublikサービスの通信を評価できるようにする情報です。</p>
            <p>3.2当社は、お客様に関して保持されている情報を次の方法で使用します。</p>
            <p>3.2.1 eRepublikサービスのコンテンツが、お客様およびお客様のコンピューターまたはデバイスにとって最も効果的な方法で提示されるようにするため。 情報のホスティングと表示、更新の提供、eRepublikサービスの開発、改善、拡張、技術的な問題への対処など。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 bGDPR。</p>
            <p>3.2.2お客様が当社に要求する情報、製品、またはサービスをお客様に提供するため。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 b GDPR。</p>
            <p>3.2.3 eRepublikサービスの広告機能とのやり取りなど、そのような目的で連絡を受けることに同意した場合に、お客様が関心を持つと思われる情報（パートナーの製品またはサービスを含む）を提供するため。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 GDPR。 私たちのモバイルゲームでは、インセンティブ広告を提供する広告プラットフォームを使用しています（たとえば、ユーザーは広告を見ることで報酬を受け取ります）。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 f GDPR。 私たちのゲームで広告を提供し、ユーザーにゲームで報酬を受け取る機会を与えることは私たちの利益です。&nbsp;</p>
            <p>3.2.4お客様と当社の間で締結された契約から生じる当社の義務を履行するため。 eRepublikサービスでアカウントを確立および維持し、アカウントのセキュリティを保護するため。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 b GDPR。</p>
            <p>3.2.5高品質のサービスを提供および維持するため。 カスタマーサポートを提供するため、または顧客の問題を解決するため。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 b GDPR。</p>
            <p>3.2.6選択した場合に、eRepublikサービスにアクセスし、当社のサービスのインタラクティブ機能に参加できるようにするため。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 b GDPR。</p>
            <p>3.2.7当社のサービスの変更、eRepublikサービスの新機能または新バージョン、当社または当社のパートナーによって開発された新しいゲームまたはアプリケーションについて通知するため。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 b GDPRとアート。 6パラ。 1点灯。 f GDPR。 当社の製品およびパートナーの製品に関するマーケティング情報をお客様に提供することに関心があります。</p>
            <p>3.2.8統計を編集し、ユーザーに関する情報を生成および分析して、ユーザーとユーザーがeRepublikサービスをどのように使用しているかをよりよく理解し、この情報に基づいて傾向と予測を引き出すため。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 f GDPR。 私たちの関心は私たちのサービスを改善することにあります。</p>
            <p>3.2.9 eRepublikサービスの規則に準拠しているかどうかを判断し、必要に応じて制裁を課す。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 f GDPR。 私たちの関心は、サービスの改善と不正の検出にあります。</p>
            <p>場合によっては、支払いを処理するための3.2.10。 個人情報の処理の法的根拠はアートです。 6パラ。 1点灯。 b GDPR。</p>
            <p>3.2.11ニュースレターにサインアップした場合、ニュース、プロモーション目的、または市場調査のために電子メールで連絡し、そのような電子メールにあるオプトアウトリンクをクリックしてオプトアウトすることができます。 個人情報の処理の法的根拠、既存の同意の場合、アート。 6パラ。 1点灯。 GDPR。</p>
            <p>個人情報は、収集した目的を達成するために必要がなくなった時点で削除されます。 したがって、ユーザーの電子メールアドレスは、ニュースレターの購読がアクティブである限り保存されます。 ユーザーは、各電子メールニュースレターの最後にある購読解除リンクをクリックすることにより、いつでもニュースレターの購読をキャンセルできます。</p>
            <p>3.4第三者のユーザーからの要求に応じた明示的な許可がある場合を除き、個人を特定できる情報が第三者のWebサイトまたはアプリにエクスポートされることはありません。 あなたがあなたのデータを使用するためにあなたが第三者のユーザーに与えるどんな許可もあなたと第三者のユーザーの問題であることに注意してください。 私たちは、あなたが彼らにあなたのデータの使用を許可することを選択した方法について責任を負いません。</p>
            <p>3.5 eRepublikサービスの任意の領域に投稿したコンテンツを販売またはライセンス供与して、コンテンツをパーソナライズし、人気のあるメンバーを特定/紹介する場合があります。 たとえば、最も人気のあるアクティブメンバーを宣伝する場合があります。 完全なメンバーデータベースを他の人に転送することは期待していませんが、ネットワーク全体でデータベース内のニックネームとアバターを使用し、ユーザーが互いに連絡を取り合ったり、相互に連絡したりできるようにするなど、他の人の結果を生成します。 ユーザーコミュニケーション。</p>
            <p><strong>4.他の人へのあなたの情報の開示</strong></p>
            <p>4.1当社は、お客様の許可または法律で許可または要求されている場合を除き、お客様から提供された個人情報を第三者に開示しません。</p>
            <p>4.2お客様が当社に個人情報を提供する場合、お客様は、当社がお客様の個人情報を以下の第三者に開示する場合があることを理解し、同意するものとします。</p>
            <p>4.2.1 eRepublik Labsホスティングプロバイダーへ：EUおよび米国にあるサーバーを使用するAmazon Inc.</p>
            <p>あなたは、私たちがいつでもホスティングプロバイダーを変更する可能性があり、そのような変更はこのプライバシーポリシーの修正によってeRepublikサービスに掲載されることを認めます。 お客様は、個人データを新しいホスティングプロバイダーのサーバーに転送することに同意するものとします。 ホスティングプロバイダーの変更に関する質問、コメント、またはリクエストは、privacy @ erepubliklabs.comに電子メールを送信するか、ゲーム内で利用可能なヘルプとサポートメニューを介して伝達できます。</p>
            <p>4.2.2登録時、またはeRepublikサービスのユーザーとしての任意の時点で、関心のある可能性のある商品やサービスに関する情報やプロモーションを選択したサードパーティから喜んで受け取ることを示した場合は、 そのようなオファーや情報を送信することに同意します。 あなたはprivacy@erepubliklabs.comに電子メールを送ることによっていつでもそのような申し出を受けることをオプトアウトするか、あなたの好みを変えることができます。</p>
            <p>4.2.3 eRepublikサービスの更新、新機能、新バージョンなど、当社のサービスに関する情報を送信することを唯一の目的として、電子メールサービスを提供する会社に。 これらの会社は、この記事に記載されている以外の目的でお客様の個人情報を使用することを禁じられています。 あなたはprivacy@erepubliklabs.comに電子メールを送ることによっていつでもそのような申し出を受けることをオプトアウトするか、あなたの好みを変えることができます。</p>
            <p>4.2.4 eRepublikサービス、またはeRepublik Labs Limited、ERPK LABS SRL、または当社グループの他のメンバーの全部または一部に関連するビジネスまたは資産の将来の売り手、買い手、または譲受人。 そして</p>
            <p>4.2.5法的義務を遵守するため、または利用規約を施行または適用するために、お客様の個人データを第三者と開示または共有する義務がある場合は、第三者にhttps：// www.erepubliklabs.com/terms-of-service/gon/、または当社グループの企業、eRepublik Labs、お客様、その他の登録ユーザーなどの権利、財産、または安全を保護するため。 これには、不正防止および信用リスクの軽減を目的とした第三者との情報交換が含まれます。</p>
            <p>4.2.6関連する広告を表示できるようにする広告主へ。</p>
            <p>4.3上記以外の方法でお客様に関する情報が第三者に提供される可能性がある場合は通知します。また、通知時にそのような情報の共有を防止するオプションがあります。お客様の同意なしにかかる情報を転送することはありません。</p>
            <p>4.4このポリシーに記載されている場合を除き、お客様の個人情報を他の第三者（広告主またはマーケティング会社を含む）に開示することはありませんが、eRepublikサービスユーザーに関する匿名の集計情報を提供する場合があります。たとえば、30歳未満の男性500人が特定の日にeRepublikサービスに表示された広告をクリックしたことを通知したり、eRepublikサービスにアクセスしたユニークユーザーの数、人口統計の内訳を第三者に通知したりする場合があります。 eRepublikサービスのコミュニティユーザーまたはeRepublikサービスへの訪問者がeRepublikサービスを利用している間に行う活動。また、このような匿名化された集計情報を使用して、広告主が当社のWebサイトに広告を表示するときに、ターゲットとする種類のオーディエンス（たとえば、アイルランド南東部の女性）にリーチできるようにする場合があります。&nbsp;</p>
            <p>4.5個人情報を欧州経済領域（EEA）外に転送する必要がある場合があります。 これは、当社のサーバーまたはサプライヤーとサービスプロバイダーがEEA外に拠点を置いている場合、またはEEA外の国を訪問しているときに当社のサービスと製品を使用している場合に発生する可能性があります。 これらの国のデータ保護およびその他の法律は、アイルランドまたはEUの法律ほど包括的ではない場合がありますが、そのような場合、お客様のプライバシー権が尊重されるように措置を講じます。 お客様は、当社がそれに基づいてお客様の個人データを転送する場合があることに同意するものとします。</p>
            <ol start="5">
            <li><strong> 個人データのセキュリティと管理</strong></li>
            </ol>
            <p>5.1当社は、お客様のデータが安全に、このプライバシーポリシーに従って取り扱われることを保証するために、合理的に必要なすべての措置を講じます。</p>
            <p>5.2 eRepublikサービスの特定の部分にアクセスできるようにするパスワードを当社がお客様に提供した場合（またはお客様が選択した場合）、お客様はこのパスワードの機密を保持する責任があります。 パスワードは誰とも共有しないでください。 あなたはあなたのパスワードのセキュリティに責任があり、私たちの利用規約https://www.erepubliklabs.com/terms-of-service/gon/はこれをより詳細に扱います。</p>
            <p>5.3残念ながら、インターネットを介した情報の送信は完全に安全ではありません。私たちはあなたの個人データを保護するために最善を尽くしますが、eRepublikLabsに送信されるあなたのデータのセキュリティを保証することはできません。送信はご自身の責任で行ってください。お客様の情報を受け取り次第、厳格な手順とセキュリティ機能を使用して、不正アクセスの防止に努めます。<strong>お客様は、インターネットを介して送信された傍受された情報について当社が責任を負わないことを認め、これにより、不正な方法での傍受された情報の使用に起因または関連するすべての請求から当社を解放します。</strong></p>
            <p>5.4個人を特定できる情報を自発的に開示し、ゲーム内チャット、個人メッセージ、フォーラム、またはeRepublikサービスの他の領域を介して、その情報をeRepublikサービスで公開することに同意する場合は、その情報をあなたのコミュニケーションで開示された実質的な情報は、第三者によって収集、関連付け、使用される可能性があります。これらのサードパーティまたは他のeRepublikServiceユーザーは、あなたの情報を使用して、一方的なメッセージを送信する場合があります。そのような活動は私たちのコントロールを超えています。 eRepublikサービスまたは非公開にしたいその他の領域に個人情報を投稿しないでください。&nbsp;</p>
            <ol start="6">
            <li><strong> 個人情報の処理の法的根拠</strong></li>
            </ol>
            <p>個人情報の処理についてデータ主体の同意を得る限り、アート。 6パラ。 1点灯。 GDPRは法的根拠として機能します。</p>
            <p>データ主体が当事者である契約の履行に必要な個人情報の処理の場合、Art。 6パラ。 1点灯。 b GDPRは法的根拠として機能します。これは、契約を締結する前のデータ主体の要求に応じたステップにも適用されます。</p>
            <p>個人情報の処理が処理である限り、私たちが従う法的義務、アートを遵守するために処理が必要です。 6パラ。 1点灯。 c GDPRは法的根拠として機能します。</p>
            <p>データ主体または他の自然人、アートの重大な利益を保護するために個人情報の処理が必要な場合。 6パラ1点灯。 d GDPRは法的根拠として機能します。</p>
            <p>公益のために、または当社に付与された公的権限の行使において実行されるタスクの実行のために個人情報の処理が必要な場合、Art。 6パラ1点灯。 e GDPRは法的根拠として機能します。</p>
            <p>当社または第三者が追求する正当な利益のために個人情報の処理が必要な場合。ただし、そのような利益が、特に個人情報の保護を必要とするデータ主体の利益または基本的な権利および自由によって上書きされる場合を除きます。データ主体が子供である場合、Art。 6パラ。 1点灯。 f GDPRは、処理の法的根拠として機能します。</p>
            <p><strong>7.サードパーティのWebサイトへのリンク</strong></p>
            <p>7.1 eRepublikサービスには、当社のパートナーネットワーク、広告主、関連会社のWebサイト、および当社が提携していないサードパーティのWebサイトとの間のリンクが含まれる場合があります。 これらのウェブサイトへのリンクをたどる場合、これらのウェブサイトには独自のプライバシーポリシーと利用規約があり、これらのポリシーまたはこれらのウェブサイトでの活動について当社は一切の責任を負わないことに注意してください。</p>
            <p><strong>8.個人情報の保持</strong></p>
            <p>保管の目的が適用されなくなるとすぐに、個人情報が削除されるか、アクセスが制限されます。 契約の締結または履行のために個人情報をさらに保管する必要がない限り、保管期間が満了すると、データも削除されるか、アクセスが制限されます。</p>
            <ol start="9">
            <li><strong> .Facebookまたは他のAppStore（Apple App Store、Google Play Store）を介したeRepublikサービスへの登録</strong></li>
            </ol>
            <p>eRepublikサービスの一部を使用するには、Facebook、Apple App Store、およびGooglePlayストアを介して個人情報を提供することにより登録できます。 データが第三者に渡されることはありません。</p>
            <p>登録の過程で個人情報を処理するための法的根拠は、アートによる契約関係です。 6パラ。 1点灯。 b GDPR。</p>
            <p><strong>10.データ主体の権利</strong></p>
            <p>あなたの個人情報が処理される場合、あなたは私たちに対して以下の権利を有します。 これらの権利を行使するには、リクエストの種類を明記したメールをprivacy@erepubliklabs.comに送信してください。</p>
            <ol>
            <li><strong>a) アクセス権</strong></li>
            </ol>
            <p>お客様は、お客様に関する個人情報が処理されているかどうか、およびその場合は個人データおよび以下の情報へのアクセスに関する確認を当社から取得する権利を有します。</p>
            <ol>
            <li aria-level="2">処理の目的;</li>
            <li aria-level="2">関連する個人データのカテゴリ。</li>
            <li aria-level="2">個人データが開示された、または開示される予定の受信者または受信者のカテゴリ、特に第三国または国際機関の受信者。</li>
            <li aria-level="2">可能な場合は、個人データが保存される予定の期間、または不可能な場合は、その期間を決定するために使用される基準。</li>
            <li aria-level="2">個人データの訂正または消去、個人情報の処理の制限を当社に要求する権利、またはそのような処理に異議を唱える権利の存在。</li>
            <li aria-level="2">監督当局に苦情を申し立てる権利。</li>
            <li aria-level="2">個人データがお客様から収集されていない場合、そのソースに関する入手可能な情報。</li>
            <li aria-level="2">アートで言及されている、プロファイリングを含む自動化された意思決定の存在。 22パラ。 1および4 GDPRと、少なくともそのような場合は、関連するロジックに関する意味のある情報、およびそのような処理の重要性と想定される結果。</li>
            </ol>
            <p>あなたには、あなたに関する個人データが第三国に転送されるのか、国際機関に転送されるのかに関する情報を要求する権利があります。 これに関連して、アートに基づく適切な保証についての通知を要求することができます。 送信に関連する46 GDPR。</p>
            <ol>
            <li><strong>b) 修正の権利</strong></li>
            </ol>
            <p>お客様は、不正確または不完全な個人情報の修正を不当に遅らせることなく、当社から取得する権利を有します。処理の目的を考慮して、補足説明を提供することを含め、不完全な個人情報を完成させる権利を有するものとします。</p>
            <p><strong>c）消去する権利（「忘れられる権利」）</strong></p>
            <p>お客様は、当社から個人情報の消去を遅滞なく取得する権利を有し、以下のいずれかの理由が当てはまる場合、当社は遅滞なく個人情報を消去する義務を負います。</p>
            <p>（1）個人情報は、収集またはその他の方法で処理された目的に関連して不要になりました。 </p>
            <p>（2）アートに従って処理の基礎となる同意を撤回します。6パラ1点灯。GDPRまたはアート。9パラ。2点灯。GDPR、および処理のための他の法的根拠がない場合。 </p>
            <p>（3）アートに基づく処理に異議を唱える。21パラ。1 GDPRであり、処理の正当な理由がない場合、またはアートに基づく処理に異議を唱える場合。21パラ2GDPR; </p>
            <p>（4）個人情報が違法に処理された場合。 </p>
            <p>（5）欧州連合の法的義務を遵守するために、個人情報を消去する必要があります </p>
            <p>（6）個人情報は、第8条第1項に規定する情報化社会サービスの提供に関連して収集されたものです。 </p>
            <p>当社が個人情報を公開し、上記に基づき個人情報の消去を義務付けられている場合、当社は、利用可能な技術および実施コストを考慮して、技術的措置を含む合理的な措置を講じて、処理中の管理者に通知するものとします。データ主体がそれらの個人データへのリンク、またはそれらの個人データのコピーまたは複製のそのような管理者による消去を要求した個人データ。</p>
            <p>消去する権利は、処理が必要な範囲では適用されないものとします。</p>
            <p>（1）表現および情報の自由の権利を行使するため。 </p>
            <p>（2）欧州連合による処理を必要とする法的義務の遵守、または公益のために実行されるタスクの実行。 </p>
            <p>（3）法的請求の確立、行使、または弁護のため。 </p>
            <h1><strong>d）処理を制限する権利</strong></h1>
            <p>お客様は、以下のいずれかに該当する場合、当社から処理の制限を取得する権利を有するものとします。</p>
            <p>（1）個人データの正確性は、私たちが個人データの正確性を検証することを可能にする期間、あなた自身によって争われます。 </p>
            <p>（2）処理が違法であり、データ主体が個人情報の消去に反対し、代わりにそれらの使用の制限を要求する。 </p>
            <p>（3）処理の目的で個人データは不要になりましたが、法的請求の確立、行使、または防御のためにデータ主体によって必要とされています。 </p>
            <p>（4）アートに基づく処理に異議を唱えました。21パラ。1 GDPRは、私たちの正当な理由がデータ主体の正当な理由を無効にするかどうかの検証を保留しています。 </p>
            <p>処理が制限されている場合、そのような個人データは、保管を除き、お客様の同意がある場合、法的請求の確立、行使、防御、または他の自然人または法人の権利の保護のため、または理由がある場合にのみ処理されるものとします欧州連合または加盟国の重要な公益の。</p>
            <p>上記の処理制限を取得した場合は、処理制限を解除する前にご連絡いたします。</p>
            <h1><strong>e）データの移植性に対する権利</strong></h1>
            <p>あなたには、あなたが私たちに提供した個人情報を、構造化され、一般的に使用され、機械で読み取り可能な形式で受け取る権利があり、個人情報が持っているコントローラーからの妨害なしにそれらのデータを別のコントローラーに送信する権利があります提供された場所：</p>
            <p>（1）処理は、アートに基づく同意に基づいています。6パラ1点灯。またはアート。6パラ1点灯。bまたはアート。2パラ2点灯。a </p>
            <p>（2）処理は自動化された手段によって実行されます。 </p>
            <p>権利は、他者の権利と自由に悪影響を及ぼさないものとします。</p>
            <p>データの移植性に対する権利を行使する際、技術的に可能な場合、個人情報をあるコントローラーから別のコントローラーに直接送信する権利を有するものとします。</p>
            <p>この権利の行使は、消去の権利を害することなく行われるものとします。その権利は、公益のために、または管理者に付与された公的権限の行使において実行されるタスクの実行に必要な処理には適用されないものとします。</p>
            <h1><strong>f）個人データの訂正または消去または処理の制限に関する通知</strong></h1>
            <p>当社は、個人情報の訂正または消去、または個人情報が開示された各受信者に実施された処理の制限について、不可能であると判明した場合または不釣り合いな努力を伴う場合を除き、通知します。ご要望があれば、それらの受取人についてお知らせします。</p>
            <h1><strong>g）異議を唱える権利</strong></h1>
            <p>あなたは、あなたの特定の状況に関連する理由で、アートに基づく彼または彼女に関する個人データの処理にいつでも異議を唱える権利を有するものとします。6パラ。1点灯e）または点灯。f）。当社は、データ主体の利益、権利および自由を無効にする処理、または法的請求の確立、行使、または防御のための説得力のある正当な理由を示さない限り、個人情報を処理しません。</p>
            <p>個人情報がダイレクトマーケティングの目的で処理される場合、お客様は、そのようなダイレクトマーケティングに関連する範囲でのプロファイリングを含む、そのようなマーケティングのための個人データの処理にいつでも異議を唱える権利を有するものとします。</p>
            <p>ダイレクトマーケティングの目的での処理に異議を唱える場合、個人情報はそのような目的で処理されなくなります。</p>
            <p>遅くともお客様との最初の連絡の時点で、上記の権利は明示的にお客様の注意を喚起し、他の情報とは別に明確に提示されるものとします。</p>
            <p>情報化社会サービスの使用に関連して、指令2002/58 / ECにかかわらず、技術仕様を使用した自動化された手段によって異議を申し立てる権利を行使することができます。</p>
            <p><strong>h）自動化された個人の意思決定</strong></p>
            <p>お客様は、プロファイリングを含む自動処理のみに基づく決定の対象とならない権利を有します。これは、法的効果を生み出すか、同様にお客様に重大な影響を及ぼします。</p>
            <p>これは、決定が次の場合には適用されないものとします。</p>
            <p>a）お客様と当社の間で契約を締結または履行するために必要です </p>
            <p>b）欧州連合法によって認可されており、あなたの権利と自由および正当な利益を保護するための適切な措置も定めています。または </p>
            <p>c）お客様の明示的な同意に基づいています。 </p>
            <p>私たちは、あなたの権利と自由および正当な利益、少なくとも私たちの側で人間の介入を得る権利、彼または彼女の見解を表明し、決定に異議を唱える権利を保護するための適切な措置を実施します。</p>
            <p>アートを除き、決定は特別なカテゴリーの個人データに基づくものではありません。9para.2点灯。a）または点灯g）GDPRが適用され、お客様の権利と自由および正当な利益を保護するための適切な措置が講じられています。</p>
            <p><strong>i）監督当局に苦情を申し立てる権利</strong></p>
            <p>他の行政上または司法上の救済を害することなく、特にあなたが居住、勤務、または侵害の疑いがある欧州加盟国において、あなたに関する個人情報の処理がGDPRに準拠していません。苦情が申し立てられた監督当局は、アートに基づく司法救済の可能性を含め、苦情の状況と結果を申立人に通知するものとします。78GDPR。</p>
            <p><strong>11.IPアドレスとCookie</strong></p>
            <p>11.1モバイルデバイスからeRepublikサービスにアクセスする場合、IPアドレス、デバイスの英数字ID、オペレーティングシステム（iOS、Android、OSバージョン）、ブラウザーの種類、デバイスID、ブランドなど、デバイスに関する情報を収集する場合があります。モデル、使用するキャリア、ネットワークタイプ（Wifi /モバイルデータ）、データ送信の量とステータス、クリックスルー統計、インターネットにアクセスするドメインとホストの名前、アクセスする日時に関する情報eRepublikサービスの一部。</p>
            <p>11.3追跡技術は私たちを可能にします</p>
            <p>11.3.1オーディエンスのサイズと使用パターンを推定する。</p>
            <p>11.3.2お客様の好みに関する情報を保存し、お客様の個人的な興味に応じてeRepublikサービスをカスタマイズできるようにします。そして</p>
            <p>11.3.3eRepublikサービスと検索の使用をスピードアップします。</p>
            <p><strong>12.情報へのアクセス、および個人データの更新、検証、削除</strong></p>
            <p>12.1あなたはいつでも、私たちがあなたについて保持しているすべての個人情報にアクセスすることを書面で要求することができます。この情報を提供し、少額の管理費を請求する場合があります。</p>
            <p>12.2 eRepublikサービスの登録ユーザーとして、ゲーム内またはprivacy@erepubliklabs.comで利用可能なサポートセンターを介して当社に連絡することにより、個人情報を変更および更新することができます。アカウントを削除する場合は、privacy @ erepubliklabs.comまでご連絡いただき、アカウントの削除をリクエストしてください。ただし、ユーザー名、アバター、ベース、その他のゲーム内アクション、要素、またはゲーム内チャットへの投稿はゲーム履歴に記録されたままになります。このような投稿に付随する情報は、ユーザー名とアバターのみです。</p>
            <p><strong>13.プライバシーポリシーの変更</strong></p>
            <p>13.1今後プライバシーポリシーに変更を加える可能性がある場合は、このページに掲載され、必要に応じて電子メールで通知されます。プライバシーポリシーの変更を確認するには、このページに定期的にアクセスする必要があります。</p>
            <p><strong>14.ユーザーのフィードバックと苦情、外部データ保護責任者</strong></p>
            <p>14.1このプライバシーポリシーに関する質問、コメント、および要求は歓迎されており、privacy @ erepubliklabs.comに送信するか、ゲーム内で利用可能なヘルプとサポートメニューを介して送信する必要があります。</p>
            <p>14.2当社のデータ保護責任者はdpo@stillfront.comに連絡することができます。</p>
            <p><br /><br /></p>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        lang:String
    }
}
</script>
<style lang="less">
    //Styling for korean and japanese
    .pp-text{
        font-family: "Open Sans",sans-serif;
        color: #fff;
        padding-top: 20px;
        p{
            font-family: "Open Sans",sans-serif;
            line-height: 1.5;
            color: #fff;
        }
        li{
            font-family: "Open Sans",sans-serif;
            line-height: 1.5;
            color: #fff;
        }
        ol{
            color: #fff;
            margin: 20px 0px;
            font-weight: 700;
            li{
                strong{
                    color: #fff;
                    font-size: 20px;
                }
            }
        }
        h1{
            font-family: "Open Sans",sans-serif;
            font-weight: 700;
            color: #fff;
            font-size: 20px;
        }

    }
    // Generated stuff idk
    .lst-kix_list_4-1>li {
        counter-increment: lst-ctn-kix_list_4-1
    }

    ol.lst-kix_list_7-0 {
        list-style-type: none
    }

    .lst-kix_list_2-1>li {
        counter-increment: lst-ctn-kix_list_2-1
    }

    ul.lst-kix_list_1-0 {
        list-style-type: none
    }

    .lst-kix_list_6-1>li {
        counter-increment: lst-ctn-kix_list_6-1
    }

    .lst-kix_list_8-1>li {
        counter-increment: lst-ctn-kix_list_8-1
    }

    ol.lst-kix_list_8-2.start {
        counter-reset: lst-ctn-kix_list_8-2 0
    }

    ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0
    }

    ol.lst-kix_list_6-6.start {
        counter-reset: lst-ctn-kix_list_6-6 0
    }

    ol.lst-kix_list_7-4.start {
        counter-reset: lst-ctn-kix_list_7-4 0
    }

    .lst-kix_list_5-0>li {
        counter-increment: lst-ctn-kix_list_5-0
    }

    .lst-kix_list_7-0>li {
        counter-increment: lst-ctn-kix_list_7-0
    }

    ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0
    }

    ol.lst-kix_list_7-5 {
        list-style-type: none
    }

    ul.lst-kix_list_1-3 {
        list-style-type: none
    }

    ol.lst-kix_list_7-6 {
        list-style-type: none
    }

    ul.lst-kix_list_1-4 {
        list-style-type: none
    }

    ol.lst-kix_list_7-7 {
        list-style-type: none
    }

    ul.lst-kix_list_1-1 {
        list-style-type: none
    }

    ol.lst-kix_list_7-8 {
        list-style-type: none
    }

    ul.lst-kix_list_1-2 {
        list-style-type: none
    }

    ol.lst-kix_list_7-1 {
        list-style-type: none
    }

    ul.lst-kix_list_1-7 {
        list-style-type: none
    }

    ol.lst-kix_list_7-2 {
        list-style-type: none
    }

    ul.lst-kix_list_1-8 {
        list-style-type: none
    }

    ol.lst-kix_list_7-3 {
        list-style-type: none
    }

    ul.lst-kix_list_1-5 {
        list-style-type: none
    }

    ol.lst-kix_list_7-4 {
        list-style-type: none
    }

    ul.lst-kix_list_1-6 {
        list-style-type: none
    }

    ol.lst-kix_list_5-3.start {
        counter-reset: lst-ctn-kix_list_5-3 0
    }

    .lst-kix_list_2-3>li {
        counter-increment: lst-ctn-kix_list_2-3
    }

    .lst-kix_list_4-3>li {
        counter-increment: lst-ctn-kix_list_4-3
    }

    .lst-kix_z70hs8pl26oz-7>li:before {
        content: "-  "
    }

    ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
    }

    .lst-kix_z70hs8pl26oz-8>li:before {
        content: "-  "
    }

    .lst-kix_z70hs8pl26oz-5>li:before {
        content: "-  "
    }

    ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0
    }

    .lst-kix_z70hs8pl26oz-2>li:before {
        content: "-  "
    }

    .lst-kix_z70hs8pl26oz-6>li:before {
        content: "-  "
    }

    .lst-kix_list_5-2>li {
        counter-increment: lst-ctn-kix_list_5-2
    }

    ol.lst-kix_list_8-8.start {
        counter-reset: lst-ctn-kix_list_8-8 0
    }

    .lst-kix_z70hs8pl26oz-3>li:before {
        content: "-  "
    }

    .lst-kix_list_3-2>li {
        counter-increment: lst-ctn-kix_list_3-2
    }

    .lst-kix_z70hs8pl26oz-4>li:before {
        content: "-  "
    }

    .lst-kix_list_7-2>li {
        counter-increment: lst-ctn-kix_list_7-2
    }

    ol.lst-kix_list_8-7.start {
        counter-reset: lst-ctn-kix_list_8-7 0
    }

    .lst-kix_list_5-0>li:before {
        content: ""counter(lst-ctn-kix_list_5-0, decimal) ". "
    }

    ol.lst-kix_list_6-0 {
        list-style-type: none
    }

    ol.lst-kix_list_6-1 {
        list-style-type: none
    }

    .lst-kix_list_5-4>li {
        counter-increment: lst-ctn-kix_list_5-4
    }

    .lst-kix_list_5-3>li:before {
        content: ""counter(lst-ctn-kix_list_5-3, decimal) ". "
    }

    .lst-kix_list_5-2>li:before {
        content: ""counter(lst-ctn-kix_list_5-2, decimal) ". "
    }

    .lst-kix_list_8-3>li {
        counter-increment: lst-ctn-kix_list_8-3
    }

    .lst-kix_list_5-1>li:before {
        content: "("counter(lst-ctn-kix_list_5-1, decimal) ") "
    }

    .lst-kix_list_5-7>li:before {
        content: ""counter(lst-ctn-kix_list_5-7, decimal) ". "
    }

    .lst-kix_list_5-6>li:before {
        content: ""counter(lst-ctn-kix_list_5-6, decimal) ". "
    }

    .lst-kix_list_5-8>li:before {
        content: ""counter(lst-ctn-kix_list_5-8, decimal) ". "
    }

    ol.lst-kix_list_6-6 {
        list-style-type: none
    }

    ol.lst-kix_list_6-7 {
        list-style-type: none
    }

    .lst-kix_list_5-4>li:before {
        content: ""counter(lst-ctn-kix_list_5-4, decimal) ". "
    }

    ol.lst-kix_list_6-8 {
        list-style-type: none
    }

    .lst-kix_list_5-5>li:before {
        content: ""counter(lst-ctn-kix_list_5-5, decimal) ". "
    }

    ol.lst-kix_list_6-2 {
        list-style-type: none
    }

    ol.lst-kix_list_6-3 {
        list-style-type: none
    }

    ol.lst-kix_list_6-4 {
        list-style-type: none
    }

    ol.lst-kix_list_6-5 {
        list-style-type: none
    }

    .lst-kix_list_6-1>li:before {
        content: ""counter(lst-ctn-kix_list_6-1, lower-latin) ". "
    }

    .lst-kix_list_6-3>li:before {
        content: ""counter(lst-ctn-kix_list_6-3, decimal) ". "
    }

    .lst-kix_list_6-5>li {
        counter-increment: lst-ctn-kix_list_6-5
    }

    .lst-kix_list_6-8>li {
        counter-increment: lst-ctn-kix_list_6-8
    }

    .lst-kix_list_6-0>li:before {
        content: "("counter(lst-ctn-kix_list_6-0, decimal) ") "
    }

    .lst-kix_list_6-4>li:before {
        content: ""counter(lst-ctn-kix_list_6-4, lower-latin) ". "
    }

    .lst-kix_list_3-0>li {
        counter-increment: lst-ctn-kix_list_3-0
    }

    ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0
    }

    .lst-kix_list_3-6>li {
        counter-increment: lst-ctn-kix_list_3-6
    }

    .lst-kix_list_6-2>li:before {
        content: ""counter(lst-ctn-kix_list_6-2, lower-roman) ". "
    }

    .lst-kix_list_2-5>li {
        counter-increment: lst-ctn-kix_list_2-5
    }

    .lst-kix_list_2-8>li {
        counter-increment: lst-ctn-kix_list_2-8
    }

    ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0
    }

    .lst-kix_list_6-8>li:before {
        content: ""counter(lst-ctn-kix_list_6-8, lower-roman) ". "
    }

    .lst-kix_list_6-5>li:before {
        content: ""counter(lst-ctn-kix_list_6-5, lower-roman) ". "
    }

    .lst-kix_list_6-7>li:before {
        content: ""counter(lst-ctn-kix_list_6-7, lower-latin) ". "
    }

    ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0
    }

    .lst-kix_list_6-6>li:before {
        content: ""counter(lst-ctn-kix_list_6-6, decimal) ". "
    }

    ul.lst-kix_z70hs8pl26oz-6 {
        list-style-type: none
    }

    ul.lst-kix_z70hs8pl26oz-7 {
        list-style-type: none
    }

    .lst-kix_list_2-7>li:before {
        content: ""counter(lst-ctn-kix_list_2-7, decimal) ". "
    }

    .lst-kix_list_2-7>li {
        counter-increment: lst-ctn-kix_list_2-7
    }

    ul.lst-kix_z70hs8pl26oz-4 {
        list-style-type: none
    }

    .lst-kix_list_7-4>li:before {
        content: ""counter(lst-ctn-kix_list_7-4, decimal) ". "
    }

    .lst-kix_list_7-6>li:before {
        content: ""counter(lst-ctn-kix_list_7-6, decimal) ". "
    }

    ul.lst-kix_z70hs8pl26oz-5 {
        list-style-type: none
    }

    .lst-kix_list_2-5>li:before {
        content: ""counter(lst-ctn-kix_list_2-5, decimal) ". "
    }

    ol.lst-kix_list_6-2.start {
        counter-reset: lst-ctn-kix_list_6-2 0
    }

    ul.lst-kix_z70hs8pl26oz-8 {
        list-style-type: none
    }

    .lst-kix_list_7-2>li:before {
        content: ""counter(lst-ctn-kix_list_7-2, decimal) ". "
    }

    .lst-kix_list_7-6>li {
        counter-increment: lst-ctn-kix_list_7-6
    }

    ul.lst-kix_z70hs8pl26oz-2 {
        list-style-type: none
    }

    ul.lst-kix_z70hs8pl26oz-3 {
        list-style-type: none
    }

    .lst-kix_list_8-6>li {
        counter-increment: lst-ctn-kix_list_8-6
    }

    ul.lst-kix_z70hs8pl26oz-0 {
        list-style-type: none
    }

    ul.lst-kix_z70hs8pl26oz-1 {
        list-style-type: none
    }

    ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
    }

    ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0
    }

    .lst-kix_list_5-7>li {
        counter-increment: lst-ctn-kix_list_5-7
    }

    .lst-kix_list_7-7>li {
        counter-increment: lst-ctn-kix_list_7-7
    }

    .lst-kix_list_7-8>li:before {
        content: ""counter(lst-ctn-kix_list_7-8, decimal) ". "
    }

    ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
    }

    .lst-kix_list_4-7>li {
        counter-increment: lst-ctn-kix_list_4-7
    }

    .lst-kix_list_7-8>li {
        counter-increment: lst-ctn-kix_list_7-8
    }

    ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0
    }

    .lst-kix_list_2-6>li {
        counter-increment: lst-ctn-kix_list_2-6
    }

    .lst-kix_list_4-1>li:before {
        content: "("counter(lst-ctn-kix_list_4-1, decimal) ") "
    }

    ol.lst-kix_list_7-3.start {
        counter-reset: lst-ctn-kix_list_7-3 0
    }

    .lst-kix_list_4-3>li:before {
        content: ""counter(lst-ctn-kix_list_4-3, decimal) ". "
    }

    .lst-kix_list_4-5>li:before {
        content: ""counter(lst-ctn-kix_list_4-5, decimal) ". "
    }

    ol.lst-kix_list_5-7.start {
        counter-reset: lst-ctn-kix_list_5-7 0
    }

    .lst-kix_list_5-5>li {
        counter-increment: lst-ctn-kix_list_5-5
    }

    .lst-kix_list_3-5>li {
        counter-increment: lst-ctn-kix_list_3-5
    }

    .lst-kix_list_3-4>li {
        counter-increment: lst-ctn-kix_list_3-4
    }

    ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
    }

    .lst-kix_list_6-4>li {
        counter-increment: lst-ctn-kix_list_6-4
    }

    .lst-kix_z70hs8pl26oz-0>li:before {
        content: "-  "
    }

    .lst-kix_list_6-3>li {
        counter-increment: lst-ctn-kix_list_6-3
    }

    ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0
    }

    ol.lst-kix_list_8-8 {
        list-style-type: none
    }

    ol.lst-kix_list_7-6.start {
        counter-reset: lst-ctn-kix_list_7-6 0
    }

    ol.lst-kix_list_6-1.start {
        counter-reset: lst-ctn-kix_list_6-1 0
    }

    ol.lst-kix_list_8-4 {
        list-style-type: none
    }

    ol.lst-kix_list_8-5 {
        list-style-type: none
    }

    ol.lst-kix_list_8-6 {
        list-style-type: none
    }

    ol.lst-kix_list_8-7 {
        list-style-type: none
    }

    ol.lst-kix_list_8-0 {
        list-style-type: none
    }

    ol.lst-kix_list_8-1 {
        list-style-type: none
    }

    .lst-kix_list_1-1>li:before {
        content: "o  "
    }

    ol.lst-kix_list_8-2 {
        list-style-type: none
    }

    ol.lst-kix_list_8-3 {
        list-style-type: none
    }

    .lst-kix_list_8-5>li {
        counter-increment: lst-ctn-kix_list_8-5
    }

    .lst-kix_list_1-3>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_4-8>li {
        counter-increment: lst-ctn-kix_list_4-8
    }

    .lst-kix_list_1-7>li:before {
        content: "\0025aa  "
    }

    ol.lst-kix_list_5-8.start {
        counter-reset: lst-ctn-kix_list_5-8 0
    }

    ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0
    }

    .lst-kix_list_1-5>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_5-6>li {
        counter-increment: lst-ctn-kix_list_5-6
    }

    ol.lst-kix_list_7-5.start {
        counter-reset: lst-ctn-kix_list_7-5 0
    }

    .lst-kix_list_2-1>li:before {
        content: "("counter(lst-ctn-kix_list_2-1, decimal) ") "
    }

    ol.lst-kix_list_6-0.start {
        counter-reset: lst-ctn-kix_list_6-0 0
    }

    .lst-kix_list_2-3>li:before {
        content: ""counter(lst-ctn-kix_list_2-3, decimal) ". "
    }

    .lst-kix_list_4-2>li {
        counter-increment: lst-ctn-kix_list_4-2
    }

    ol.lst-kix_list_3-1 {
        list-style-type: none
    }

    ol.lst-kix_list_3-2 {
        list-style-type: none
    }

    .lst-kix_list_3-1>li {
        counter-increment: lst-ctn-kix_list_3-1
    }

    ol.lst-kix_list_3-3 {
        list-style-type: none
    }

    ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0
    }

    .lst-kix_list_5-1>li {
        counter-increment: lst-ctn-kix_list_5-1
    }

    ol.lst-kix_list_3-4 {
        list-style-type: none
    }

    ol.lst-kix_list_3-0 {
        list-style-type: none
    }

    .lst-kix_list_7-1>li {
        counter-increment: lst-ctn-kix_list_7-1
    }

    ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0
    }

    .lst-kix_list_3-0>li:before {
        content: ""counter(lst-ctn-kix_list_3-0, lower-latin) ") "
    }

    ol.lst-kix_list_7-7.start {
        counter-reset: lst-ctn-kix_list_7-7 0
    }

    .lst-kix_list_3-1>li:before {
        content: ""counter(lst-ctn-kix_list_3-1, lower-latin) ". "
    }

    .lst-kix_list_3-2>li:before {
        content: ""counter(lst-ctn-kix_list_3-2, lower-roman) ". "
    }

    .lst-kix_list_8-1>li:before {
        content: "("counter(lst-ctn-kix_list_8-1, decimal) ") "
    }

    .lst-kix_list_4-0>li {
        counter-increment: lst-ctn-kix_list_4-0
    }

    .lst-kix_list_8-2>li:before {
        content: ""counter(lst-ctn-kix_list_8-2, decimal) ". "
    }

    .lst-kix_list_6-0>li {
        counter-increment: lst-ctn-kix_list_6-0
    }

    .lst-kix_list_8-0>li {
        counter-increment: lst-ctn-kix_list_8-0
    }

    .lst-kix_list_3-5>li:before {
        content: ""counter(lst-ctn-kix_list_3-5, lower-roman) ". "
    }

    .lst-kix_list_3-4>li:before {
        content: ""counter(lst-ctn-kix_list_3-4, lower-latin) ". "
    }

    .lst-kix_list_3-3>li:before {
        content: ""counter(lst-ctn-kix_list_3-3, decimal) ". "
    }

    ol.lst-kix_list_3-5 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6 {
        list-style-type: none
    }

    .lst-kix_list_8-0>li:before {
        content: ""counter(lst-ctn-kix_list_8-0, decimal) ". "
    }

    ol.lst-kix_list_3-7 {
        list-style-type: none
    }

    ol.lst-kix_list_3-8 {
        list-style-type: none
    }

    .lst-kix_list_8-7>li:before {
        content: ""counter(lst-ctn-kix_list_8-7, decimal) ". "
    }

    .lst-kix_list_3-8>li:before {
        content: ""counter(lst-ctn-kix_list_3-8, lower-roman) ". "
    }

    .lst-kix_list_8-5>li:before {
        content: ""counter(lst-ctn-kix_list_8-5, decimal) ". "
    }

    .lst-kix_list_8-6>li:before {
        content: ""counter(lst-ctn-kix_list_8-6, decimal) ". "
    }

    .lst-kix_list_2-0>li {
        counter-increment: lst-ctn-kix_list_2-0
    }

    .lst-kix_list_8-3>li:before {
        content: ""counter(lst-ctn-kix_list_8-3, decimal) ". "
    }

    .lst-kix_list_3-6>li:before {
        content: ""counter(lst-ctn-kix_list_3-6, decimal) ". "
    }

    .lst-kix_list_3-7>li:before {
        content: ""counter(lst-ctn-kix_list_3-7, lower-latin) ". "
    }

    .lst-kix_list_8-4>li:before {
        content: ""counter(lst-ctn-kix_list_8-4, decimal) ". "
    }

    ol.lst-kix_list_5-0.start {
        counter-reset: lst-ctn-kix_list_5-0 0
    }

    ol.lst-kix_list_8-5.start {
        counter-reset: lst-ctn-kix_list_8-5 0
    }

    ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
    }

    .lst-kix_list_8-8>li:before {
        content: ""counter(lst-ctn-kix_list_8-8, decimal) ". "
    }

    ol.lst-kix_list_2-2 {
        list-style-type: none
    }

    ol.lst-kix_list_2-3 {
        list-style-type: none
    }

    ol.lst-kix_list_2-4 {
        list-style-type: none
    }

    ol.lst-kix_list_7-2.start {
        counter-reset: lst-ctn-kix_list_7-2 0
    }

    ol.lst-kix_list_2-5 {
        list-style-type: none
    }

    .lst-kix_list_4-4>li {
        counter-increment: lst-ctn-kix_list_4-4
    }

    ol.lst-kix_list_2-0 {
        list-style-type: none
    }

    ol.lst-kix_list_2-1 {
        list-style-type: none
    }

    .lst-kix_list_4-8>li:before {
        content: ""counter(lst-ctn-kix_list_4-8, decimal) ". "
    }

    ol.lst-kix_list_6-4.start {
        counter-reset: lst-ctn-kix_list_6-4 0
    }

    .lst-kix_list_4-7>li:before {
        content: ""counter(lst-ctn-kix_list_4-7, decimal) ". "
    }

    ol.lst-kix_list_5-6.start {
        counter-reset: lst-ctn-kix_list_5-6 0
    }

    ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
    }

    .lst-kix_list_7-3>li {
        counter-increment: lst-ctn-kix_list_7-3
    }

    ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
    }

    .lst-kix_list_8-4>li {
        counter-increment: lst-ctn-kix_list_8-4
    }

    ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0
    }

    ol.lst-kix_list_2-6 {
        list-style-type: none
    }

    ol.lst-kix_list_2-7 {
        list-style-type: none
    }

    ol.lst-kix_list_2-8 {
        list-style-type: none
    }

    ol.lst-kix_list_7-8.start {
        counter-reset: lst-ctn-kix_list_7-8 0
    }

    ol.lst-kix_list_7-1.start {
        counter-reset: lst-ctn-kix_list_7-1 0
    }

    ol.lst-kix_list_8-6.start {
        counter-reset: lst-ctn-kix_list_8-6 0
    }

    .lst-kix_list_3-3>li {
        counter-increment: lst-ctn-kix_list_3-3
    }

    ol.lst-kix_list_6-3.start {
        counter-reset: lst-ctn-kix_list_6-3 0
    }

    ol.lst-kix_list_5-5.start {
        counter-reset: lst-ctn-kix_list_5-5 0
    }

    ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0
    }

    .lst-kix_list_7-0>li:before {
        content: ""counter(lst-ctn-kix_list_7-0, decimal) ". "
    }

    .lst-kix_list_2-2>li {
        counter-increment: lst-ctn-kix_list_2-2
    }

    ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
    }

    .lst-kix_list_6-2>li {
        counter-increment: lst-ctn-kix_list_6-2
    }

    ol.lst-kix_list_5-0 {
        list-style-type: none
    }

    .lst-kix_list_2-6>li:before {
        content: ""counter(lst-ctn-kix_list_2-6, decimal) ". "
    }

    .lst-kix_list_3-7>li {
        counter-increment: lst-ctn-kix_list_3-7
    }

    ol.lst-kix_list_5-1 {
        list-style-type: none
    }

    ol.lst-kix_list_5-2 {
        list-style-type: none
    }

    .lst-kix_list_2-4>li:before {
        content: ""counter(lst-ctn-kix_list_2-4, decimal) ". "
    }

    .lst-kix_list_2-8>li:before {
        content: ""counter(lst-ctn-kix_list_2-8, decimal) ". "
    }

    .lst-kix_list_7-1>li:before {
        content: "("counter(lst-ctn-kix_list_7-1, decimal) ") "
    }

    .lst-kix_list_7-5>li:before {
        content: ""counter(lst-ctn-kix_list_7-5, decimal) ". "
    }

    .lst-kix_list_6-6>li {
        counter-increment: lst-ctn-kix_list_6-6
    }

    ol.lst-kix_list_5-4.start {
        counter-reset: lst-ctn-kix_list_5-4 0
    }

    .lst-kix_list_7-3>li:before {
        content: ""counter(lst-ctn-kix_list_7-3, decimal) ". "
    }

    ol.lst-kix_list_5-1.start {
        counter-reset: lst-ctn-kix_list_5-1 0
    }

    ol.lst-kix_list_5-7 {
        list-style-type: none
    }

    .lst-kix_list_6-7>li {
        counter-increment: lst-ctn-kix_list_6-7
    }

    ol.lst-kix_list_5-8 {
        list-style-type: none
    }

    ol.lst-kix_list_5-3 {
        list-style-type: none
    }

    .lst-kix_list_8-7>li {
        counter-increment: lst-ctn-kix_list_8-7
    }

    ol.lst-kix_list_5-4 {
        list-style-type: none
    }

    ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0
    }

    ol.lst-kix_list_5-5 {
        list-style-type: none
    }

    ol.lst-kix_list_5-6 {
        list-style-type: none
    }

    .lst-kix_list_7-7>li:before {
        content: ""counter(lst-ctn-kix_list_7-7, decimal) ". "
    }

    ol.lst-kix_list_8-1.start {
        counter-reset: lst-ctn-kix_list_8-1 0
    }

    .lst-kix_list_7-5>li {
        counter-increment: lst-ctn-kix_list_7-5
    }

    .lst-kix_list_5-8>li {
        counter-increment: lst-ctn-kix_list_5-8
    }

    .lst-kix_list_4-0>li:before {
        content: ""counter(lst-ctn-kix_list_4-0, decimal) ". "
    }

    .lst-kix_list_3-8>li {
        counter-increment: lst-ctn-kix_list_3-8
    }

    ol.lst-kix_list_6-8.start {
        counter-reset: lst-ctn-kix_list_6-8 0
    }

    .lst-kix_list_4-6>li {
        counter-increment: lst-ctn-kix_list_4-6
    }

    .lst-kix_list_4-4>li:before {
        content: ""counter(lst-ctn-kix_list_4-4, decimal) ". "
    }

    ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0
    }

    ol.lst-kix_list_6-5.start {
        counter-reset: lst-ctn-kix_list_6-5 0
    }

    .lst-kix_list_4-2>li:before {
        content: ""counter(lst-ctn-kix_list_4-2, decimal) ". "
    }

    .lst-kix_list_4-6>li:before {
        content: ""counter(lst-ctn-kix_list_4-6, decimal) ". "
    }

    ol.lst-kix_list_7-0.start {
        counter-reset: lst-ctn-kix_list_7-0 0
    }

    ol.lst-kix_list_4-0 {
        list-style-type: none
    }

    ol.lst-kix_list_4-1 {
        list-style-type: none
    }

    ol.lst-kix_list_4-2 {
        list-style-type: none
    }

    ol.lst-kix_list_4-3 {
        list-style-type: none
    }

    .lst-kix_list_2-4>li {
        counter-increment: lst-ctn-kix_list_2-4
    }

    ol.lst-kix_list_6-7.start {
        counter-reset: lst-ctn-kix_list_6-7 0
    }

    .lst-kix_z70hs8pl26oz-1>li:before {
        content: "-  "
    }

    ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0
    }

    .lst-kix_list_5-3>li {
        counter-increment: lst-ctn-kix_list_5-3
    }

    ol.lst-kix_list_4-8 {
        list-style-type: none
    }

    .lst-kix_list_7-4>li {
        counter-increment: lst-ctn-kix_list_7-4
    }

    .lst-kix_list_1-0>li:before {
        content: "\0025cf  "
    }

    ol.lst-kix_list_4-4 {
        list-style-type: none
    }

    ol.lst-kix_list_4-5 {
        list-style-type: none
    }

    .lst-kix_list_1-2>li:before {
        content: "\0025aa  "
    }

    ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0
    }

    ol.lst-kix_list_4-6 {
        list-style-type: none
    }

    ol.lst-kix_list_4-7 {
        list-style-type: none
    }

    ol.lst-kix_list_8-4.start {
        counter-reset: lst-ctn-kix_list_8-4 0
    }

    .lst-kix_list_1-4>li:before {
        content: "\0025aa  "
    }

    ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0
    }

    .lst-kix_list_8-8>li {
        counter-increment: lst-ctn-kix_list_8-8
    }

    .lst-kix_list_1-6>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_2-0>li:before {
        content: ""counter(lst-ctn-kix_list_2-0, decimal) ". "
    }

    ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0
    }

    ol.lst-kix_list_8-3.start {
        counter-reset: lst-ctn-kix_list_8-3 0
    }

    .lst-kix_list_4-5>li {
        counter-increment: lst-ctn-kix_list_4-5
    }

    .lst-kix_list_1-8>li:before {
        content: "\0025aa  "
    }

    .lst-kix_list_2-2>li:before {
        content: ""counter(lst-ctn-kix_list_2-2, decimal) ". "
    }

    ol.lst-kix_list_5-2.start {
        counter-reset: lst-ctn-kix_list_5-2 0
    }

    .lst-kix_list_8-2>li {
        counter-increment: lst-ctn-kix_list_8-2
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td,
    table th {
        padding: 0
    }

    .c22 {
        margin-left: 46.3pt;
        padding-top: 12pt;
        padding-left: 3.3pt;
        padding-bottom: 12pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c5 {
        margin-left: 48pt;
        padding-top: 12pt;
        padding-left: 0pt;
        padding-bottom: 12pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c15 {
        margin-left: 49.6pt;
        padding-top: 12pt;
        padding-left: 0pt;
        padding-bottom: 12pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c27 {
        margin-left: 72pt;
        padding-top: 12pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c33 {
        margin-left: 45.2pt;
        padding-top: 12pt;
        padding-left: 18.5pt;
        padding-bottom: 12pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c17 {
        padding-top: 14pt;
        padding-bottom: 14pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }

    .c3 {
        padding-top: 12pt;
        padding-bottom: 11.1pt;
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c2 {
        color: #fff;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Arial";
        font-style: normal
    }

    .c10 {
        padding-top: 5pt;
        padding-bottom: 14pt;
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c8 {
        padding-top: 0pt;
        padding-bottom: 8pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt
    }

    .c13 {
        padding-top: 5pt;
        padding-bottom: 14pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt
    }

    .c21 {
        color: #fff;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 18pt;
        font-family: "Arial";
        font-style: normal
    }

    .c4 {
        color: #fff;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Arial";
        font-style: normal
    }

    .c12 {
        padding-top: 12pt;
        padding-bottom: 12pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c29 {
        padding-top: 0pt;
        padding-bottom: 2pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c35 {
        padding-top: 5pt;
        padding-bottom: 14pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c9 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c25 {
        padding-top: 2pt;
        padding-bottom: 14pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c28 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c0 {
        padding-top: 0pt;
        padding-bottom: 14pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c1 {
        padding-top: 2pt;
        padding-bottom: 14pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c37 {
        padding-top: 0pt;
        padding-bottom: 12pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c16 {
        padding-top: 0pt;
        padding-bottom: 8pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c20 {
        padding-top: 0pt;
        padding-bottom: 14pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c11 {
        padding-top: 14pt;
        padding-bottom: 14pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c18 {
        padding-top: 5pt;
        padding-bottom: 5pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c6 {
        padding-top: 0pt;
        padding-bottom: 2pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c32 {
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        text-decoration: underline;
        text-decoration-skip-ink: none
    }

    .c23 {
        font-size: 9pt;
        font-family: "Arial";
        font-weight: 700
    }

    .c36 {
        background-color: #ffffff;
        max-width: 453.6pt;
        padding: 70.8pt 70.8pt 56.7pt 70.8pt
    }

    .c7 {
        font-size: 9pt;
        font-family: "Arial";
        font-weight: 400
    }

    .c30 {
        margin-left: 72pt;
        padding-left: 0pt
    }

    .c24 {
        color: inherit;
        text-decoration: inherit
    }

    .c19 {
        padding: 0;
        margin: 0
    }

    .c14 {
        margin-left: 36pt;
        padding-left: 0pt
    }

    .c34 {
        height: 11pt
    }

    .c26 {
        vertical-align: super
    }

    .c31 {
        font-style: italic
    }

    .title {
        padding-top: 24pt;
        color: #fff;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #fff;
        font-size: 11pt;
        font-family: "Calibri"
    }

    p {
        margin: 0;
        color: #fff;
        font-size: 11pt;
        font-family: "Calibri"
    }

    h1 {
        padding-top: 12pt;
        color: #2f5496;
        font-size: 16pt;
        padding-bottom: 0pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #fff;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 5pt;
        color: #fff;
        font-weight: 700;
        font-size: 13.5pt;
        padding-bottom: 5pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 12pt;
        color: #fff;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 11pt;
        color: #fff;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 10pt;
        color: #fff;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }
</style>