<template>
	<section class="image-carousel">
        <vueper-slides class="no-shadow slider" :slide-ratio="1 / 2" fixed-height="500px" :arrowsOutside=false >
            <vueper-slide  v-for="(slide, i) in slides" :key="i" :image="slide.image" :video="slide.video"/>
        </vueper-slides>
	</section>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { isMobile, isAndroid } from 'mobile-device-detect';

export default{
    components: { VueperSlides, VueperSlide },

    data: () => ({
    slides: [
        {
            video: {
            url: 'https://www.youtube.com/embed/9R8IuCRXqrE?rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1&mute=1',
            props: {
                allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture'
            }
            }
        },
        {
            image: require("../assets/carousel-images/1.png")
        },
        {
            image: require("../assets/carousel-images/2.png")
        },
    ],
    video: {
      url: 'https://www.youtube.com/embed/9R8IuCRXqrE?rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1',
      props: {
        allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      }
    }
    }
)
}
</script>

<style lang="less" scoped>
.image-carousel{
    height: 430px;
    // width: 100%;
    margin: 100px auto;
    border: 0px;
    border-radius: 100px;
    .vueperslide{
        border-radius: 30px;
        box-shadow: none;
    }
    .slider{
        max-width:1090px;
        border-radius: 30px;
        animation: ease-in;
        animation-duration: 0.2s;
        margin:0px auto;
        // height: 300px !important;
    }
}
@media(max-width:481px) {
    .image-carousel{
        width: 100%;
        height: 250px;
        .vueperslide{
            z-index: 2;
            // height: 600px;
            border-radius: 0px;
        }
        .slider{
            height: 210px !important;
        }
    }
}
</style>