<template>
  <footer class="footer">
    <div class="links">
        <router-link to="privacy-policy" class="link">{{getLocalText(locales.privacyPolicy)}}</router-link> | <router-link to="/tos" class="link">{{getLocalText(locales.tos)}}</router-link>
    </div>
    <img class="pc-logo" src="../assets/Powerchallenge.png" alt="" srcset="">
    <img class="erep-logo" src="../assets/eRepublik.png" alt="" srcset="">
    <img class="alset-logo" src="../assets/Alset.png" alt="" srcset="">
    <img class="stillfront-logo" src="../assets/Stillfront.png" alt="" srcset="">

  </footer>
</template>

<script>
export default {
  data: ()=>({
    locales:{
      privacyPolicy:[
        "Privacy Policy",
        "개인정보 보호정책"
      ],
      tos:[
        "Terms of Service",
        "서비스 약관"
      ],
    }
  }),
  methods:{
    getLocalText(localizationVar){
      return localizationVar[0]
			return localizationVar[this.$language.id]
		}
  },
}
</script>

<style lang="less" scoped>
  .footer{
    height: 200px;
    width: 100%;
    box-sizing: content-box;
    padding: 20px 0px;
    text-align: center;
    color: #fff;
    position: relative;
    .logos{
      max-width: 500px;
      margin: 0px auto;
      margin-top: 30px;
    }
    .pc-logo{
      left: 0px;
      right: 0px;
      margin: 0px auto;
      position: absolute;
      top:65px;
      max-width: 180px;
    }
    .alset-logo{
      left: 0px;
      right: 0px;
      margin: 0px auto;
      position: absolute;
      top: 65px;
      max-width: 150px;
      padding-left: 350px;
    }
    .erep-logo{
      left: 0px;
      right: 0px;
      margin: 0px auto;
      position: absolute;
      top:70px;
      max-width: 150px;
      padding-right: 350px;
    }
    .stillfront-logo{
      left: 0px;
      right: 0px;
      margin: 0px auto;
      position: absolute;
      top:120px;
      max-width: 150px;
    }
    .links{
      color: #5674c5;

      .link{
        text-decoration: none;
        color: #acbadf;
      }
    }
  }
  @media(max-width:516px) {
    .footer .alset-logo,.footer .erep-logo, .footer .pc-logo, .footer .stillfront-logo{
      position: relative;
      padding: 0;
      display: block;
      top: 0px;
      margin-top: 18px;
    }
    .footer .alset-logo{
      margin-bottom: 10px;
    }
    .footer .erep-logo{
      margin-top: 8px;
    }
  }
</style>