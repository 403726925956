<template>
  <section v-if="languageSelected==true">
    <router-view>
    </router-view>
    <Footer></Footer>
  </section>
  <div v-else>
    <Home></Home>
  </div>
</template>

<script>
import Vue from "vue";
import Footer from "./components/Footer.vue"
import LanguageSelect from "./components/LanguageSelect.vue";
import Home from "./pages/Home.vue";


export default {
  components:{
    Footer,
    LanguageSelect,
    Home
  },
  data: ()=>({
    languageSelected: true,
  }),
  methods:{
      onLanguageSelect(payload){
          this.languageSelected = true;
          this.language = payload.name;
          Vue.prototype.$language = payload;
          console.log(this.$language.name)

      }
  },
  created(){
    
    console.log(this.$language)
  }
}
</script>

<style lang="less">
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('OpenSans-Regular'), url(~/assets/fonts/OpenSans-Regular.ttf) format('ttf');
  }

  html, body {
    font-family: 'Open Sans', sans-serif;
  }

  #app {
    font-family: 'Open Sans', sans-serif;
  }
</style>