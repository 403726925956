
<template>
	<div class="title-container">
		<div class="title-image">
			<a class="button-to-register" href="#register">Find out when we launch!</a>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.title-container{
	width: 100%;
	height: 620px;
	background-image: url("../assets/background.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	.title-image{
		background-image: url("../assets/footbrawl.png");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		display: block;
		max-width: 1200px;
		margin: 0px auto;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		.button-to-register{
			display: block;
			text-align: center;
			margin: 0px auto;
			font-size: 20px;
			text-decoration: none;
			color: #ffffff;
			text-align: center;
            margin: 10px auto;
            background-color: #ff6600;
            width: 250px;
            border: none;
            border-radius: 10px;
            cursor: pointer;
			padding: 10px 0px;
			margin-bottom: 120px;
		}
	}
}
@media(max-width:790) {
}
@media(max-width:481px) {
	.title-container{
		height: 500px;
	}
}
</style>