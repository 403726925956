<template>
    <section class="flag-select">
        <img src="../assets/logo.png"/>
        <h1>Please select your language</h1>
        <div class="flag-container">
            <img class="flag" v-for="item in languages" :key="item.name" v-on:click="onLanguageSelect(item)" :src="item.image">
        </div>
    </section>
</template>

<style lang="less" scoped>
    .flag-select{
        width: 650px;
        margin: 0px auto;
        text-align: center;
        h1{
            text-align: center;
            padding: 20px 0px;
            color: #fff;
            font-size: 34px;
            font-weight: 900;
            font-family: "Open Sans";

        }
        h1:after{
            height: 5px;
            width: 100px;
            display: block;
            background-color: #fff;
            content: "";
            margin: 20px auto;
        }
        .flag-container{
            .flag{
                margin: 0px 10px;
                border-radius: 10px;
                max-height: 100px;
            }
            .flag:hover{
                cursor: pointer;
            }
        }
        .character{
            height: 30vh;
            text-align: center;
        }
    }
</style>

<script>
import Vue from "vue";
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
export default {
    props: {
        method: { type: Function },
    },
    data: ()=>({
        
        languages:[
            {name:"english", id:0, shorthand: "EN", image: require("../assets/flags/english.png")},
            {name:"korean", id:1, shorthand: "KR" , image: require("../assets/flags/korean.png")}
        ]
    }),
    methods:{
        onLanguageSelect(payload){
            this.$emit('language-change', payload);
        },
        getFlagImageUrl(name){
            return require("../assets/flags/"+name+".png")
        }
    },
    created(){
        if(this.$cookies.isKey("language")){
            this.languages.forEach(element => {
                if(element.id==this.$cookies.get("language")){
                    this.onLanguageSelect(element)
                    return;
                }
            });
        }
    }
}
</script>