// Important stuff
import Vue from "vue";
import router from "./router";
import App from "./App.vue";
// Not important stuff
import VueGtag from "vue-gtag";

Vue.prototype.$language = "none"
new Vue({ router, render: createElement => createElement(App) }).$mount("#app");


