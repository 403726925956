
<template>
	<section class="header-wide">
		<div class="header-container">
			<div class="side-50-50" id="side-text">
				<h1>{{getLocalText(locale.title1)}} {{getLocalText(locale.title2)}}</h1>
				<p>{{getLocalText(locale.subtitle)}}</p>
				<p>{{getLocalText(locale.subtitle2)}}</p>
				<button class="button" src="../assets/button_green.png" alt="" @click="goToStoreLink">{{getLocalText(locale.playNow)}}</button>
			</div>
			<div class="side-50-50" id="side-image">
				<img src="../assets/field_thumb.png" alt="">
			</div>
		</div>
	</section>
</template>

<script>
export default{
	methods:{
		goToStoreLink(){
			window.location.href = "https://gameofnations.onelink.me/GIhB/92c74d6c"
		}
	},
	data: ()=>({
		locale:{
			title1:[
				"Welcome to",
				"게임 오브 네이션에"
			],
			title2:[
				"FootBrawl!",
				"오신 것을 환영합니다!"
			],
			subtitle:[
				"Imagine you are back in the days when football was rough and player tackles were ruthless. Your goal in FootBrawl is to build your team of footballers by training, upgrading, buying and selling players.",
				"새로운 방치형 RPG 게임 오브 네이션에서 잠깐 시간을 내어 세계 정복의 즐거움을 느껴보세요. 손가락을 움직일 필요도 없습니다. 화면만 밀어도 바로 시작됩니다!"
			],
			subtitle2:[
				"As the team manager, scout other teams, set out the best soccer formations and tactics, equip your players and make smart moves during the match that will secure victory."
			],
			playNow:[
				"Play Now",
				"지금 재생"
			]
		}
	}),
	methods:{
        getLocalText(localizationVar){
			// return localizationVar[this.$language.id]
			return localizationVar[0]
		}
    },
}
</script>

<style lang="less">
*{
	font-family: "Open Sans",sans-serif;
}
.header-wide{
	box-sizing: border-box;
	height: 420px;
    width: 100%;
	background: rgb(5,111,179);
	background: linear-gradient(180deg, rgba(5,111,179,1) 0%, rgba(10,103,167,1) 100%);
	color: #FFF;
	padding: 0px 40px;
	margin-top: 0px;
	box-sizing: border-box;
	margin-bottom: 0px;

	.header-container{
		height: 100%;
		max-width: 1000px;
		margin: 0px auto;
		
		h1{
			color: #fff;
			font-size: 40px;
			font-weight: 700;
			line-height: 1;
			font-family: "Open Sans",sans-serif;
		}
		p{
			color: rgb(219, 219, 219);
			margin-top: 20px;
			line-height: 28px;
			font-family: "Open Sans",sans-serif;
			font-size: 20px;
		}
		.button{
			width: 140px;
			height: 50px;
			margin: 20px auto;
			transform: scaleX(1);
			cursor: pointer;
			background-image: url("~/assets/button_green.png");
			background-size:contain;
			background-repeat: no-repeat;
			background-color: rgba(255, 255, 255, 0);
			border: none;
			font-size: 18px;
			font-weight: 900;
			padding-bottom: 10px;
			font-family: "Open Sans", sans-serif;
			color: #fff;
			display: none;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		.side-50-50{
			height: 100%;
			width: 50%;
			float: left;
		}
		#side-text{
			padding-top: 35px;
			height: auto;
		}
	}
}
@media(max-width:790px) {
	.header-wide{
		height: 340px;
		.side-50-50{
			height: 50%;
			width: 100%;
			float: none;
			
		}
		#side-text{
			width: 100%;
			text-align:center;
		}
		#side-text h1{
			text-align:center;
		}
		#side-image{
			display: none;
		}
		.header-container{
			width: 100%;
			.button{
			}
		}
	}
}
@media(max-width:500px) {
	.header-wide{
		height: 520px;
	}
}
</style>
