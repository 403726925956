import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./pages/Home.vue";
import PrivacyPolicy from "./pages/PrivacyPolicy.vue";
import TermsOfService from "./pages/TermsOfService.vue";

Vue.use(VueRouter);
const routes = [
  {path: "/", component: Home },
  {path:"/privacy-policy", component: PrivacyPolicy},
  {path:"/tos", component: TermsOfService}
];
const router = new VueRouter({
  mode: "history",
  routes: routes
});
export default router;
