<template>
  <div>
    <logo></logo>
    <script type="application/json" id="gdpr-levels">{"required":{"title":"Strictly necessary cookies","content":"These cookies are essential for the operation of the site.","scripts":[{"position":"head","content":""},{"position":"body","content":""},{"position":"footer","content":""}]},"third_party":{"title":"3rd party cookies","content":"Keeping this cookie enabled helps us to improve our website.","scripts":[{"position":"head","content":""},{"position":"body","content":""},{"position":"footer","content":""}]}}</script>
<!-- <gdpr-modal button-agreement="Save changes" button-close="" title="Cookie settings" content="You can find out more about which cookies we use, or switch them off. Here, you'll also find links to our [gdpr_policy]Cookie Policies[/gdpr_policy], which explain how we process your personal data." label-active="Enabled" label-inactive="Disabled" ></gdpr-modal>
<gdpr-bar button-agreement="Accept" button-preferences="Settings" content="This website uses cookies to provide you the best browsing experience. [gdpr_policy]Find out more[/gdpr_policy] or adjust your [gdpr_settings]settings[/gdpr_settings]." ></gdpr-bar> -->
    <header-wide></header-wide> 
    <div class="content-other">
      <Heading :title=getLocalText(locales.heading1) :subtitle=getLocalText(locales.subtitle1)></Heading>
      <image-carousel></image-carousel>
      <!-- <nation-list></nation-list> -->
      <Heading id="register" :title=getLocalText(locales.heading2) :subtitle=getLocalText(locales.subtitle2)></Heading>    
      <email></email>
    </div>
    <cookies></cookies>
  </div>
</template>

<script>
import Vue from "vue"
import Heading from "../components/Heading.vue"
import Logo from "../components/Logo.vue"
import Header from "../components/Header.vue"
import ImageCarousel from "../components/ImageCarousel.vue"
import PromptCookies from "../components/PromptCookies.vue"
import EmailRegister from "../components/EmailRegister.vue"
export default {
  components: {
    Heading,
    'logo': Logo,
    'header-wide': Header,
    'image-carousel': ImageCarousel,
    'cookies': PromptCookies,
    'email' : EmailRegister
  },
  data: ()=>({
    locales:{
      heading1:[
        "Media",
        "게임을 시작하자"
      ],
      subtitle1:[
        "Start your football career with your friends and teammates in this sports game for real champions!",
        "매력적인 클레오파트라의 눈길로 적을 조종하고 아우라로 부대를 회복하세요! 장난꾸러기 신 로키의 도움을 받으세요. 분명 뭔가 유용한 술수를 몇 가지 숨겨놓았을 겁니다. 토르도 이번 싸움에 동참할 거라고 하더군요. 그게 아니라면 여신 아테나와 처음 맞붙었을 때 전설 속 미노타우르스를 데리고 가 빠르게 끝내는 방법도 좋을 것 같습니다."
      ],
      heading2:[
        "Stay up to date!",
        "국가들"
      ],
      subtitle2:[
        "Be the first to know when the game is out!",
        "편히 앉아 느긋하게 즐기세요 특별한 능력을 가진 전설 속 나라의 특별 부대를 해제하세요. 신화 속 영웅, 전설 속 생물과 신이 대의를 위해 여러분 밑으로 모여 전투를 벌입니다"
      ]
    }
  }),
  methods:{
    getLocalText(localizationVar){
			// return localizationVar[this.$language.id]
      return localizationVar[0]
		}
  },
};
</script>
<style lang="less" scoped>
  div{
    position: relative;
  }
  .content-blue{
    background-color: rgba(10,103,167,1);
  }
  .content-other{
    background-image: url("../assets/field.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: grey;
    background-blend-mode: multiply;
    padding-bottom: 100px;
  }
</style>
