<template>
    <div class="heading">
        <h1>{{title}}</h1>
        <h2>{{subtitle}}</h2>
    </div>
</template>
<script>
export default {
    props:{
        title: String,
        subtitle: String,
    }
}
</script>
<style lang="less" scoped>
*{
	  font-family: "Open Sans",sans-serif;
}
.heading{
    display: block;
    color: #fff;
    margin: 30px 0px;
    margin-top: 60px;
    text-shadow: 0px 2px 20px black;
}
h1{
    text-align: center;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
}
h2{
    max-width: 630px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    margin: 0px auto;
    font-weight: 300;
    line-height: 1.3;
}
</style>