<template>
    <div>
        <div class="title-container">
            <section>
                <router-link to="/" class="logo">
                    <img class="logo-image-1" src="../assets/footbrawl.png"/>
                </router-link>
            </section>
        </div>
        
        <div class="pp-text">
            <h1 class="pp-header">{{object.title}}</h1>
            <!--<dropdown class="my-dropdown-toggle"
                :options="languageList" 
                :selected="object" 
                v-on:updateOption="onLanguageSelect" 
                :placeholder="'Select an Item'"
                :closeOnOutsideClick="true">
            </dropdown>-->
            <PPtext :lang="language"></PPtext>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Heading from "../components/Heading.vue";
import Logo from "../components/Logo.vue";
import PPtext from "../components/PPText.vue"

import dropdown from '../components/Dropdown.vue';

export default {
    components:{
        PPtext,
        "dropdown": dropdown
    },
    data: () => ({
        language:'EN',
        languageList:[
            {
                name:"EN",
                title: "Privacy Policy"
            },
            {
                name:"KR",
                title: "개인 정보 정책"
            },
            {
                name:"JP",
                title: "個人情報保護方針"
            }
        ],
        object:{
            name:"EN",
            title: "Privacy Policy"
        }
    }),
    methods:{
        onLanguageSelect(payload){
            this.language = payload.name
            this.object= payload
            console.log(this.language)
        },
    },
    created(){
        window.scrollTo(0,0);
        this.languageList.forEach(element => {
            if(element.name==this.$language.shorthand){
                this.onLanguageSelect(element);
                console.log(element.name)
            }
        });
    }
    
};
</script>

<style lang="less">
.title-container{
	width: 100%;
	.logo{
		max-width: 900px;
        margin: 0px auto;
        margin-top: 20px;
		height: 100px;
		.logo-image-1{
			// margin: -50px 0px;
            width: 100%;
            height: 100%;
            background-size: cover;
            object-fit: cover;
		}
		display: flex;
		justify-content: center;
		overflow:unset;
	}
}
.pp-text{
    font-family: "Open Sans",sans-serif; 
    .pp-header{
        font-family: "Open Sans",sans-serif;
        color: #fff;
        font-weight: 700;
        font-size: 36px;
    }
    a{
        color: #00ebfc;
    }
    color: #fff;
    width: 900px;
    margin: 0px auto;
}



@media(max-width:481px) {
    .title-container{
	    .logo{
            height: 100px;
            .logo-image{
                height: 100px;
            }
        }
    }
    .pp-text{
        width: 90%;
    }
}
</style>
