<template>
    <div>
        <div v-if="lang=='EN'" class="tos-text">
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Terms Of Use</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">(Revised November
                2022)<br>&nbsp;<br>&nbsp;YOUR USE OF OUR SERVICES AND APPLICATION IS SUBJECT TO YOUR AGREEMENT TO THE TERMS OF
                THIS AGREEMENT.<br>&nbsp;<br>This application (referred to as "<strong>Footbrawl</strong>") is offered
                to you, the user, by Power Challenge AB (�<strong>PC</strong>") conditioned on your acceptance,
                without modification, of the terms contained herein. Your use of Footbrawl constitutes your agreement to all
                such terms (the "Agreement"). PC may change, add or remove any part of this Agreement, or any other
                terms associated with the use of Footbrawl, at any time, by posting a notice of such changes. Any changes shall
                become part of the Agreement and shall apply as soon as such a notice is posted. By continuing to use Footbrawl
                after the notice is posted, you are indicating your acceptance of those changes.<br>&nbsp;<br>&nbsp;By register
                an account on Footbrawl you accept and warrant that you fully understand and agree to be bound by the terms of
                use of this Agreement, by the privacy policy on this site and by all the rules and guidelines of this site. You
                will always be able to find the latest agreements and policies in the footer of this site.<br>&nbsp;Failure to
                abide by these terms and conditions may result in disqualification, account closure, forfeiture of funds and/or
                legal action against you. If you do not agree to these terms and conditions, do not create an account and
                don�t run our software.<br>&nbsp;<br>&nbsp;Since the text in this Agreement, as well as the rules and
                guidelines at our application, could be presented in several languages, English is always the deciding language
                in case of differences in interpretation.<br>&nbsp;<br>&nbsp;All contents of this application are: Copyright
                © Power Challenge AB All rights reserved.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">GENERAL TERMS OF USE
            </p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Footbrawl is only for
                your personal use. You may not sell or exchange anything you copy from Footbrawl or use it for any business or
                commercial purpose. As long as you comply with the terms of this Agreement, PC grants you a non-exclusive,
                non-transferable, limited right to enter, use, perform and display Footbrawl and everything on it. You agree not
                to interrupt or attempt to interrupt the operation of Footbrawl in any way.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">A user only allowed to
                have one account. It is not allowed to take over another user's account.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">The e-mail address you
                include in your account registration should be correct and existing. If it doesn�t exist, we will be
                unable to send you a link to reset your password if you should forget it, nor will you be able to access our
                in-game support form. Footbrawl also has a newsletter and certain offerings that you approve being sent to your
                e-mail address by registering your account.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">It is your
                responsibility to protect your password. Any actions carried out by the use of your login details are at your
                sole risk and any liability therefrom shall be strictly yours. Never give out your password to anyone claiming
                to be a crew member or admin, we will NEVER ask for your password in any situation.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">All taxes, duties and
                fees applicable in connection with any winnings at Footbrawl are the sole responsibility of the user.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Don�t make use of
                possible bugs or exploits. When there are errors in the code or in the system in general it is considered as
                cheating if you exploit this to benefit from it compared to other users.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">You understand that
                online games, and system specifications necessary to play those games, may continuously evolve over time.
                Accordingly, PC reserves the right to modify and/or increase the system specifications necessary to play any
                online game - whether free or subscription based - at any time and without notice.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Your failure to abide
                by this agreement may result in cancellation of the fee-based content and/or service without refund and in
                additional charges based on unauthorized use.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">FOOTBRAWL COMMUNICATION
                FEATURES</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Footbrawl communication
                features (e.g. game room chat, message boards, forums, community areas, team pages) are services provided to
                members of Footbrawl. By using any of PC Communication Features, you agree to be bound by PC Privacy Policy as
                well as this agreement. You understand that anything sent through or appearing in PC�s Communication
                Features are the sole responsibility of the person posting a message.<br>&nbsp;<br>&nbsp;PC does not control the
                content of any postings in PC's Communication Features and does not guarantee the accuracy, integrity or
                quality of anything within them. You understand that by using PC's Communication Features, you may be
                exposed to content that is offensive, indecent or objectionable. Under no circumstances will PC be liable for
                any errors or omissions in any postings or for any loss or damages of any kind incurred as a result of the use
                of any information contained within PC's Communication Features.<br>&nbsp;<br>&nbsp;PC retains the right to
                terminate Footbrawl membership, including membership through our partners, of anyone who violates our rules
                below or on the site.<br>&nbsp;<br>&nbsp;You agree not to do any of the following while on Footbrawl or in any
                communication feature provided by PC:</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">transmit any message,
                information, data, text, software or graphic files, or other materials ("Content") that is unlawful,
                harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, or may be invasive of
                another's right of privacy or publicity, hateful, racially, ethnically or otherwise objectionable;</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">impersonate any person
                or entity, including but not limited to, a Footbrawl crew member or admin, guide or host or falsely state or
                otherwise misrepresent your affiliation with a person or entity;</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">post or transmit any
                Content that you do not have a right to transmit under any law or under contractual or fiduciary relationships
                (such as nondisclosure agreements);</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">post or transmit any
                Content that contains a virus or corrupted data;</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">delete any author
                attributions, legal notices or proprietary designations or labels that you upload to a PC Communication Feature;
            </p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">use a PC Communication
                Feature in a manner that adversely affects the availability of its resources to other users (e.g., excessive
                shouting [use of all caps], flooding [continuous posting of repetitive text] or excessively large embedded
                images);</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">post or transmit any
                unsolicited advertising, promotional materials, "junk mail," "spam," "chain
                letters," "pyramid schemes," or any other form of solicitation;</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">intentionally or
                unintentionally violate any applicable local, state, national or international law, rule or regulation; and</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">upload or transmit any
                Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights
                ("Rights") of any party.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">By submitting (e.g.,
                uploading or transmitting) Content to a PC Communication Feature, you automatically grant PC the royalty-free,
                perpetual, irrevocable, non-exclusive, and fully sublicensable right and license to use, reproduce, modify,
                adapt, publish, translate, create derivative works from, distribute, perform and display such Content (in whole
                or part) worldwide and/or to incorporate it in other works in any form, media, or technology now known or later
                developed. In addition, you warrant that any and all so-called "moral rights" in the Content have been
                waived.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">PC and our crew shall
                have the right, but not the obligation, in their sole discretion to edit, refuse to post, or remove any Content
                posted in any PC Communication Feature. Without limiting the foregoing, PC and its crew shall have the right to
                remove any Content that is in violation of the provisions hereof or otherwise objectionable. You agree to
                indemnify and hold PC harmless from any claim or demand, including reasonable attorneys' fees, made by any
                third party due to or arising out of your violation of these terms and conditions or your violation of any
                rights of another.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">SOFTWARE AVAILABLE IN
                THIS APPLICATION</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Any software that is
                made available to download from this application ("<strong>Software</strong>") is the copyrighted work
                of PC. PC hereby grants to you, the user, a personal, non-transferable license to use the Software for viewing
                and other use of this application in accordance with these terms of use, and for no other purpose. Please note
                that all Software, including, without limitation, all HTML code contained in this application, is owned by PC
                (or PC�s suppliers) and are subject to protection under various intellectual property laws, including
                copyright, throughout the world. Unauthorized copying of the Software, or any portion thereof, is expressly
                forbidden. You may be held legally responsible for any infringement caused by your failure to abide by the terms
                of this Agreement. You may not modify, adapt, translate, reverse engineer, decompile, or disassemble the
                Software or create any derivative work thereof or any software interfacing with the Software.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">This Agreement
                authorizes you to use the Software on the single microcomputer in which the Software is installed. Title to the
                Software will at all times remain with PC and third parties who have granted PC the right to distribute portions
                of the Software.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">You may not (a)
                electronically transfer the Software from one computer to another over a network (b) distribute copies of the
                Software to others, (c) print, modify, adapt, translate, or create derivative works based on the graphics or
                written materials included in the Software without Footbrawl's prior written consent which may be withheld,
                or (d) employ any means of circumventing any cheating protection mechanism included in the Software.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">DISCLAIMER OF LIABILITY
            </p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">NEITHER PC NOR ANY
                PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING OR DISTRIBUTING ANY CONTENT OR SERVICES CONTAINED ON FOOTBRAWL,
                NOR ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS, MAKE ANY WARRANTY WHATSOEVER, INCLUDING,
                WITHOUT LIMITATION, THAT FOOTBRAWL WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE
                RESULTS THAT MAY BE OBTAINED FROM USE OF FOOTBRAWL OR AS TO THE ACCURACY, RELIABILITY, QUALITY OR CONTENT OF ANY
                INFORMATION, SERVICE OR MERCHANDISE PROVIDED ON OR THROUGH FOOTBRAWL, INCLUDING, WITHOUT LIMITATION, ALL PC
                COMMUNICATION FEATURES.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">The Software is
                delivered "as is" without any warranties. USE OF FOOTBRAWL IS AT YOUR SOLE RISK.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">LIMITATION OF LIABILITY
            </p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">In no event shall PC or
                any of its licensors or any of its or their respective partners, contractors, vendors or suppliers be liable to
                licensee or any third party for any claims or damages, including, but not limited to any lost profits, lost
                savings, lost data or any other direct incidental, consequential, indirect or punitive damages, whether
                resulting from impaired or lost data, software or computer failure or any other cause, even if such damages are
                caused by PC's own negligence and even if PC is advised of the possibility of such damages, or for any
                other claim by any user of the software. In no event shall the liability of PC or any of its licensors or any of
                its or their respective contractors, vendors or suppliers be liable to licensee or any third party exceed the
                amount you paid for the Software.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">No oral or written
                advice or statement given or made by PC, or its dealers, agents, partners, distributors or employees shall
                create any warranty or in any way increase the scope of the foregoing warranty.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">TERMINATION</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Either PC or you may
                terminate this Agreement at any time.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Fees paid for any
                subscription term or pay-per-use are paid in advance and are not refundable in whole or in part.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Power Tokens expire 365
                days after their purchase.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">We can terminate the
                Agreement by asking you to stop using Footbrawl, and, if necessary, by preventing your access to Footbrawl.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Footbrawl reserves the
                right to close down existing accounts without explanation.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Footbrawl reserves the
                right to refuse any application without further explanation.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Upon termination of
                this Agreement, you shall have no further rights to use Footbrawl or its services.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Inactive accounts, that
                are not accessed after 70 days may be automatically deleted.</p>
            <p style="line-height: 108%;text-align: left;margin-bottom: 0.28cm;background: transparent;">Power Tokens in
                inactive accounts, that are not accessed after 91 days will expire.</p>
        </div>
        <div v-if="lang=='KR'" class="tos-text">
            <p><strong>이용 약관</strong></p>
            <p>1 <strong>계약 및 당사 서비스</strong> </p>
            <p>1.1 이 약관 (최종 업데이트 : <strong>2020 </strong>년 3 월 <strong>6 일 </strong>) ( " <strong>약관 </strong>") 및 여기에 명시 적으로 언급 된 모든 문서 ( " <strong>계약 </strong>")는 귀하가 사용할 때 당사와 체결하는 계약의 약관입니다. 소프트웨어, 업데이트 및 업그레이드, 사용자 설명서, 기타 전자 자료 및 그러한 소프트웨어 및 자료의 사본 (이하&ldquo; <strong>게임 </strong>&rdquo;)을 포함하되 이에 국한되지 않는 국가 게임 게임 . </p>
            <p>1.2 게임에 액세스하고, 설치하고, 사용을 시작하기 전에이 약관과 여기에 언급 된 모든 문서를주의 깊게 읽으십시오. 당사 게임에 액세스, 다운로드 및 / 또는 사용함으로써 귀하는 본 계약의 약관을 읽고 이해했으며 이에 동의했으며 당사 게임에 액세스하고 사용할 때마다이를 준수하는 데 동의 함을 나타냅니다. 본 계약에 동의하지 않는 경우 게임에 액세스, 다운로드 및 / 또는 사용하지 마십시오. 본 계약은 당사 게임의 각 사용자 (각각 " <strong>사용자 </strong>", <strong>"귀하", "귀하의" </strong>)에게 적용됩니다.&nbsp;</p>
            <p>2 우리에 대한 <strong>정보</strong> </p>
            <p>Game of Nations는 가상 게임으로 eRepublik Labs Ltd (&ldquo; <strong>우리 </strong>&rdquo;,&ldquo; <strong>우리 </strong>&rdquo;및&ldquo; <strong>우리 </strong>&rdquo;)에서 제공하는 응용 프로그램 입니다. eRepublik Labs Ltd는 회사 번호 462101로 아일랜드에 설립되었으며 Unit 4 Block A Broomfield Business Park Malahide, Dublin, K36 RF83, Ireland에 등록 사무실이 있습니다.</p>
            <p>3 <strong>귀하의 계정</strong> </p>
            <p>3.1 귀하는 재 라이센스, 임대, 임대, 판매, 거래, 선물 또는 기타 방법으로 귀하의 계정을 양도하거나 이러한 방식으로 얻은 계정을 사용할 수 없습니다. </p>
            <p>3.2 당사 게임에 대한 액세스는 제 3 자의 애플리케이션 / 웹 사이트를 통해서도 허용 될 수 있으며, 이는 당사의 게임에 액세스하려면 귀하가 당사의 게임에 연결할 제 3 자의 웹 사이트 / 애플리케이션에 유효한 계정이 있어야 함을 의미 할 수 있습니다. 제 3 자와의 계정 생성은 해당 제 3자가 제공하는 서비스 약관 및 개인 정보 보호 정책에 의해 규제되며 이러한 모든 문서는 본 계약에 대한 별도의 계약을 형성합니다. 당사는 제 3 자 웹 사이트 및 / 또는 애플리케이션의 사용으로 인해 귀하가 입은 손실 또는 손해에 대해 책임을지지 않으며 명시 적으로 배제합니다. 제 3 자 웹 사이트 / 애플리케이션의 이용 약관을주의 깊게 읽으십시오.</p>
            <p>4 <strong>게임에 액세스</strong> </p>
            <p>4.1 본 계약에 따라 귀하는 개인적 및 비상업적 용도로만 게임을 사용할 수 있습니다. </p>
            <p>4.2 귀하의 게임 액세스 및 사용 조건으로 귀하는 본 계약을 체결하고 구속 할 권리, 권한 및 능력이 있음을 당사에 보증합니다. </p>
            <p>4.3 게임을 사용하려면 16 세 이상이어야합니다. 16 세 미만은 게임을 사용할 수 없습니다. 16 세에서 18 세 사이의 미성년자가 게임을 사용하는 경우 부모 또는 보호자의 동의가 필요합니다. 게임을 사용함으로써 귀하는 16 세 이상이거나 본 계약에 따라 게임을 사용하는 데 필요한 동의를 얻었음을 선언합니다. 그러한 동의가 승인되지 않았거나 귀하가 만족할만큼 승인되지 않았 음을 입증 할 수없는 경우 당사는 사용자 계정을 폐쇄 할 권리를 보유합니다. 자녀가 게임을 사용하도록 허용하는 부모 또는 보호자에게 중재는 절대적인 것이 아니므로 온라인에서 자녀의 안전에 대해 자녀와 소통하는 것이 중요하다고 조언합니다. 양방향 서비스를 사용하는 미성년자는 부모 또는 보호자가 자신에게 미칠 수있는 잠재적 인 위험을 인식해야합니다.&nbsp;</p>
            <p>4.4 우리는 사용자의 활동 또는 게임 사용을 모니터링하거나 조정할 의무가 없지만 관련 법률, 규정, 법적 절차 또는 규제 기관 요청을 충족하기 위해 필요한 정보를 항상 모니터링, 보유 및 공개 할 권리를 보유합니다. . </p>
            <p>4.5 우리는 당신이 우리를 문의하는 것이 좋습니다 않습니다 에 support@gameofnations.net , 본 계약의 위반 또는 이의 다른 방법으로이 계약의 침해 또는 침해의 사회자에게 통지하는 어떤 내용을보고 할 수 있습니다 이메일을.</p>
            <p>4.6 당사 게임에 대한 액세스는 일시적으로 허용되며, 당사는 언제든 책임없이 사전 통지 또는 책임없이 게임의 모든 측면 또는 기능을 철회, 일시 중지 또는 수정할 권리를 보유합니다. 게임에 대한 액세스는 "있는 <strong>그대로 </strong>"제공됩니다. 귀하는 당사 게임의 콘텐츠 및 / 또는 기타 자료에 의존하여 결정을 내리거나 (또는 ​​내리지 않도록) 의존해야하는 조치를 취 (또는 취하지 않도록)해서는 안됩니다. 당사는 게임 사용자 또는 콘텐츠에 대해 알 수있는 사람이 그러한 콘텐츠 및 / 또는 자료에 대한 의존으로 인해 발생하는 모든 책임과 책임을 부인합니다. 당사는 게임의 정보를 최신 상태로 유지하거나 완전하게 유지하거나 그렇게하지 않을 경우 책임을지지 않습니다. </p>
            <p>4.7 게임을 사용할 때 귀하는 본 계약의 일부를 구성하는 개인 정보 보호 정책의 조항을 준수해야합니다. 당사의 개인 정보 보호 정책은 게임을 사용하는 동안 수집 한 귀하에 대한 정보를 사용하는 방법을 명시합니다. </p>
            <p>5 <strong>사용권 제한</strong> </p>
            <p>5.1 귀하는 다음과 같은 행위를하지 않을 것에 동의합니다 (제 3자가 허용하지 않는 것에 동의 함). </p>
            <p>5.1.1 게임의 일부를 수정, 개조, 번역 또는 리버스 엔지니어링하는 행위</p>
            <p>5.1.2 게임 또는 게임을 통해 획득 한 콘텐츠 또는 기타 자료에 포함 된 저작권, 상표 또는 기타 소유권 고지를 제거합니다.</p>
            <p>5.1.3 로봇, 스파이더, 검색 / 검색 애플리케이션 또는 기타 자동화 된 장치, 프로세스 또는 수단을 사용하여 게임의 일부에 액세스, 검색 또는 색인을 생성하는 행위</p>
            <p>5.1.4 정보 또는 콘텐츠의 검색 가능한 데이터베이스를 구성하거나 채울 목적으로 게임의 일부에 액세스, 화면 스크랩, 검색 또는 색인 생성</p>
            <p>5.1.5 게임 콘텐츠 부분의 일부를 재구성하거나 프레임을 지정합니다.</p>
            <p>5.1.6 자동화 된 수단 또는 허위 또는 허위로 사용자 계정을 생성하는 행위</p>
            <p>5.1.7 "스팸"과 같은 원치 않는 전자 통신을 생성 또는 전송하거나, 다른 사용자에게 과도한 도전이나 초대를하거나, 다른 사용자가 게임을 즐기는 데 방해가되는 행위</p>
            <p>5.1.8 게임을 사용하여 네트워크의 보안을 위반하고, 비밀번호 또는 보안 암호화 코드를 크래킹하고, 위협적이거나 외설적 인 것으로 간주되는 불법 자료를 전송 또는 저장하는 행위</p>
            <p>5.1.9 귀하의 게임 사용 이외의 목적으로 게임에서 제공되는 콘텐츠를 복사 또는 저장하는 행위</p>
            <p>5.1.10 게임의 올바른 작동을 방해하는 장치, 소프트웨어 또는 루틴을 사용하거나 게임의 올바른 작동을 방해하려는 시도 </p>
            <p>5.1.11 당사의 단독 재량에 따라 IT 인프라에 불합리하거나 불균형 적으로 큰 부하를 부과하거나 부과 할 수있는 조치를 취합니다. </p>
            <p>5.1.12 게임을 고의 또는 비 의도적으로 사용하여 관련 법률을 위반하는 행위 </p>
            <p>5.1.13 이 단락에 설명 된 금지 된 활동과 관련하여 다른 사용자에 대한 개인 데이터를 수집하거나 저장합니다. </p>
            <p>5.1.14 게임을 통한 설문 조사, 콘테스트, 피라미드 계획, 연쇄 편지, 원치 않는 이메일 또는 스팸에 참여하거나 홍보하는 행위 </p>
            <p>5.1.15 광고, 마케팅, 자선, 캠페인, 모든 종류의 홍보 또는 정치적 목적으로 게임을 사용하는 행위 </p>
            <p>5.1.16 개인 또는 단체를 사칭하는 행위 </p>
            <p>5.1.17 제 3 자의 지적 재산권 침해를 포함하는 법률 또는 계약상의 의무에 따라 제공 할 권리가없는 자료를 게임을 사용하여 게시, 이메일, 전송 또는 기타 방식으로 제공하는 행위. </p>
            <p>6 <strong>콘텐츠 표준 및 라이센스</strong> </p>
            <p>6.1 이러한 조항은 귀하가 당사 게임에 기여하는 모든 콘텐츠 및 이와 관련된 모든 대화 형 서비스에 적용됩니다. </p>
            <p>6.2 귀하는 편지뿐만 아니라 다음 표준의 정신을 준수해야합니다. 표준은 콘텐츠의 각 부분과 전체에 적용됩니다. </p>
            <p>6.3 귀하는 게임에 제출하거나 다른 사용자에게 전송하는 모든 콘텐츠에 대해 전적으로 책임을집니다. </p>
            <p>6.4 당사 게임에 게시 된 모든 정보 또는 통신에 대한 책임은 전적으로 귀하에게 있습니다. 귀하는 게임을 통해 공개적으로 게시되거나 비공개로 전송 된 모든 정보는 해당 콘텐츠가 생성 된 사람의 전적인 책임이며 당사는 모든 콘텐츠의 오류 또는 누락에 대해 또는 사용자의 경기. 귀하는 게임 사용 과정에서 귀하와 상호 작용할 수있는 다른 사용자의 신원을 보장 할 수 없음을 이해합니다. 또한 다른 사용자가 제공 한 데이터 또는 이들이 설명 할 수있는 관계의 신뢰성을 보장 할 수 없습니다. </p>
            <p>6.5 다음은 게임에서 금지 된 콘텐츠 유형의 일부 목록입니다. 콘텐츠는 다음과 같아서는 안됩니다. </p>
            <p>6.5.1 명예 훼손, 학대, 괴롭힘, 괴롭힘, 스토킹, 위협 또는 다른 사람의 불쾌감을주는 행위</p>
            <p>6.5.2 불법적이거나 외설적이거나 명예를 훼손하거나 선동적이거나 외설적이거나 공격적이거나 인종 차별적이거나 위협적이거나 폭력적이거나 차별적이거나 위협적이거나 선동적이거나 선동적이거나 기밀을 침해하거나 사생활을 침해하거나 부적절해야합니다.</p>
            <p>6.5.3 관련 법률 또는 규정을 위반하는 행위</p>
            <p>6.5.4 사람 및 / 또는 단체를 속이거나 오도하는 행위 :</p>
            <p>6.5.5 모든 콘텐츠를 업로드, 게시, 이메일, 전송 또는 기타 방법으로 제공하거나 법률 또는 계약 또는 신탁 관계 (예 : 내부자 정보 또는 고용 관계의 일부로 또는 비공개 계약에 따라 알게되거나 공개 된 독점 및 기밀 정보</p>
            <p>6.5.6 특허, 상표, 영업 비밀, 저작권 또는 기타 소유권을 침해하는 콘텐츠를 업로드, 게시, 이메일 전송, 전송 또는 기타 방법으로 제공하거나 통신을 시작하는 행위</p>
            <p>6.5.7 요청하지 않거나 승인되지 않은 광고, 홍보 자료, " <strong>정크 메일 </strong>", " <strong>스팸 </strong>", " <strong>연쇄 편지 </strong>", " <strong>피라미드 계획 </strong>"또는 기타 형태의 권유를 게시, 이메일, 전송 또는 기타 방법으로 제공하는 행위</p>
            <p>6.5.8 소프트웨어 또는 하드웨어 또는 통신 장비의 기능을 중단, 파괴 또는 제한하도록 설계된 소프트웨어 바이러스 또는 기타 컴퓨터 코드, 파일 또는 프로그램이 포함 된 자료를 게시, 이메일, 전송 또는 기타 방법으로 제공하는 행위</p>
            <p>6.5.9 게임 또는 게임에 연결된 서버 또는 네트워크를 방해하거나 방해하거나 게임에 연결된 네트워크의 요구 사항, 절차, 정책 또는 규정을 위반하는 행위 및 / 또는</p>
            <p>7 <strong>정지 및 해지</strong> </p>
            <p>7.1 귀하는 당사가 단독 재량에 따라 사전 통지없이 귀하의 사용자 계정과 당사 게임에 대한 액세스를 즉시 해지 할 수 있다는 데 동의합니다. 전술 한 내용을 제한하지 않고 다음과 같은 경우 사용자의 게임 사용이 종료 될 수 있습니다. (a) 본 계약 위반, (b) 관련 법 집행 기관, Gardai / 경찰 또는 기타 정부 기관의 요청 (c) 귀하의 요청 (자체적으로 계정 삭제), (d) 당사가 경험 한 예상치 못한 기술적 문제 또는 문제, (e) 귀하의 계정과 관련하여 장기간의 비활성. 귀하의 사용자 계정의 해지에는 당사의 게임, 당사가 결정할 수있는 모든 콘텐츠에 대한 액세스 제거가 포함되며 귀하가 게임을 더 이상 사용하지 못하게 할 수도 있습니다. 또한 귀하는 모든 해지가 당사의 단독 재량에 따라 이루어지며 귀하의 계정 해지 또는 게임 액세스에 대해 귀하 또는 제 3 자에게 책임을지지 않는다는 데 동의합니다. </p>
            <p>7.2 귀하는 당사가 단독 판단으로 본 계약을 위반하거나 공격적이거나 불법적이거나 타인 또는 당사의 권리를 침해하거나 해를 끼치거나 위협 할 수있는 콘텐츠를 검토하고 삭제할 수 있음을 이해하고 동의합니다. 당사는 조사 할 권리를 보유하며 귀하의 게임 사용을 통해 본 계약을 위반했는지 여부를 당사의 재량에 따라 결정할 것입니다. 본 계약 위반이 발생하면 적절하다고 판단되는 조치를 취할 수 있습니다. </p>
            <p>7.3 본 계약을 준수하지 않으면 당사가 다음 조치를 전부 또는 일부 취하게 될 수 있습니다. </p>
            <p>7.3.1 당사 게임 사용 권한의 즉각적, 일시적 또는 영구적 철회</p>
            <p>7.3.2 귀하가 당사 게임에 업로드 한 모든 게시물, 자료 또는 콘텐츠의 즉각적, 일시적 또는 영구적 제거</p>
            <p>7.3.3 (게임을 통한) 공개 또는 개인 경고 발행</p>
            <p>7.3.4 게시 할 수있는 게시물의 양을 제한합니다.</p>
            <p>7.3.5 위반으로 인해 발생하는 배상 기준 (합당한 행정 및 법적 비용을 포함하되 이에 국한되지 않음)에 따른 모든 비용의 배상을위한 귀하에 대한 법적 절차</p>
            <p>7.3.6 귀하에 대한 기타 법적 조치 및 / 또는</p>
            <p>7.3.7 우리가 합리적으로 필요하다고 생각하는 정보를 법 집행 기관에 공개합니다.</p>
            <p>7.4 우리는 콘텐츠 표준 위반 및 사용 제한에 대한 조치에 대한 책임을 배제합니다. 본 계약에 설명 된 응답은 제한되지 않으며 당사는 합리적으로 적절하다고 판단되는 기타 조치를 취할 수 있습니다. </p>
            <p>8 <strong>불만</strong> </p>
            <p>게임에 게시 된 콘텐츠가 본 계약을 위반하거나 다른 방식으로 이의를 제기한다고 생각되는 경우, 해당 콘텐츠 의 전체 세부 정보를 지정하고 해당 콘텐츠의 모든 게시물을 확인하고 그 이유를 명시하여 support@gameofnations.net 으로 이메일을 보내 알려주십시오. 당신의 이의에 대해. 충분한 정보를 제공하지 않으면 불만을 처리하지 못할 수 있습니다. 당사는 조사 할 권리가 있으며 취해야 할 조치 (있는 경우)를 재량에 따라 결정할 것입니다. 게임의 다른 사용자와의 상호 작용에 대한 책임은 전적으로 귀하에게 있습니다. 당사는 귀하와 다른 사용자 간의 분쟁을 모니터링하거나 개입 할 권리가 있지만 그렇게 할 의무는 없습니다.</p>
            <p>9 <strong>제 3 자와의 분쟁</strong> </p>
            <p>누군가가 귀하의 게임 사용과 관련하여 귀하를 상대로 소송을 제기하거나 법적 소송을 제기 할 경우 즉시 당사에 알려야합니다. 귀하는 당사의 요청에 따라 불만을 제기 한 행위를 즉시 중단 할 것입니다. 저희가 귀하에게 요청하는 경우, 귀하는 서면으로 청구 세부 사항을 확인해야합니다. 귀하가 행위를 중지하지 않거나 불만을 제기 한 행위는 계약 조건에 따라 귀하에 대해 적절한 모든 조치를 취할 권리가 있습니다.</p>
            <p>10 <strong>지적 재산권</strong> </p>
            <p>10.1 우리는 게임 및 게임에 게시 된 콘텐츠에 대한 모든 지적 재산권의 소유자 또는 라이센스를 보유하고 있습니다. 이러한 저작물은 전 세계의 저작권법, 상표법, 데이터베이스 법률 및 조약에 의해 보호됩니다. 그러한 모든 권리는 유보됩니다. </p>
            <p>10.2 게임 (텍스트, 사진, 그래픽, 비디오 및 오디오 콘텐츠를 포함하되 이에 국한되지 않음)은 아일랜드 및 기타 국가의 저작권법에 따라 공동 저작물 또는 편집물로서 저작권으로 보호됩니다. 사용자가 제출 한 콘텐츠를 제외하고, 모든 저작권, 상표 및 기타 지적 재산권 또는 소유권을 포함한 게임 및 그 모든 측면은 당사 또는 당사의 라이선스 제공자가 소유합니다. 귀하는 게임 및 게임과 관련하여 사용되는 모든 기본 기술 또는 소프트웨어에 당사의 독점 정보가 포함되어 있음을 인정합니다. 귀하는 명시 적으로 제공된 경우를 제외하고 게임에서 제공되는 콘텐츠, 소프트웨어 및 / 또는 자료의 전체 또는 일부를 수정, 복제, 배포, 파생물 생성, 공개적으로 표시하거나 어떤 방식 으로든 악용 할 수 없습니다. 여기에 명시적이고 분명하게 제공된 경우를 제외하고, 당사와 당사의 공급 업체는 귀하에게 어떠한 명시 적 또는 묵시적 권리도 부여하지 않으며 당사가 귀하에게 명시 적으로 부여하지 않은 게임 요소의 모든 권리는 당사가 보유합니다. </p>
            <p>10.3 귀하가 콘텐츠에 대한 저작 인격권을 보유하고 있다고 판단되는 경우, 귀하는 (a) 귀하가 콘텐츠 또는 콘텐츠의 파생물, 업그레이드 또는 업데이트와 관련하여 개인 식별 정보를 사용하도록 요구하지 않는다고 선언합니다. (b) 귀하는 당사 또는 당사의 사용 권자, 승계자 및 양수인 또는 기타 게임에 의한 콘텐츠의 게시, 사용, 수정, 삭제 및 이용에 대해 이의가 없습니다. (c) 귀하는 콘텐츠에서 저자의 모든 저작 인격권에 대한 권리를 주장하거나 주장하지 않기로 영원히 포기하고 동의합니다. (d) 귀하는 그러한 도덕적 권리로 인해 당사에 대해 주장 할 수있는 모든 청구로부터 당사와 당사의 사용 권자, 승계자 및 양수인을 영원히 석방합니다. </p>
            <p>10.4 게임에 언급 된 모든 제품 이름은 해당 소유자의 상표이며 기타 상표는 때때로 게임에 표시 될 수 있습니다. 당사 게임의 일부 자료에는 제 3 자의 지적 재산을 포함하는 기타 정보가 포함될 수 있습니다. 본 계약에 명시 적으로 제공된 경우를 제외하고 관련 소유자의 명시적인 서면 승인 없이는 게임에 표시된 어떤 내용도 귀하에게 표시된 로고, 정보 또는 상표의 사용권 또는 사용권을 부여하는 것으로 해석되어서는 안됩니다. </p>
            <p>10.5 귀하는 귀하의 개인적인 목적을 위해 당사 게임에서 한 페이지의 사본을 인쇄하고 추출을 다운로드 할 수 있으며 당사 게임에 게시 된 자료에 대해 귀하의 조직 내 다른 사람들의주의를 끌 수 있습니다. 특히, 제 3자가 액세스 할 수 있도록 콘텐츠를 캐시하거나 게임 콘텐츠를 미러링, 스크래핑 또는 프레임 화하거나 당사의 명시적인 서면 허가없이 다른 웹 사이트, 소프트웨어 또는 애플리케이션에 통합해서는 안됩니다. 우리. </p>
            <p>10.6 어떤 식 으로든 인쇄하거나 다운로드 한 자료의 종이 또는 디지털 사본을 수정해서는 안되며, 첨부 된 텍스트와 별도로 삽화, 사진 또는 그래픽을 사용해서는 안됩니다. </p>
            <p>10.7 당사의 게임 자료 작성자로서 당사의 지위 (및 당사 게임에서 확인 된 파트너, 광고주 또는 제 3 자의 지위)는 항상 귀하가 인정해야합니다. </p>
            <p>10.8 귀하는 당사로부터 라이선스를 획득하지 않고 상업적 또는 비상업적 목적으로 당사 게임의 콘텐츠 일부를 사용해서는 안됩니다. </p>
            <p>10.9 본 계약을 위반하여 게임의 일부를 인쇄, 복사 또는 다운로드하는 경우, 게임 사용 권한이 즉시 중단되며, 당사의 선택에 따라 사용자가 만든 자료의 사본을 반환하거나 파기해야합니다.</p>
            <p>11 개의 <strong>가상 아이템</strong> </p>
            <p>11.1 본 계약에 따라 귀하는 당사의 재량에 따라 구매 또는 무료 배포가 가능한 게임 내 가상 아이템을 사용할 수있는 제한된 라이선스 권한이 부여됩니다. 귀하는 게임 내 가상 아이템이 당사, 당사 게임의 다른 사용자 또는 제 3 자로부터 언제라도 금전적 가치로 교환 할 수 없다는 데 동의합니다. 귀하는 귀하가 당사에서 구매 한 게임 내 가상 아이템에 대해 당사가 귀하의 사용자 계정을 해지 할 권리를 행사하고 귀하의 본 계약 위반으로 인한 해지가 아닌 경우를 제외하고는 당사로부터 환불을 청구 할 권리가 없음을 인정합니다. 의심의 여지를 없애기 위해 귀하는 당사가 무료로 귀하에게 제공하거나 다른 게임 사용자가 귀하에게 제공 한 게임 내 가상 아이템에 대해 당사로부터 환불을받을 수 없습니다. 귀하는 당사가 게임 내 가상 아이템과 해당 아이템의 사용을 규제, 수정, 철회, 일시 중지 및 기타 방식으로 통제 할 권리가 있음을 인정하며, 당사의 규제로 인해 귀하에 대한 책임이 없다는 데 동의합니다. 게임 내 가상 아이템의 수정, 철회, 일시 중단 및 / 또는 제어. </p>
            <p>11.2 <strong>귀하는 게임의 게임 플레이에서 허용하는 특정 게임 내 액션의 게임 내 가상 아이템을 </strong>재 라이선스, 대여, 임대, 판매, 거래, <strong>선물 </strong>또는 양도 할 수 없으며 여기에 언급 된 방식으로 획득 한 가상 아이템을 사용할 수 없습니다. 절. 귀하는 당사가 언제든지 단독 재량에 따라 추가 요금 및 요금에 대해 새로운 서비스를 추가하거나 기존 서비스에 대한 요금 및 요금을 수정할 수 있으며 모든 요금 및 요금을 결정할 수 있음을 인정합니다. </p>
            <p>12 <strong>우리의 책임</strong> </p>
            <p>12.1 당사 게임에서 표시, 액세스 또는 사용되는 콘텐츠 및 기타 자료는 정확성에 대한 어떠한 보증, 조건 또는 보증없이 제공됩니다. 법률이 허용하는 한도 내에서 다음을 명시 적으로 제외합니다. </p>
            <p>12.1.1 법령, 관습법 또는 형평법에 의해 암시 될 수있는 모든 조건, 보증 및 기타 조건 과 </p>
            <p>12.1.2 당사 게임과 관련하여 또는 당사 게임의 사용, 사용 불능 또는 사용 결과와 관련하여 사용자가 입은 직간접 적 손실 또는 손해에 대한 모든 책임 소득 또는 수익 손실, 사업 손실, 이익 또는 계약 손실, 예상 절감액 손실, 데이터 손실, 영업권 손실, 관리 또는 업무 시간 낭비에 대한 책임을 포함하되 이에 국한되지 않는 모든 게시 된 자료 및 불법 행위 (과실 포함), 계약 위반 등으로 인해 발생하는 모든 종류의 기타 손실 또는 손해에 대해. </p>
            <p>12.2 이는 당사의 과실로 인해 발생하는 사망 또는 개인 상해에 대한 당사의 책임, 근본적인 문제에 대한 허위 진술 또는 허위 진술에 대한 당사의 책임 또는 관련 법률에 따라 배제되거나 제한 될 수없는 기타 책임에 영향을 미치지 않습니다. </p>
            <p>12.3 당사는 합리적인 통제 범위를 벗어난 문제로 인해 본 계약에 따른 의무를 수행하지 못한 경우 책임을지지 않습니다. 어떠한 경우에도 당사는 콘텐츠의 오류나 누락, 콘텐츠의 사용, 액세스 또는 사용으로 인해 발생하는 모든 종류의 손실 또는 손상을 포함하되 이에 국한되지 않는 콘텐츠에 대해 어떠한 방식으로도 책임을지지 않습니다. 게임의 모든 콘텐츠에 대한 액세스 거부.</p>
            <p>12.4 당사는 분산 된 서비스 거부 공격, 바이러스, 악의적이거나 손상을주는 컴퓨터 프로그램 또는 귀하의 장비 또는 장치, 컴퓨터 프로그램, 데이터 또는 기타 독점 자료를 감염시킬 수있는 기타 기술적으로 유해한 자료로 인해 발생하는 손실 또는 손상에 대해 책임을지지 않습니다. 귀하가 당사 게임을 사용하거나 이에 게시 된 자료를 다운로드했거나 이와 연결된 웹 사이트 / 응용 프로그램을 다운로드했기 때문입니다. </p>
            <p>12.5 당사는 서비스에 액세스하는 데 사용되는 게임 (인터넷 및 귀하의 하드웨어 및 소프트웨어 포함) 사용과 관련된 서비스 또는 시스템 또는 이러한 시스템을 통해 전달되는 정보의 보안을 보장하지 않습니다. 우리는 게임에 대한 액세스 또는 서비스 액세스에 사용되는 시스템에 대한 액세스가 지속적이거나 바이러스 또는 오류가 없음을 보장하지 않습니다. </p>
            <p>12.6 게임 사용을 통해 다운로드하거나 획득 한 모든 자료는 귀하의 재량과 위험에 따라 수행되며 귀하의 컴퓨터 / 장치 손상 또는 그러한 자료의 다운로드로 인한 데이터 손실에 대해 전적으로 책임을 져야합니다. 귀하가 당사로부터 또는 게임을 통해 획득 한 구두 또는 서면 조언이나 정보는 본 계약에 명시 적으로 명시되지 않은 보증을 생성하지 않습니다. </p>
            <p>13 <strong>면책</strong> </p>
            <p>귀하는 (i) 귀하의 액세스로 인해 발생하거나 제 3자가 제기 한 모든 청구 또는 요구로부터 당사, 당사의 부모, 자회사, 임원, 이사, 주주 및 직원을 비용 및 법적 비용을 포함하여 무해하게 보호하는 데 동의합니다. 게임, (ii) 귀하의 당사 서비스 사용, (iii) 귀하의 본 계약 위반 또는 (iv) 귀하 또는 귀하의 계정 또는 사용자 식별 이름 또는 비밀번호를 사용하는 제 3 자의 침해 개인 또는 단체의 지적 재산 또는 기타 권리.</p>
            <p>14 우리 <strong>로부터 연락</strong> </p>
            <p>귀하에게 서비스를 제공하는 과정에서 그리고 귀하의 게임 사용과 관련하여, 당사는 귀하가 당사에 직접 제출 한 이메일 또는 기타 세부 정보를 통해 또는 제 3 자의 웹 사이트 또는 응용 프로그램을 연결하는 다른 응용 프로그램을 통해 귀하와 통신해야 할 수 있습니다. 우리 게임. 귀하는 사용자에게 게임 기능에 대해 알리는 데 도움이되는 이메일을 포함하여 귀하의 계정에 고유하고 게임의 정상적인 작동에 필요한 이메일을 수신하는 데 동의합니다. 귀하는 또한 당사 또는 당사의 파트너 및 / 또는 계열사가 개발 한 새로운 게임 및 / 또는 응용 프로그램에 관한 정보가 포함 된 이메일을 수신하는 데 동의합니다.</p>
            <p>15 <strong>관할권 및 관련 법률</strong> </p>
            <p>아일랜드 법원은 귀하의 거주 국가 또는 기타 관련 국가에서 본 계약 위반에 대해 귀하를 상대로 소송을 제기 할 권리가 있지만 당사 게임 방문으로 인해 발생하거나 이와 관련된 모든 청구에 대해 배타적 인 관할권을 갖습니다. 본 계약은 아일랜드 법의 적용을받습니다. 본 계약에 포함 된 권리 및 구제책은 누적되며 법률에서 제공하는 권리 또는 구제책을 배제하지 않습니다.</p>
            <p>16 가지 <strong>변형</strong> </p>
            <p>당사는 그러한 수정 사항에 대한 통지없이 당사 게임에 나타나는 페이지를 수정함으로써 언제든지 본 계약을 수정할 수 있습니다. 이러한 수정 사항은 수정 된 약관이 게임에 게시되는 즉시 효력이 발생합니다. 귀하는 수정 사항을 알 수 있도록 계약을 주기적으로 검토하는 데 동의합니다. 수정 후에도 게임을 계속 사용하면 수정 된 계약에 동의하는 것입니다.</p>
            <p>17 <strong>포기</strong> </p>
            <p>본 계약 또는 법률에 의해 제공된 권리 또는 구제책을 행사하지 않거나 지연한다고해서 권리 또는 구제책의 포기 또는 기타 권리 또는 구제책의 포기가 아닙니다. 본 계약 또는 법률에 의해 제공된 권리 또는 구제책의 단일 또는 부분 행사는 해당 권리 또는 구제책의 추가 행사 또는 다른 권리 또는 구제책의 행사를 방해하지 않습니다.</p>
            <p>18 <strong>분리 가능성</strong> </p>
            <p>본 계약의 조항 또는 조항의 일부가 동의에 의한 판결 또는 판결 또는 항소를 할 수없는 관할 법원의 결정에 의해 무효 또는 시행 불가능으로 선언 된 경우, 다른 모든 조항 본 계약에 포함 된 내용은 완전한 효력을 유지하며 본 계약 기간 동안 그러한 발견에 영향을받지 않습니다.</p>
            <p>19 <strong>양도 및 완전한 합의</strong> </p>
            <p>19.1 당사는 귀하와의 계약에 대한 당사의 권리 및 의무의 일부 또는 전부를 당사의 재량에 따라 언제든지 제 3 자에게 양도하거나 하도급 할 수 있습니다. 당사의 사전 서면 동의 없이는 본 계약에 따라 발생하는 귀하의 권리 또는 의무를 양도하거나 처분 할 수 없습니다. </p>
            <p>19.2 본 계약은 당사 게임과 관련된 당사자 간의 전체 합의 및 이해를 포함하며 귀하 또는 당사의 사기 또는 사기 표현을 제외하고 모든 이전 계약, 약정, 진술 및 이해를 대체합니다. 개인 정보 보호 정책과 본 약관이 일치하지 않는 경우 본 약관이 우선합니다. </p>
            <p>20 개의 <strong>제출물</strong> </p>
            <p>귀하는 귀하가 당사에 제공 한 게임에 대한 질문, 의견, 제안, 아이디어, 피드백 및 기타 정보가 기밀이 아니며 당사의 재량에 따라 사용될 수 있음을 인정하고 동의합니다.</p>
            <p>21 <strong>귀하의 우려 사항 및 불만</strong> </p>
            <p>게임에 나타나는 자료에 대해 우려 사항이있는 경우 <a href="mailto:support@gameofnations.net">support@gameofnations.net</a> 으로 문의하십시오 .</p>
            <p><br /><br /><br /></p>
        </div>
        <div v-if="lang=='JP'" class="tos-text">
            <p>1<strong>契約と当社のサービス</strong> </p>
            <p>1.1これらの<strong>利用規約</strong>（最終更新日：<strong>2020</strong>年6月3<strong>日</strong>）（「<strong>利用規約</strong>」）およびそれらで明示的に参照されるすべての文書（「<strong>契約</strong>」）は、<strong>ユーザー</strong>が使用するときに当社と締結する契約の利用規約です。当社のGameof Nationsゲームには、ソフトウェア、アップデートとアップグレード、ユーザーマニュアル、その他の電子資料、およびそのようなソフトウェアと資料のコピーが含まれますが、これらに限定されません（「<strong>ゲーム</strong>」）。 </p>
            <p>1.2ゲームにアクセス、インストール、使用を開始する前に、これらの規約とその中で参照されているすべてのドキュメントを注意深くお読みください。当社のゲームにアクセス、ダウンロード、および/または使用することにより、お客様は本契約の利用規約を読み、理解し、同意し、当社のゲームにアクセスして使用するたびにそれらに従うことに同意するものとします。本契約に同意しない場合は、当社のゲームへのアクセス、ダウンロード、および/または使用をご遠慮ください。本契約は、当社のゲームの各ユーザー（それぞれ「<strong>ユーザー</strong>」、<strong>「あなた」、「あなたの」</strong>）に適用されます。&nbsp;</p>
            <p>2<strong>私たちについての情報</strong> </p>
            <p>Game of Nationsは、eRepublik Labs Ltd（「<strong>私たち</strong>」、「<strong>私たち</strong>」、「<strong>私たち</strong>」）が提供するアプリケーションである仮想ゲームです。eRepublik Labs Ltdは、アイルランドのユニット4ブロックAブルームフィールドビジネスパークマラハイド、ダブリン、K36 RF83に登録事務所を持ち、会社番号462101でアイルランドに設立されました。</p>
            <p>3<strong>あなたのアカウント</strong> </p>
            <p>3.1お客様は、アカウントをサブライセンス、賃貸、リース、販売、取引、贈与、またはその他の方法で譲渡したり、これらの方法で取得したアカウントを使用したりすることはできません。 </p>
            <p>3.2当社のゲームへのアクセスは、第三者のアプリケーション/ウェブサイトを介して許可される場合もあります。これは、当社のゲームにアクセスするには、当社のゲームに接続するための第三者のウェブサイト/アプリケーションに有効なアカウントが必要であることを意味する場合があります。サードパーティとのアカウントの作成は、それぞれのサードパーティが提供する利用規約とプライバシーポリシーによって規制されており、そのようなすべてのドキュメントは、本契約とは別の契約を形成します。当社は、第三者のWebサイトおよび/またはアプリケーションの使用によりお客様が被った損失または損害について、責任を負わず、明示的に責任を負わないものとします。サードパーティのウェブサイト/アプリケーションの利用規約を注意深くお読みください。</p>
            <p>4<strong>ゲームへのアクセス</strong> </p>
            <p>4.1本契約に従い、ゲームは個人的および非営利目的でのみ使用できます。 </p>
            <p>4.2当社のゲームへのアクセスおよび使用の条件として、お客様は、本契約を締結し、拘束される権利、権限、および能力を有することを当社に保証します。 </p>
            <p>4.3ゲームを使用するには、16歳以上である必要があります。16歳未満の人は私たちのゲームを使用する権利がありません。16歳から18歳までの未成年者によるゲームの使用は、親または保護者の同意が必要です。ゲームを使用することにより、16歳以上であること、および/または本契約に従ってゲームを使用するために必要な同意を得たことを宣言します。当社は、そのような同意が付与されていない場合、またはお客様が当社の満足のいくように付与されていないことを証明できない場合、ユーザーアカウントを閉鎖する権利を留保します。お子様にゲームの使用を許可する保護者または後見人には、モデレートは絶対確実ではないため、オンラインでお子様の安全について連絡することが重要であることをお勧めします。インタラクティブサービスを使用している未成年者は、両親または保護者による潜在的なリスクを認識しておく必要があります。&nbsp;</p>
            <p>4.4当社は、ユーザーのアクティビティまたはゲームの使用を監視または管理する義務を負いませんが、適用される法律、規制、法的手続き、または規制当局の要求を満たすために、必要に応じて情報を監視、保持、開示する権利を常に保持します。 。 </p>
            <p>4.5私たちは、あなたが私達に連絡することを奨励しないでsupport@gameofnations.net 、あなたが本契約に違反し、または不快な他の方法である任意のコンテンツを報告すると、本契約のいずれかの侵害または侵害のモデレータに通知することを可能にする電子メールを。</p>
            <p>4.6当社のゲームへのアクセスは一時的に許可されており、当社はいつでも責任を負うことなく、通知または責任を負うことなくゲームのあらゆる側面または機能を撤回、一時停止、または修正する権利を留保します。当社のゲームへのアクセス<strong>は</strong>「現状有姿」で提供されます。当社のゲームのコンテンツおよび/またはその他の資料に依存して、決定を下す（または行うことを控える）、または信頼を置くべき行動をとる（または行うことを控える）ことはできません。当社は、当社のゲームのユーザー、またはコンテンツのいずれかを知らされている可能性のある人がそのようなコンテンツおよび/または資料に依存することから生じるすべての責任および責任を否認します。当社は、ゲーム内の情報を最新または完全に保つ責任、またはそうしなかった場合の責任を負いません。 </p>
            <p>4.7当社のゲームを使用する場合、お客様は本契約の一部を構成する当社のプライバシーポリシーの規定に準拠する必要があります。当社のプライバシーポリシーは、当社のゲームの使用中に収集されたお客様に関する情報をどのように使用するかを定めています。 </p>
            <p><strong>使用権に関する</strong>5つの<strong>制限</strong> </p>
            <p>5.1あなたは、あなたがしてはならないことに同意します（そしてあなたはいかなる第三者にも許可しないことに同意します）： </p>
            <p>5.1.1ゲームの任意の部分を変更、適合、翻訳、またはリバースエンジニアリングする。</p>
            <p>5.1.2ゲーム内またはゲーム上、あるいはゲームを通じて取得したコンテンツまたはその他の素材に含まれる著作権、商標、またはその他の所有権に関する通知を削除します。</p>
            <p>5.1.3ロボット、スパイダー、検索/取得アプリケーション、またはその他の自動化されたデバイス、プロセス、または手段を使用して、ゲームの任意の部分にアクセス、取得、またはインデックスを作成する。</p>
            <p>5.1.4情報またはコンテンツの検索可能なデータベースを構築または入力する目的で、ゲームの任意の部分にアクセス、スクリーンスクレイピング、取得、またはインデックスを作成する。</p>
            <p>5.1.5ゲームのコンテンツ部分の任意の部分を再フォーマットまたはフレーム化します。</p>
            <p>5.1.6自動化された手段によって、または虚偽または詐欺的なふりをしてユーザーアカウントを作成する。</p>
            <p>5.1.7 「スパム」などの不要な電子通信を作成または送信したり、他のユーザーに過度のチャレンジや招待を行ったり、その他の方法で他のユーザーのゲームの楽しみを妨害したりすること。</p>
            <p>5.1.8ゲームを使用して、ネットワークのセキュリティを侵害したり、パスワードやセキュリティ暗号化コードを解読したり、脅迫的またはわいせつと見なされるものを含む違法な素材を転送または保存したりする。</p>
            <p>5.1.9ゲームを自分で使用する以外の目的で、ゲームで提供されるコンテンツをコピーまたは保存する。</p>
            <p>5.1.10ゲームの適切な動作を妨害する、またはゲームの適切な動作を妨害しようとするデバイス、ソフトウェア、またはルーチンを使用する。 </p>
            <p>5.1.11 ITインフラストラクチャに不当または不釣り合いに大きな負荷を課す、または課す可能性のある行動をとる。 </p>
            <p>5.1.12適用法に違反するために、意図的または意図せずにゲームを使用すること。 </p>
            <p>5.1.13この段落で説明されている禁止されている活動に関連して、他のユーザーに関する個人データを収集または保存する。 </p>
            <p>5.1.14ゲームを介した調査、コンテスト、ねずみ講、チェーンレター、迷惑メール、スパム行為に関与または宣伝すること。 </p>
            <p>5.1.15広告、マーケティング、慈善、キャンペーン、広報またはあらゆる種類の政治目的でゲームを使用する。 </p>
            <p>5.1.16個人または団体になりすます。 </p>
            <p>5.1.17第三者の知的財産権の侵害を含む法律または契約上の義務の下で利用可能にする権利がない素材を、ゲームを使用して投稿、電子メール、送信、またはその他の方法で利用可能にします。 </p>
            <p>6<strong>コンテンツ標準とライセンス</strong> </p>
            <p>6.1これらの規定は、お客様が当社のゲームに提供するすべてのコンテンツ、およびそれに関連するすべてのインタラクティブサービスに適用されます。 </p>
            <p>6.2あなたは、手紙だけでなく、以下の基準の精神にも従わなければなりません。この基準は、コンテンツの各部分とその全体に適用されます。 </p>
            <p>6.3ユーザーは、ゲームで送信したり、他のユーザーに送信したりするコンテンツに対して単独で責任を負います。 </p>
            <p>6.4お客様は、当社のゲームで公開されるすべての情報または通信に対して単独で責任を負います。ゲームを通じて公に投稿または非公開で送信されたすべての情報は、そのようなコンテンツの作成者の単独の責任であり、コンテンツの誤りまたは脱落、またはユーザーによるユーザーの使用の結果としての責任を負わないことを理解します。ゲーム。あなたは、ゲームの使用中にあなたが対話する可能性のある他のユーザーの身元を保証できないことを理解しています。さらに、他のユーザーまたはユーザーが説明する可能性のある関係によって提供されるデータの信頼性を保証することはできません。 </p>
            <p>6.5以下は、ゲームで禁止されているコンテンツの種類の一部のリストです。コンテンツは次のことをしてはなりません。 </p>
            <p>6.5.1名誉毀損、虐待、いじめ、嫌がらせ、ストーカー行為、脅迫、またはその他の方法で他人を怒らせること。</p>
            <p>6.5.2違法、わいせつ、中傷的、煽動的、煽動的、攻撃的、人種的憎悪を扇動する傾向がある、暴力を扇動する傾向がある、差別的、威嚇的、スキャンダラス、炎症的、信頼違反、プライバシー侵害、または不適切であること。</p>
            <p>6.5.3適用される法律または規制への違反。</p>
            <p>6.5.4個人および/または団体を欺く、または誤解させる：</p>
            <p>6.5.5法律または契約上または受託者関係の下で開示または利用可能にする権利がない情報（インサイダー情報、または雇用関係の一部として、または秘密保持契約の下で学習または開示された専有情報および機密情報。</p>
            <p>6.5.6特許、商標、企業秘密、著作権、またはその他の所有権を侵害するコンテンツをアップロード、投稿、電子メール、送信、またはその他の方法で利用可能にする、または通信を開始する。</p>
            <p>6.5.7未承諾または無許可の広告、販促資料、「<strong>ジャンクメール</strong>」、「<strong>スパム</strong>」、「<strong>チェーンメール</strong>」<strong>、</strong>「<strong>ねずみ講</strong>」、またはその他の形式の勧誘を投稿、電子メール、送信、またはその他の方法で利用可能にすること。</p>
            <p>6.5.8ソフトウェアウイルス、またはソフトウェア、ハードウェア、または通信機器の機能を中断、破壊、または制限するように設計されたその他のコンピューターコード、ファイル、またはプログラムを含む資料を投稿、電子メール、送信、またはその他の方法で利用可能にする。</p>
            <p>6.5.9ゲームまたはゲームに接続されているサーバーまたはネットワークを妨害または妨害する、またはゲームに接続されているネットワークの要件、手順、ポリシー、または規制に違反する。および/または</p>
            <p>7<strong>停止および終了</strong> </p>
            <p>7.1ユーザーは、理由の有無にかかわらず、独自の裁量により、事前の通知なしにユーザーアカウントとゲームへのアクセスを直ちに終了できることに同意するものとします。上記を制限することなく、以下は、ユーザーによるゲームの使用の終了につながる可能性があります：（a）本契約の違反、（b）法執行機関、ガルダイ/警察、またはその他の政府機関による要求お客様のアカウントの、（c）お客様による要求（自己開始によるアカウントの削除）、（d）予期しない技術的な問題または当社が経験した問題、および（e）お客様のアカウントに関する長期間の非アクティブ。ユーザーアカウントの終了には、当社のゲーム、当社が決定する可能性のあるすべてのコンテンツへのアクセスの削除が含まれ、ゲームのそれ以上の使用を妨げる可能性もあります。さらに、お客様は、すべての終了が当社の独自の裁量で行われるものとし、お客様のアカウントの終了またはゲームへのアクセスについて、当社がお客様または第三者に対して責任を負わないことに同意するものとします。 </p>
            <p>7.2お客様は、当社の単独の判断で本契約に違反する、または攻撃的、違法、または他者または当社の権利を侵害する、害を及ぼす、または脅かす可能性のあるコンテンツを確認および削除する場合があることを理解し、同意するものとします。当社は調査する権利を留保し、当社の裁量により、お客様による当社のゲームの使用を通じて本契約の違反があったかどうかを判断します。本契約の違反が発生した場合、当社は適切とみなす措置を講じることがあります。 </p>
            <p>7.3本契約に従わない場合、当社は以下のすべてまたはいずれかの措置を講じる可能性があります。 </p>
            <p>7.3.1当社のゲームを使用するお客様の権利の即時、一時的、または恒久的な撤回。</p>
            <p>7.3.2お客様が当社のゲームにアップロードした投稿、素材、またはコンテンツの即時、一時的、または恒久的な削除。</p>
            <p>7.3.3 （ゲームを介した）公開または非公開の警告の発行。</p>
            <p>7.3.4投稿の量を制限します。</p>
            <p>7.3.5違反に起因するすべての費用（合理的な管理および法的費用を含むがこれに限定されない）の補償に対するお客様に対する法的手続き。</p>
            <p>7.3.6あなたに対するその他の法的措置; および/または</p>
            <p>7.3.7合理的に必要と思われる場合、法執行当局へのそのような情報の開示。</p>
            <p>7.4当社は、コンテンツ基準の違反および使用制限に対応して取られた措置に対する責任を除外します。本契約に記載されている対応は限定的ではなく、当社は合理的に適切とみなすその他の措置を講じることがあります。 </p>
            <p>8<strong>件の苦情</strong> </p>
            <p>ゲームに投稿されたコンテンツが本契約に違反している、またはその他の方法で好ましくないと思われる場合は、そのようなコンテンツの完全な詳細を指定し、そのようなコンテンツのすべての投稿を特定し、理由を指定して、support @ gameofnations.netに電子メールで通知してくださいあなたの異議のために。あなたが十分な情報を私たちに提供しない場合、私たちはあなたの苦情を処理することができないかもしれません。当社は調査する権利を留保し、当社の裁量により、取るべき行動（もしあれば）を決定します。ゲームの他のユーザーとのやり取りについては、お客様が単独で責任を負います。当社は、お客様と他のユーザーとの間の紛争を監視または介入する権利を留保しますが、そうする義務はありません。</p>
            <p><strong>サードパーティとの</strong>9<strong>つの紛争</strong> </p>
            <p>ゲームの使用に関して、誰かがあなたに対して何らかの主張をしたり、法的手続きを行うと脅したりした場合は、すぐに私たちに知らせなければなりません。あなたは、私たちの要求に応じて、不平を言った行為を直ちに停止します。要請があった場合は、請求の詳細を書面で確認する必要があります。お客様がその行為を停止しなかった場合、または不満のある行為があった場合、当社は本契約の条件に基づいてお客様に対してあらゆる適切な措置を講じる権利を留保します。</p>
            <p>10の<strong>知的財産権</strong> </p>
            <p>10.1私たちは、ゲームおよびゲームで公開されているコンテンツのすべての知的財産権の所有者またはライセンシーです。これらの作品は、世界中の著作権法、商標法、データベース法、および条約によって保護されています。そのようなすべての権利は留保されています。 </p>
            <p>10.2ゲーム（テキスト、写真、グラフィック、ビデオ、オーディオコンテンツを含むがこれらに限定されない）は、アイルランドおよびその他の国の著作権法に基づく集合作品または編集物として著作権によって保護されています。ユーザーが提出したコンテンツを除き、ゲームとそのすべての側面（すべての著作権、商標、その他の知的財産権または所有権を含む）は、当社または当社のライセンサーが所有します。お客様は、ゲームおよびゲームに関連して使用される基盤となるテクノロジーまたはソフトウェアに、当社の専有情報が含まれていることを認めます。明示的に提供されている場合を除き、ゲームで利用可能なコンテンツ、ソフトウェア、および/または素材の全部または一部を変更、複製、配布、派生物の作成、公に表示、または何らかの方法で悪用することはできません。ここに明示的かつ明確に規定されている場合を除き、当社および当社のサプライヤーは、明示的または黙示的な権利をお客様に付与せず、当社がお客様に明示的に付与していないゲームの要素に関するすべての権利は当社が保持します。 </p>
            <p>10.3コンテンツの著作者人格権を保持していると判断された場合、お客様はここに次のことを宣言します。（a）コンテンツ、またはコンテンツの派生物、アップグレード、更新に関連して個人を特定する情報を使用する必要はありません。（b）お客様は、当社または当社のライセンシー、後継者および譲受人、または他のゲームによるコンテンツの公開、使用、変更、削除、および悪用に異議を唱えません。（c）あなたは永久に放棄し、コンテンツのいずれかにおける作者のすべての道徳的権利に対する権利を主張または主張しないことに同意します。（d）あなたは、そのような道徳的権利のために私たちに対して主張する可能性のある主張から、私たち、および私たちのライセンシー、後継者、譲受人を永久に解放します。 </p>
            <p>10.4当社のゲームに記載されているすべての製品名は、それぞれの所有者の商標であり、その他の商標が当社のゲームに表示される場合があります。当社のゲームの一部の資料には、第三者の知的財産を含むその他の情報が含まれている場合があります。ゲームに表示されるものは、本契約で明示的に規定されている場合を除き、関連する所有者の書面による明示的な許可なしに、ゲームに表示されるロゴ、情報、または商標のライセンスまたは使用権を付与するものと解釈されるべきではありません。 </p>
            <p>10.5あなたは、あなた自身の個人的な目的のために、私たちのゲームから任意のページの1つのコピーを印刷し、抜粋をダウンロードすることができ、あなたは私たちのゲームに投稿された資料に組織内の他の人の注意を引くことができます。特に、第三者がアクセスできるようにコンテンツをキャッシュしたり、ゲームのコンテンツをミラーリング、スクレイピング、フレーム化したり、書面による明示的な許可なしに別のWebサイト、ソフトウェア、またはアプリケーションに組み込んだりしてはなりません。我ら。 </p>
            <p>10.6印刷またはダウンロードした資料の紙またはデジタルコピーをいかなる方法でも変更してはならず、イラスト、写真、またはグラフィックを付随するテキストとは別に使用してはなりません。 </p>
            <p>10.7当社のゲームの素材の作者としての当社のステータス（および当社のゲームで特定されたパートナー、広告主、または第三者のステータス）は、常にお客様に承認される必要があります。 </p>
            <p>10.8お客様は、当社からライセンスを取得せずに、ゲームのコンテンツのいかなる部分も営利目的または非営利目的で使用してはなりません。 </p>
            <p>10.9本契約に違反してゲームの一部を印刷、コピー、またはダウンロードした場合、ゲームを使用する権利は直ちに終了し、当社の選択により、作成した資料のコピーを返却または破棄する必要があります。</p>
            <p>11の<strong>仮想アイテム</strong> </p>
            <p>11.1本契約に従い、ゲーム内の仮想アイテムを使用するための制限付きライセンス権が付与されます。これらのアイテムは、当社の裁量で購入または無料配布できます。あなたは、ゲーム内の仮想アイテムが、私たち、私たちのゲームの別のユーザー、または第三者からいつでもお金またはお金の価値と引き換えることができないことに同意します。ユーザーアカウントを終了する権利を行使し、本契約の違反が原因ではない場合を除き、ユーザーが購入したゲーム内仮想アイテムについて、ユーザーは当社からの払い戻しを請求する権利がないことを認めます。誤解を避けるために、当社から無料で提供された、またはゲームの別のユーザーから提供されたゲーム内仮想アイテムについて、当社からの払い戻しを受ける権利はありません。お客様は、ゲーム内の仮想アイテムおよび適切と思われるアイテムの使用を規制、変更、撤回、一時停止、またはその他の方法で管理する権利を当社が留保することを認め、規制の理由により当社がお客様に対して責任を負わないことに同意するものとします。 、ゲーム内仮想アイテムの変更、撤回、一時停止、および/または制御。 </p>
            <p>11.2<strong>あなたはないかもしれない</strong>サブライセンス、賃貸、リース、販売、貿易、<strong>ギフト</strong>やその他の方法で転送する<strong>アクションは、ゲームのゲームプレイによって許可され、ゲーム内の特定のゲーム内の仮想アイテムを</strong>、あなたはこの中で言及したのいずれかの方法で得られた仮想アイテムを使用することはできません段落。お客様は、当社がいつでも独自の裁量で、追加料金および料金で新しいサービスを追加したり、既存のサービスの料金および料金を修正したりする場合があり、料金および料金を決定する場合があることを認めます。 </p>
            <p>12<strong>私たちの責任</strong> </p>
            <p>12.1当社のゲームから表示、アクセス、または使用されるコンテンツおよびその他の資料は、その正確性に関する保証、条件、または保証なしに提供されます。法律で許可されている範囲で、以下を明示的に除外します。 </p>
            <p>12.1.1法令、慣習法、または衡平法によって暗示される可能性のあるすべての条件、保証、およびその他の条件。そして </p>
            <p>12.1.2当社のゲームに関連して、または当社のゲームの使用、使用不能、または使用の結果に関連してユーザーが被った直接的、間接的、または結果的な損失または損害に対する責任。収入または収入の損失、事業の損失、利益または契約の損失、予想される貯蓄の損失、データの損失、のれんの損失、管理または営業時間の浪費、および不法行為（過失を含む）、契約違反、またはその他の理由によるものであるかどうかにかかわらず、予見可能であっても、その他のあらゆる種類の損失または損害。 </p>
            <p>12.2これは、当社の過失に起因する死亡または人身傷害に対する当社の責任、または基本的な事項に関する詐欺的な不実表示または不実表示に対する当社の責任、または適用法の下で除外または制限できないその他の責任には影響しません。 </p>
            <p>12.3当社は、当社の合理的な制御が及ばない事柄により生じた、本契約に基づく当社の義務の不履行について責任を負わないものとします。いかなる状況においても、コンテンツの誤りや脱落、または使用、アクセス、またはアクセスの結果として発生したあらゆる種類の損失または損害を含むがこれらに限定されない、いかなるコンテンツに対しても責任を負わないものとします。ゲーム上のコンテンツへのアクセスの拒否。</p>
            <p>12.4当社は、分散型サービス拒否攻撃、ウイルス、悪意のある、または障害のあるコンピュータープログラム、またはお客様の機器やデバイス、コンピュータープログラム、データ、またはその他の専有資料に感染する可能性のあるその他の技術的に有害な資料によって引き起こされた損失または損害について責任を負いません。当社のゲームの使用、またはゲーム、またはそれにリンクされたWebサイト/アプリケーションに投稿された資料のダウンロードが原因です。 </p>
            <p>12.5当社は、サービスまたはサービスへのアクセスに使用されるゲームの使用に関連するシステム（インターネットおよびお客様のハードウェアとソフトウェアを含む）、またはそのようなシステムを介して渡される情報のセキュリティを保証しません。当社は、ゲームへのアクセスを保証するものではなく、当社のサービスへのアクセスに使用されるシステムが継続的であるか、ウイルスまたはエラーがないことを保証しません。 </p>
            <p>12.6ゲームの使用を通じてダウンロードまたはその他の方法で取得された資料は、ユーザー自身の裁量とリスクで行われ、そのような資料のダウンロードに起因するコンピューター/デバイスの損傷またはデータの損失については、ユーザーが単独で責任を負います。口頭または書面を問わず、お客様が当社から、またはゲームを通じて、またはゲームから取得したアドバイスまたは情報は、本契約に明示的に記載されていない保証を作成するものではありません。 </p>
            <p>13 <strong>補償</strong> </p>
            <p>お客様は、（i）お客様のアクセスに起因または起因する第三者による請求または要求から、当社、当社の親会社、子会社、役員、取締役、株主および従業員を、費用および法的費用を含め、無害に補償および保持することに同意するものとします。ゲームに対して、（ii）当社のサービスの使用、（iii）お客様による本契約の違反、または（iv）お客様、またはお客様のアカウントまたはユーザー識別名またはパスワードを使用した第三者による、個人または団体の知的財産またはその他の権利。</p>
            <p>14<strong>米国からのお問い合わせ</strong> </p>
            <p>お客様にサービスを提供する過程で、またお客様によるゲームの使用に関して、お客様が直接当社に送信した電子メールまたはその他の詳細を介して、または第三者のWebサイトまたはアプリケーションをリンクするさまざまなアプリケーションを介してお客様と通信する必要がある場合があります。私たちのゲーム。お客様は、お客様のアカウントに固有であり、ゲームの通常の機能に必要な電子メールを受信することに同意するものとします。これには、ゲームの機能についてユーザーに通知するのに役立つ電子メールが含まれます。また、当社または当社のパートナーおよび/または関連会社によって開発された新しいゲームおよび/またはアプリケーションに関する情報を含む電子メールを受信することに同意するものとします。</p>
            <p>15<strong>管轄および適用法</strong> </p>
            <p>アイルランドの裁判所は、当社のゲームへの訪問に起因または関連するすべての請求について専属管轄権を有しますが、当社は、お客様の居住国またはその他の関連国で本契約の違反についてお客様に対して訴訟を起こす権利を留保します。本契約はアイルランドの法律に準拠します。本契約に含まれる権利および救済は累積的であり、法律によって提供される権利または救済を排除するものではありません。</p>
            <p>16<strong>バリエーション</strong> </p>
            <p>当社は、かかる変更を通知することなく、ゲームに表示されるページを修正することにより、いつでも本契約を改訂することができます。このような変更は、変更された用語がゲームに投稿されるとすぐに有効になります。お客様は、変更を認識できるように、定期的に契約を確認することに同意するものとします。変更後もゲームを継続して使用することは、変更された契約に同意したことを示します。</p>
            <p>17<strong>権利放棄</strong> </p>
            <p>本契約または法律によって提供される権利または救済の行使の不履行または行使の遅延は、権利または救済の放棄または他の権利または救済の放棄を構成するものではありません。本契約または法律によって提供される権利または救済の単一または部分的な行使は、その権利または救済のさらなる行使または別の権利または救済の行使を妨げるものではありません。</p>
            <p>18<strong>可分性</strong> </p>
            <p>本契約のいずれかの条項または条項の一部が無効または執行不能であると宣言された場合、同意またはその他の管轄裁判所の判決または判決により、上訴が行われない、または行われることができない、その他すべての規定本契約に含まれるものは、完全な効力を維持し、本契約の期間中、そのような発見によって影響を受けないものとします。</p>
            <p>19<strong>譲渡および完全合意</strong> </p>
            <p>19.1当社は、当社の裁量により、いつでもお客様との本契約の権利および義務の一部またはすべてを第三者に譲渡または下請けすることができます。お客様は、事前の書面による同意なしに、本契約に基づいて発生するお客様の権利または義務を譲渡または処分することはできません。 </p>
            <p>19.2本契約には、当社のゲームに関連する当事者間の完全な合意および理解が含まれ、お客様または当社による詐欺または詐欺的な表明を除き、以前のすべての合意、取り決め、声明および理解に優先します。プライバシーポリシーとこれらの規約の間に矛盾がある場合は、これらの規約が優先されます。 </p>
            <p>20の<strong>提出</strong> </p>
            <p>お客様は、お客様から当社に提供されたゲームに関する質問、コメント、提案、アイデア、フィードバック、およびその他の情報は機密情報ではなく、当社の裁量で使用される可能性があることを認め、同意するものとします。</p>
            <p>21<strong>あなたの懸念と苦情</strong> </p>
            <p>ゲームに表示される素材について懸念がある場合は、support @ gameofnations.net までご連絡ください。</p>
            <p><br /><br /></p>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        lang:String
    }
}
</script>
<style lang="less">
    .tos-text{
        color: #fff;
        padding-top: 20px;
        p{
            line-height: 1.5;
            color: #fff;
        }
        li{
            line-height: 1.5;
            color: #fff;
        }
        ol{
            color: #fff;
            margin: 20px 0px;
            font-weight: 700;
            li{
                strong{
                    color: #fff;
                    font-size: 20px;
                }
            }
        }
        h1{
            font-weight: 700;
            color: #fff;
            font-size: 20px;
        }

    }
</style>